import { PersistGate } from 'redux-persist/es/integration/react';
import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import store, { persistor } from './store';
import theme from './themes';
import AppRoutes from './routes';
import { SnackbarProvider } from 'notistack';
import ModalProvider from 'mui-modal-provider';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <div>
              <SnackbarProvider
                maxSnack={2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
                <AppRoutes />
              </SnackbarProvider>
            </div>
          </ModalProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
