import { Box, Grid, LinearProgress, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { DistributorListType } from '../../redux-store/types/api-types';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldReadonly } from './AddressFields';

export default function UserManagementDistributorDetails({
  location,
}: {
  location: Location<{ distributorId: number }>;
}): JSX.Element {
  const { distributorId: id } = location.state || { distributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState<DistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(id)
          )) as unknown as DistributorListType;
          setDistributorDetails(getDistributorDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User={
            distributorDetails?.type
              ? distributorDetails?.type + ' Distributor Detail'
              : 'Distributor Detail'
          }
          accessForEditAdd={true}
          onClickBack={() =>
            history.push('distributors', {
              distributorType: distributorDetails?.type,
            })
          }
          onClickEdit={() =>
            history.push('edit-distributor', { distributorId: distributorDetails?.id })
          }
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Distributor Name'} description={distributorDetails.name} />
                    {distributorDetails.type === 'individual' && (
                      <>
                        <KeyValue title={'Email Id'} description={distributorDetails.user.email} />
                        <KeyValue
                          title={'Mobile Number'}
                          description={distributorDetails.user.phone}
                        />
                      </>
                    )}
                    <KeyValue title={'Pan'} description={distributorDetails.panNumber} />
                    <KeyValue title={'ARN Code'} description={distributorDetails.arnCode} />
                    {distributorDetails.type === 'individual' && (
                      <KeyValue title={'RM Name'} description={distributorDetails.rm.name} />
                    )}
                    <AddressFieldReadonly AddressField={distributorDetails} />
                    <Grid item xs={12}>
                      <Item>{`Applicable Schemes and Plans`}</Item>

                      {distributorDetails?.planDetails?.schemes?.length > 0 ? (
                        distributorDetails?.planDetails.schemes?.map((scheme) => {
                          return (
                            <React.Fragment key={scheme?.id}>
                              <Box
                                sx={{
                                  border: 0.5,
                                  // borderColor: 'rgba(41, 49, 57, 0.7)',
                                  borderColor: 'primary.main',
                                  p: 3,
                                  mb: 3,
                                  mt: 2,
                                  borderRadius: 0.5,
                                }}>
                                <ItemDetails sx={{ pb: 1 }}>{scheme?.schemeName}</ItemDetails>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {scheme?.plans.map((plan) => {
                                    return (
                                      <React.Fragment key={plan.id}>
                                        <Chip
                                          label={plan?.planDescription}
                                          sx={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            mt: 2,
                                            color: 'rgba(41, 49, 57, 0.7)',
                                          }}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <ItemDetails>-</ItemDetails>
                      )}
                    </Grid>{' '}
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
