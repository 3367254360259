import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { COMMENT_ROLES } from '../../utils/constant';
import { formatShortDateAndTime } from '../../utils/date';
import { Gridstyles } from './index';
import { styles } from '../../Styles/styles';

export default function Comments({ application }: { application: ApplicationProps }): JSX.Element {
  const { comments = [] } = application || {};
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mb={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Comments
              </Typography>
            </Grid>

            <Gridstyles>
              <Box sx={{ mt: 4 }}>
                {comments.map((row: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    sx={{
                      border: '1px solid #00D9DA',
                      p: 1.25,
                      borderRadius: 1.25,
                      mb: 1.25,
                      rowGap: 1.25,
                      padding: { xs: '10px', sm: '55px' },
                      '.MuiGrid-item': {
                        px: 1,
                      },
                      '.MuiTypography-root': {
                        fontSize: { xs: 10, sm: 16 },
                        fontWeight: 500,
                        maxWidth: '250px',
                        overflowWrap: 'anywhere',
                        textAlign: 'left',
                        '&:nth-child(2)': {
                          maxWidth: '100%',
                        },
                      },
                      '& .MuiTypography-h6': {
                        fontFamily: 'Poppins, sans-serif',
                        color: 'primary.main',
                      },
                      '& .MuiTypography-body1': {
                        color: 'rgba(41, 49, 57, 0.7)',
                      },
                    }}>
                    <Grid item xs={6} sm={6}>
                      <Typography variant="h6">Commented By</Typography>
                      <Typography>
                        {row.commentedUserName.toUpperCase()}(
                        {COMMENT_ROLES[row.commentedByRole || ''] || 'N/A'})
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography variant="h6">Time</Typography>
                      <Typography>{formatShortDateAndTime(row.createdAt) || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ width: '100%' }}>
                      <Typography variant="h6" sx={{ width: '100%' }}>
                        Comment(s)
                      </Typography>
                      <Typography sx={{ width: '100%' }}>{row.comment || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
