import { AnyAction } from 'redux';
import { monthWiseCommit } from '../../components/dashboard/commitmentAndContribution';
import { monthwiseCommitmentAmountType } from '../types/api-types';
import { GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS } from '../types/application';
import { LOGOUT_SUCCESS } from '../types/auth';

function commitmentAndContributionReducer(
  state = monthWiseCommit,
  action: AnyAction
): Partial<monthwiseCommitmentAmountType> {
  switch (action.type) {
    case GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS:
      return {
        monthWiseCommit,
        ...action.body,
      };
    case LOGOUT_SUCCESS: {
      return monthWiseCommit;
    }
    default:
      return state;
  }
}

export default commitmentAndContributionReducer;
