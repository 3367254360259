import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFSubmitButton } from '../../lib/formik';
import { Location } from 'history';
import { mdmsFundInvestorCategoryType } from '../../redux-store/types/mdms';
import { getFundInvestorCategory } from '../../redux-store/actions';
import { IsResidentFlow } from '../../utils/utilityFunctions';
interface Values {
  onboardingFundCategory: string;
  onboardingInvestorType: string;
}

export default function InvestorOnboarding({
  // eslint-disable-next-line
  location,
}: {
  location: Location<{
    redirectTo?: string;
  }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mdmsFundInvestorType, setMdmsFundInvestorType] = useState<mdmsFundInvestorCategoryType>(
    {}
  );
  const validationSchema1 = yup.object().shape({
    onboardingFundCategory: yup
      .string()
      .nullable()
      .required('Fund Category is required')
      .oneOf(Object?.keys(mdmsFundInvestorType), 'Invalid value for Fund Category'),
    onboardingInvestorType: yup
      .string()
      .nullable()
      .test('onboardingInvestorType', (value, context) => {
        const fieldValue = value === null ? '' : value;
        const { createError, path, parent } = context;
        const mdmsInvestorTypeArray = mdmsFundInvestorType[
          parent?.onboardingFundCategory || ''
        ]?.onboardingInvestorType
          ?.filter((inv) => !inv?.disabled)
          ?.map((inv) => inv.key);
        if (!fieldValue) {
          return createError({
            message: 'Onboarding Investor Type is required',
            path,
          });
        }
        if (!mdmsInvestorTypeArray?.includes(fieldValue)) {
          return createError({
            message: 'Invalid value for Onboarding Investor Type',
            path,
          });
        }
        return true;
      }),
  });
  const initialValues1: Values = {
    onboardingFundCategory: '',
    onboardingInvestorType: '',
  };
  const [initialValues, setInitialValues] = useState<Values>(initialValues1);

  useEffect(() => {
    (async function () {
      try {
        const categoryMasters = (await dispatch(
          getFundInvestorCategory()
        )) as unknown as mdmsFundInvestorCategoryType;
        setMdmsFundInvestorType(categoryMasters);
        setInitialValues({
          ...initialValues,
          onboardingFundCategory: Object?.keys(categoryMasters)[0] || '',
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const handleSubmit1 = async (values: Values) => {
    try {
      const { onboardingInvestorType, onboardingFundCategory } = values;
      const locationHistory = {
        ...(values || {}),
        redirectTo: 'investor-onboarding',
      };
      !IsResidentFlow(onboardingInvestorType, onboardingFundCategory)
        ? history.push('enter-tax-id-number', locationHistory)
        : history.push('enter-pan-number', locationHistory);
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema1}
        enableReinitialize={true}
        onSubmit={handleSubmit1}>
        {({ handleSubmit, values, setValues }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <Box sx={{ mt: 1.5 }}>
                <UseRadioGroup
                  formLabel="Fund Category *"
                  name={`onboardingFundCategory`}
                  items={Object.keys(mdmsFundInvestorType).map((option) => ({
                    label: mdmsFundInvestorType[option].displayText,
                    value: option,
                  }))}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      onboardingFundCategory: value,
                      onboardingInvestorType: '',
                    });
                  }}
                />
              </Box>
              <Box sx={{ mt: 1.5 }}>
                <UseRadioGroup
                  formLabel="Investor Category *"
                  name={`onboardingInvestorType`}
                  items={mdmsFundInvestorType[
                    values.onboardingFundCategory || ''
                  ]?.onboardingInvestorType?.map((option) => ({
                    label: option.label,
                    value: option.key,
                    disabled: option?.disabled,
                  }))}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      onboardingInvestorType: value,
                    });
                  }}
                />
              </Box>
              <MFSubmitButton label="Proceed" />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
