import { resetQ, setVisibility } from '../components/NonIndividualInvestor/fatca';
import {
  nonIndividualMdmsFatca,
  nonIndividualMdmsQuestionsFatca,
} from '../redux-store/types/api-types';

export default function updateVisibility(
  answer: string,
  q: Partial<nonIndividualMdmsFatca>,
  formValues: nonIndividualMdmsQuestionsFatca
): void {
  if (
    q.question_type === 'single_choice_radio' ||
    q.question_type === 'single_choice_dropdown' ||
    q.question_type === 'single_choice_dropdown_withInfo'
  ) {
    const filteredOption = q.options?.filter((option) => option.label === answer);
    const showQs =
      filteredOption && filteredOption?.length > 0 ? filteredOption[0].showQs || [] : [];
    if (showQs && showQs.length > 0) {
      formValues.forEach((ques) => {
        resetQ(ques, q.id || '');
      });
    }
    showQs?.forEach((qns) => {
      formValues.forEach((ques) => {
        setVisibility(qns, ques, formValues);
      });
    });
  }
}
