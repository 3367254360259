import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MFTextField, MFSubmitButton } from '../../lib/formik';
import { useDispatch } from 'react-redux';
import { LoginLayout } from '../commonComponents';
import React from 'react';
import { Grid } from '@mui/material';
import { showError, verifyCvl } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import MFSelectField from '../../lib/formik/SelectField';
import { CvlUrlMaster } from '../../utils/constant';

interface Values {
  cvlUrl: string;
  cvlPwd: string;
  cvlPasscode: string;
  cvlUsername: string;
  cvlPosCode: string;
}

const validationSchema = yup.object().shape({
  cvlUrl: yup
    .string()
    .required('CVL URL is required')
    .oneOf(Object.values(CvlUrlMaster), 'Invalid value for CVL URL'),
  cvlPwd: yup.string().required('CVL PWD is required'),
  cvlPasscode: yup.string().required('CVL Passcode is required'),
  cvlUsername: yup.string().required('CVL UserName is required'),
  cvlPosCode: yup.string().required('CVL APP POS CODE is required'),
});

export default function Cvl(): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: Values, resetForm: () => void) => {
    try {
      const response = await dispatch(verifyCvl(values));
      if (response) {
        enqueueSnackbar('Cvl verification done successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        throw `${JSON.stringify(response)}`;
      }
    } catch (e) {
      console.error((e as unknown as Error).message);
      typeof e === 'string' && dispatch(showError(e, 'cvl'));
    } finally {
      resetForm();
    }
  };
  const initialValues: Values = {
    cvlUrl: '',
    cvlPwd: '',
    cvlPasscode: '',
    cvlUsername: '',
    cvlPosCode: '',
  };
  return (
    <LoginLayout LoginText="CVL Check" cvl={true}>
      <Formik
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({ handleSubmit }) => (
          <React.Fragment>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <Grid
                container
                sx={{
                  width: '100%',
                  ml: 0,
                  '.MuiGrid-item': { px: { xs: 0, sm: 0, md: '10px' } },
                  '.MuiLoadingButton-root': {
                    mt: 5,
                  },
                  '.MuiInputLabel-root': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 16,
                    fontWeight: 500,
                  },
                }}>
                <Grid item xs={12} sm={12} md={6}>
                  <MFTextField
                    label="CVL UserName"
                    name="cvlUsername"
                    placeholder="Enter CVL UserName"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <MFTextField
                    label="CVL PWD"
                    name="cvlPwd"
                    placeholder="Enter CVL PWD"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <MFSelectField
                    name="cvlUrl"
                    label="CVL URL"
                    items={Object.keys(CvlUrlMaster).map((item) => ({
                      key: item,
                      value: CvlUrlMaster[item],
                    }))}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <MFTextField
                    label="CVL Passcode"
                    name="cvlPasscode"
                    placeholder="Enter CVL Passcode"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <MFTextField
                    label="CVL APP POS CODE"
                    name="cvlPosCode"
                    placeholder="Enter CVL APP POS CODE"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', my: 1 }}>
                <MFSubmitButton label="SUBMIT" />
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Formik>
    </LoginLayout>
  );
}
