import { Box, Typography, Stack, Switch } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  gettrusteeAccess,
  getTrustessList,
  trusteeAccess,
  UpdateTrustee,
} from '../../redux-store/actions/userManagement';
import { AddTrusteeRequestBody, Trustee } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { styles } from '../../Styles/styles';
import { UserListHeader } from './userBackButton';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: Trustee) => {
      return (
        <Typography
          variant="h5"
          sx={styles.UsersClickbleColumn}
          component={RouterLink}
          to={{ pathname: 'trustee-details', state: { trusteeId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: Trustee) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: Trustee) => row.user.email || 'N/A',
  },
];

function Trustees(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [trusteeList, setTrusteeList] = useState<Trustee[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{ trusteeRowData: AddTrusteeRequestBody } | null>(null);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getTrustessList())) as unknown as Trustee[];
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        if (!isComponentAlive) return;
        setTrusteeList(res);
        setAccessForEditAdd(trusteeAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddTrusteeRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ trusteeRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  // const viewOrEditCell = [
  //   {
  //     header: '',
  //     renderCell: (row: Trustee): JSX.Element => {
  //       return (
  //         <>
  //           {accessForEditAdd && row.isActive ? (
  //             <Tooltip title="Edit">
  //               <IconButton
  //                 // onClick={() => {
  //                 //  history.push(`/application`, { id: row.id });
  //                 // }}
  //                 component={RouterLink}
  //                 to={{ pathname: 'edit-trustee', state: { trusteeId: row.id } }}
  //                 sx={{
  //                   ':hover': {
  //                     bgcolor: '#F4FCFC',
  //                   },
  //                   '&.Mui-disabled': {
  //                     cursor: 'pointer',
  //                     pointerEvents: 'all',
  //                   },
  //                 }}>
  //                 <EditIcon />
  //               </IconButton>
  //             </Tooltip>
  //           ) : (
  //             <Typography
  //               variant="h5"
  //               component={RouterLink}
  //               to={{ pathname: 'edit-trustee', state: { trusteeId: row.id } }}
  //               sx={{
  //                 color: '#61D1D6',
  //                 textDecoration: 'none',
  //                 fontWeight: 500,
  //                 fontSize: '14px',
  //                 ':hover': {
  //                   color: 'primary.main',
  //                 },
  //               }}>
  //               View Details
  //             </Typography>
  //           )}
  //         </>
  //       );
  //     },
  //   },
  // ];
  const viewPlansCell = [
    {
      header: 'View Plans',
      renderCell: (row: Trustee): JSX.Element => {
        return (
          <Typography
            variant="h5"
            component={RouterLink}
            to={{ pathname: 'trustee-plans', state: { trusteeId: row.id } }}
            sx={styles.UsersClickbleColumn}>
            View
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <UserListHeader
          addUser="Add Trustee"
          listName="List of Trustees"
          accessForEditAdd={accessForEditAdd}
          onClickAdd={() => history.push('add-trustee')}
        />
        {useMemo(
          () => (
            <DataTable
              tableData={trusteeList}
              tableHeader={[...columns, ...AccessCell, ...viewPlansCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
              tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
            />
          ),
          [trusteeList, currentPage, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.trusteeRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                UpdateTrustee(openDialog.trusteeRowData.id as number, {
                  ...openDialog.trusteeRowData,
                  isActive: !openDialog?.trusteeRowData.isActive,
                })
              )) as unknown as Trustee;
              setTrusteeList(
                trusteeList?.map((trustee) => {
                  if (Number(trustee.id) === Number(updatedResponse.id)) {
                    return {
                      ...trustee,
                      ...updatedResponse,
                    };
                  }
                  return trustee;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default Trustees;
