import { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik } from 'formik';
import { AddAifApproverRequestBody, RTAApprover } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addAifApprover,
  AifApproverAccess,
  getRTAApproverById,
  getRtaApproverAccess,
  UpdateAifApprover,
  getAmcApproverAccess,
  AmcApproverAccess,
  getAMCApproverById,
  addAmcApprover,
  UpdateAmcApprover,
} from '../../redux-store/actions/userManagement';
import { aifApproverSchema } from '../../utils/schema';
import { Location } from 'history';
// eslint-disable-next-line
import { CountryCodesDropDown } from '../commonComponents';
import { AddressFieldEditable } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { ENABLE_RTA_APPROVER } from '../../utils/constant';
import { nonDigitRegex } from '../../utils/regex';

const initialValues: AddAifApproverRequestBody = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  isActive: true,
};

export default function AddAIFApprover({
  location,
}: {
  location: Location<{ aifApproverId: number }>;
}): JSX.Element {
  const { aifApproverId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [formikValues, setFormikValues] = useState<AddAifApproverRequestBody | null>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  const [aifApproverDetails, setAifApproverDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        let approverAccess: boolean;
        if (ENABLE_RTA_APPROVER) {
          const { rtaApproverAccess } = (await dispatch(
            getRtaApproverAccess()
          )) as unknown as AifApproverAccess;
          approverAccess = rtaApproverAccess;
        } else {
          const { amcApproverAccess } = (await dispatch(
            getAmcApproverAccess()
          )) as unknown as AmcApproverAccess;
          approverAccess = amcApproverAccess;
        }
        if (aifApproverId) {
          const getAifApproverDetails = (await dispatch(
            ENABLE_RTA_APPROVER
              ? getRTAApproverById(aifApproverId)
              : getAMCApproverById(aifApproverId)
          )) as unknown as RTAApprover;
          const { pincode, buildingNo, streetName, city, state, country, panNumber, id, isActive } =
            getAifApproverDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getAifApproverDetails.user;
          setAifApproverDetails({
            ...aifApproverDetails,
            name,
            phone,
            email,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(approverAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddAifApproverRequestBody) => {
    try {
      const _values = {
        ...values,
        countryCode: values.countryNameAndCode?.split(':')[1].trim(),
      };
      if (!aifApproverId) {
        await dispatch(ENABLE_RTA_APPROVER ? addAifApprover(_values) : addAmcApprover(_values));
        history.push('aif-approver');
      } else {
        await dispatch(
          ENABLE_RTA_APPROVER
            ? UpdateAifApprover(aifApproverId, _values)
            : UpdateAmcApprover(aifApproverId, _values)
        );
        history.push('aif-approver-details', { aifApproverId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={aifApproverDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={aifApproverSchema}>
        {({ handleSubmit, values }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={aifApproverId ? 'AIF Approver' : 'Onboarding AIF Approver'}
              onClickBack={() =>
                aifApproverId
                  ? history.push('aif-approver-details', { aifApproverId: values.id })
                  : history.push('aif-approver')
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name *"
                      placeholder="Enter Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      regexForFilterValue={nonDigitRegex}
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <AddressFieldEditable accessForEditAdd={accessForEditAdd} />
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
