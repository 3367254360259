import { Grid } from '@mui/material';
import { Notes, ProceedSaveLater, SubHeading } from '../components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { Document, RiskProfileMaster } from '../../../redux-store/types/api-types';
import {
  getDocuments,
  isFormValidForSubmission,
  updateApplication,
} from '../../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { documentDetailsForeignSchema } from '../../../utils/schema';
import { showError } from '../../../redux-store/actions/auth';
import {
  KRAEnabled,
  getApplicantName,
  getStep,
  individualApplicationDocument,
  nomineeApplicationDocument,
  statusRoleBased,
  updatedApplicants,
  updatedNominees,
} from '../../../utils/utilityFunctions';
import { getFieldTitle } from '../../ApplicationDetails/Resident/DocumentDetails';
import { applicationComparison } from '../../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getNationalityList, riskProfileMasterData } from '../../../redux-store/actions';
import { nationaliyType } from '../../../redux-store/types/mdms';
import { KraDocument } from '../kraDocument';
import MFCheckbox from '../../../lib/formik/Checkbox';
import { IFSCGuidlines, DocumentDeclaration } from '../../DocumentDeclairations';
import { Values } from '../Resident/documentDetails';
import { DocumentInfoDialog, DocumentwithLables } from '../../commonComponents';
import FieldValidationNote from '../FieldValidationNote';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export const fatcaDocumentCheck = (fatcaDocument: boolean | null, docType: string): boolean => {
  return !!(fatcaDocument && docType === 'fatca');
};

export default function DocumentDetailsForegin(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUpload, setDocumentUpload] = useState(false);
  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        if (application) {
          const response = (await dispatch(
            getDocuments(application?.onboardingFundCategory || '')
          )) as unknown as Document;
          setDocumentsData(response as Document);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const {
    banks = [],
    hasPOA = true,
    documentDeclarationCheck = false,
    onboardingFundCategory = '',
  } = application || {};

  const initialValues: Values = {
    applicants: individualApplicationDocument(application, documentsData, role),
    nominees: nomineeApplicationDocument(application, documentsData),
    saveType: 'save and proceed',
    banks: banks,
    hasPOA: hasPOA,
    documentDeclarationCheck,
    onboardingFundCategory,
  };

  const handleSubmit = async (values: Values) => {
    try {
      const nationalitiesMdmsMasters = (await dispatch(
        getNationalityList()
      )) as unknown as nationaliyType;
      let risk;
      if (RiskProfileEnabled) {
        risk = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
      }
      await isFormValidForSubmission(application, risk, false, true, nationalitiesMdmsMasters, []);
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId,
        currentStep,
        applicationNumber,
        nominees: existingNominees = [],
      } = application || {};
      const { applicants, saveType, nominees } = values;

      const updatedApplicantsWithDoc = updatedApplicants(exisitingApplicants, applicants);
      updatedApplicantsWithDoc.map((applicant) => {
        if (applicant.documents?.length) {
          applicant.documents.map((document) => {
            if (
              Object.keys(document.options || {}).some((ele) => ele) &&
              !Object.keys(document.options || {}).includes(document.documentName)
            ) {
              throw `Please Select ${
                getFieldTitle[document.documentType] || document.documentName
              }`;
            }
          });
        }
      });

      const updatedNomineesWithDoc = updatedNominees(existingNominees, nominees);
      updatedNomineesWithDoc.map((_nominee) => {
        if (_nominee.nomineedocuments?.length) {
          _nominee.nomineedocuments.map((document) => {
            if (
              Object.keys(document.options || {}).some((ele) => ele) &&
              !Object.keys(document.options || {}).includes(document.documentName)
            ) {
              throw `Please Select ${
                getFieldTitle[document.documentType] || document.documentName
              }`;
            }
          });
        }
      });
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // eslint-disable-next-line
                      filename,
                      // eslint-disable-next-line
                      updatedAt,
                      // eslint-disable-next-line
                      createdAt,
                      // eslint-disable-next-line
                      updatedBy,
                      // eslint-disable-next-line
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: application?.nominees
            ?.map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // eslint-disable-next-line
                      filename,
                      // eslint-disable-next-line
                      updatedAt,
                      // eslint-disable-next-line
                      createdAt,
                      // eslint-disable-next-line
                      updatedBy,
                      // eslint-disable-next-line
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
        },
        {
          ...application,
          applicants: updatedApplicantsWithDoc
            .map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // eslint-disable-next-line
                      options,
                      // eslint-disable-next-line
                      infoText,
                      // eslint-disable-next-line
                      downloadTemplate,
                      // eslint-disable-next-line
                      maxFiles,
                      // eslint-disable-next-line
                      maxFileSize,
                      // eslint-disable-next-line
                      minFileSize,
                      // eslint-disable-next-line
                      uploadFormats,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: updatedNomineesWithDoc
            .map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  // eslint-disable-next-line
                  ?.map((document: any) => {
                    const {
                      // eslint-disable-next-line
                      documentData,
                      // eslint-disable-next-line
                      options,
                      // eslint-disable-next-line
                      infoText,
                      // eslint-disable-next-line
                      downloadTemplate,
                      // eslint-disable-next-line
                      maxFiles,
                      // eslint-disable-next-line
                      maxFileSize,
                      // eslint-disable-next-line
                      minFileSize,
                      // eslint-disable-next-line
                      uploadFormats,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
          currentStep:
            !!currentStep && currentStep > (RiskProfileEnabled ? 9 : 8)
              ? currentStep
              : Number(currentStep) + 1,
          documentDeclarationCheck: values?.documentDeclarationCheck,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicantsWithDoc,
              nominees: updatedNomineesWithDoc,
              status: statusRoleBased(role, application),
              documentDeclarationCheck: values.documentDeclarationCheck,
              currentStep: RiskProfileEnabled ? getStep(10, isSaveLater) : getStep(9, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
      } else if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      history.push(
        role === USER_ROLES.INVESTOR
          ? `/investment-details/${applicant1ReferenceId}/application-details`
          : [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
          ? `/application-details/${id}`
          : isSaveLater
          ? `/applications`
          : `/application-preview/${id}`
      );
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  const getLabel = (documentName: string, required: string): string => {
    return `${documentName}  ${required === 'true' ? '*' : ''} `;
  };

  const [open, setOpen] = useState(false);
  const [popUpText, setPopUpText] = useState<{ popUpInfoText: string[] } | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, documentDetailsForeignSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue, handleChange }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid sx={{ width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
            />
          </Grid>
          <IFSCGuidlines />
          <MFCheckbox
            name={'documentDeclarationCheck'}
            label={DocumentDeclaration}
            onChange={handleChange}
          />
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            return (
              <React.Fragment key={ind}>
                {' '}
                <SubHeading>Applicant {ind + 1} Document Details</SubHeading>
                <DocumentwithLables
                  disabled={isFieldDisabled}
                  documentFrom={`applicants.${ind}.documents`}
                  documents={documents}
                  // eslint-disable-next-line
                  getLabel={(documentName, documentType, required, values, ind) =>
                    getLabel(documentName, required)
                  }
                  handleOpen={handleOpen}
                  onFileUpload={handleUpload}
                  onFileUploaded={handleUploaded}
                  setPopUpText={(infoText) => setPopUpText({ popUpInfoText: infoText })}
                  userIndex={ind}
                  values={values}
                />
                {KRAEnabled(application) && USER_ROLES.INVESTOR !== role && (
                  <KraDocument KraDocument={applicant.kraDocument} num={documents.length + 1} />
                )}
              </React.Fragment>
            );
          })}
          {values.nominees.map((nominee, ind) => {
            const { nomineedocuments } = nominee;
            return (
              <React.Fragment key={ind}>
                {' '}
                <SubHeading> {getApplicantName(ind + 1, true)} Nominee Document Details</SubHeading>
                <DocumentwithLables
                  disabled={isFieldDisabled}
                  documentFrom={`nominees.${ind}.nomineedocuments`}
                  documents={nomineedocuments}
                  // eslint-disable-next-line
                  getLabel={(documentName, documentType, required, values, ind) =>
                    getLabel(documentName, required)
                  }
                  handleOpen={handleOpen}
                  onFileUpload={handleUpload}
                  onFileUploaded={handleUploaded}
                  setPopUpText={(infoText) => setPopUpText({ popUpInfoText: infoText })}
                  userIndex={ind}
                  values={values}
                  isNominee={true}
                />
              </React.Fragment>
            );
          })}
          <FieldValidationNote finalPage={true} />
          <ProceedSaveLater
            saveButtonText={
              isFieldDisabled || USER_ROLES.AMCAPPROVER === role ? 'Save Application' : 'Preview'
            }
            showEndIcon={false}
            saveLater={() => {
              setFieldValue('saveType', 'save for later');
            }}
            loader={loading}
            clickedButton={values.saveType}
            showSaveForLater={
              isFieldDisabled ? false : USER_ROLES.AMCAPPROVER === role ? false : true
            }
            disabled={documentUpload}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
          />
          <DocumentInfoDialog handleClose={handleClose} open={open} popUpText={popUpText} />
        </Grid>
      )}
    </Formik>
  );
}
