import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { AmcAdmin } from '../../redux-store/types/api-types';
import {
  AmcAdminAccess,
  getAmcAdminAccess,
  getAMCAdminById,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';

export default function AmcAdminDetails({
  location,
}: {
  location: Location<{ amcAdminId: number }>;
}): JSX.Element {
  const { amcAdminId: id } = location.state || { amcAdminId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [amcAdminDetails, setamcAdminDetails] = useState<AmcAdmin>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { amcAdminAccess } = (await dispatch(
          getAmcAdminAccess()
        )) as unknown as AmcAdminAccess;
        if (id) {
          const getAmcAdminDetails = (await dispatch(getAMCAdminById(id))) as unknown as AmcAdmin;
          setamcAdminDetails(getAmcAdminDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(amcAdminAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="AMC Admin"
          accessForEditAdd={accessForEditAdd}
          onClickBack={() => history.push('amc-admin')}
          onClickEdit={() => history.push('edit-amc-admin', { amcAdminId: amcAdminDetails?.id })}
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          amcAdminDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Grid
                  item
                  container
                  pb={5}
                  rowSpacing={6}
                  columnSpacing={13}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ paddingLeft: { xs: 0, sm: '10%' } }}>
                  <KeyValue title={'Name'} description={amcAdminDetails.user.firstName} />
                  <KeyValue title={'Email Id'} description={amcAdminDetails.user.email} />
                  <KeyValue title={'Mobile Number'} description={amcAdminDetails.user.phone} />
                </Grid>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
