import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createApplication } from '../../redux-store/actions/application';
import { Location } from 'history';
import { ApplicationProps } from '../../redux-store/types/api-types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

export function DocumentsVerification({
  location,
}: {
  location: Location<{
    applicantVerificationToken: string;
  }>;
}): JSX.Element {
  const { applicantVerificationToken } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    boardResolutionDocument: '',
    listOfAuthorisedSignatories: '',
  };

  const handleSubmit = async () => {
    try {
      const res = (await dispatch(
        createApplication({ applicantVerificationToken })
      )) as unknown as ApplicationProps;
      setLoading(true);
      history.push('/non-individual-application', { ...(res || {}) });
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ pt: 13, px: 18 }}>
            <Typography sx={{ color: '#0D2762', fontWeight: 500, fontSize: '18px' }}>
              Kindly verify the following documents before you proceed
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={5}
                py={3}
                mt={7}
                sx={{ border: '2px dashed #ccc' }}>
                <Typography sx={{ color: '#131836', fontWeight: 500, fontSize: '16px' }}>
                  Board Resolution Document
                </Typography>
                <VisibilityOutlinedIcon color="info" />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={5}
                py={3}
                mt={4}
                sx={{ border: '2px dashed #ccc' }}>
                <Typography sx={{ color: '#131836', fontWeight: 500, fontSize: '16px' }}>
                  List Of Authorised Signatories
                </Typography>
                <VisibilityOutlinedIcon color="info" />
              </Grid>
              <LoadingButton
                loadingPosition="start"
                type="submit"
                loading={loading}
                variant="contained"
                sx={{
                  mt: 10,
                  mb: 2,
                  lineHeight: 1.5,
                  fontSize: 14,
                  px: 15,
                  boxSizing: 'border-box',
                }}>
                Proceed
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
}
