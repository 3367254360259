import { CALL_API } from '../middleware';

export type GetAllConsentEmailApplicantsRequestBody = {
  pan: string;
  limit: number;
  page: number;
  orderBy: string;
  orderDirection: string;
};

export type ConsentEmailDetails = {
  id: string;
  pan: string;
  emailId: string;
  countryCode: string;
  mobileNumber: string;
  consentStatus: string;
  consentLinkSentAt: string;
  consentApprovedAt: string;
  consentRejectedAt: string;
  updatedAt: string;
  user: {
    countryCode: string;
    createdAt: string;
    createdBy: string | null;
    email: string;
    firstName: string;
    id: number;
    isActive: boolean;
    lastName: string | null;
    phone: string;
    role: string;
    updatedAt: string;
    updatedBy: number;
  };
};

export type GetAllConsentEmailApplicantionsResponseBody = {
  consents: ConsentEmailDetails[];
  page: number;
  limit: number;
  pages: number;
  total: number;
};

export type ResendConsentEmailRequestBody = {
  panVerificationToken: string;
};

export type ReSendConsentEmailResponseBody = {
  message: string;
};

export type RevokeConsentEmailResponseBody = {
  message: string;
};

const GET_ALL_CONSENTEMAIL_APPLICATION_SUCCESS = 'GET_ALL_CONSENTEMAIL_APPLICATION_SUCCESS';
const GET_ALL_CONSENTEMAIL_APPLICATION = 'GET_ALL_CONSENTEMAIL_APPLICATION';
const RESEND_CONSENTEMAIL_SUCCESS = 'RESEND_CONSENTEMAIL_SUCCESS';
const RESEND_CONSENTEMAIL = 'RESEND_CONSENTEMAIL';

export const getAllConsentEmailApplications =
  (params: Partial<GetAllConsentEmailApplicantsRequestBody>) =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<GetAllConsentEmailApplicantionsResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/consents`,
        method: 'GET',
        types: [GET_ALL_CONSENTEMAIL_APPLICATION_SUCCESS, GET_ALL_CONSENTEMAIL_APPLICATION],
        params: {
          limit: 10,
          ...params,
        },
      },
    });
  };

export const ReSendConsentEmail =
  (ConsentEmailId: string) =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<ReSendConsentEmailResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/resend-consent-email/${ConsentEmailId}`,
        method: 'POST',
        types: [RESEND_CONSENTEMAIL_SUCCESS, RESEND_CONSENTEMAIL],
      },
    });
  };

export const RevokeConsentEmail =
  (ConsentEmailId: string) =>
  // eslint-disable-next-line
  async (dispatch: any): Promise<RevokeConsentEmailResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/onboarding/revoke-consent-email/${ConsentEmailId}`,
        method: 'POST',
        types: [RESEND_CONSENTEMAIL_SUCCESS, RESEND_CONSENTEMAIL],
      },
    });
  };
