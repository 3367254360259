/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { encodeBase64 } from '../../utils/utilityFunctions';
import { CALL_API } from '../middleware';
import {
  CVlResponse,
  LoginWithOtpRequestBody,
  LoginWithOtpResponseBody,
  VerifyCvlRequestBody,
  VerifyLoginOtpRequestBody,
  VerifyLoginOtpResponseBody,
} from '../types/api-types';
import {
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_LOGIN_OTP,
  VERIFY_LOGIN_OTP_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO,
  SHOW_GLOBAL_ERROR,
  LOGOUT,
  VERIFY_CVL_SUCCESS,
  VERIFY_CVL,
} from '../types/auth';

export const loginWithOtp =
  (body: LoginWithOtpRequestBody) =>
  async (dispatch: any): Promise<LoginWithOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/login/send-otp`,
        method: 'POST',
        types: [LOGIN_WITH_OTP_SUCCESS, LOGIN_WITH_OTP],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const verifyLoginOtp =
  (body: VerifyLoginOtpRequestBody) =>
  async (dispatch: any): Promise<VerifyLoginOtpResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/login/verify-otp`,
        method: 'POST',
        types: [VERIFY_LOGIN_OTP_SUCCESS, VERIFY_LOGIN_OTP],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export const logout = () => {
  return {
    [CALL_API]: {
      url: `/users/users/logout`,
      method: 'POST',
      types: [LOGOUT_SUCCESS, LOGOUT],
    },
  };
};

export const getUserInfo = () => {
  return {
    [CALL_API]: {
      url: `/users/users/me`,
      method: 'GET',
      types: [GET_USER_INFO_SUCCESS, GET_USER_INFO],
    },
  };
};

export const showError = (message: string, title?: string) => {
  return {
    type: SHOW_GLOBAL_ERROR,
    errorType: 'ALERT',
    message,
    title,
  };
};

export const verifyCvl =
  (body: VerifyCvlRequestBody) =>
  async (dispatch: any): Promise<CVlResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/cvl-details`,
        method: 'POST',
        types: [VERIFY_CVL_SUCCESS, VERIFY_CVL],
        body,
      },
    });
  };
