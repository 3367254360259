import { Box, Button, Dialog, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from '../components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../../lib/formik/SelectField';
import UseRadioGroup from '../../../lib/formik/Radio';
import { MFTextField } from '../../../lib/formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Applicant } from '../../../redux-store/types/api-types';
import {
  addressTypesMasters,
  IdTypeMasters,
  ReasonMaster,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  YesNoMaster,
} from '../../../utils/constant';
import {
  applicationComparison,
  getApplicantName,
  getStep,
  removeSingleQuote,
  saveForLater,
  statusRoleBased,
} from '../../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../../redux-store/actions/application';
import { FATCAValidationForeginSchema } from '../../../utils/schema';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../../redux-store/types/mdms';
import { getNationalityList } from '../../../redux-store/actions';
import { SearchableSelect } from '../../../lib/formik/searchSelectField';
import MFCheckbox from '../../../lib/formik/Checkbox';
import { showError } from '../../../redux-store/actions/auth';
import { GridItem } from '../distributorDetails';
import FieldValidationNote from '../FieldValidationNote';

export type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: string[];
};

const applicantObj = {
  typeOfAddressProvidedAtKRA: '',
  taxIdResidentOfAnyCountryOtherThanIndia: 'Yes',
  placeOfBirth: '',
  countryOfBirth: '',
  countryOfNationality: '',
  taxCountryName: '',
  taxID: '',
  idType: '',
  nameOfEntity: '',
  dateOfIncorporation: '',
  cityOfIncorporation: '',
  countryOfIncorporation: '',
  entityExcemptionCode: '',
  idTypeOthers: '',
  tinNotAvailableReason: '',
  othersTinNotAvailableReason: '',
  usPerson: '',
  fatcaDeclaration: true,
};

const initialValues: Values = {
  applicants: [
    {
      ...applicantObj,
      wishToUploadFatcaDocument: false,
    },
  ],
  saveType: 'save and proceed',
  countryDropdown: [],
};

export const TermsAndConditions = ({ onClose, open }: { onClose: any; open: any }): JSX.Element => {
  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 800,
            p: { xs: 2, sm: '15px 5px 35px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: '90%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'start',
              fontFamily: 'Work Sans,sans-serif',
            },
          }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              1.{' '}
            </Typography>
            Details under FATCA & CRS: The Central Board of Direct Taxes has notified Rules 114F to
            114H, as part of the Income-tax Rules, 1962, which Rules require Indian financial
            institutions such as the Bank to seek additional personal, tax and beneficial owner
            information and certain certifications and documentation from all our account holders.
            In relevant cases, information will have to be reported to tax authorities/ appointed
            agencies. Towards compliance, we may also be required to provide information to any
            institutions such as withholding agents for the purpose of ensuring appropriate
            withholding from the account or any proceeds in relation thereto. Should there be any
            change in any information provided by you,please ensure you advise us promptly, i.e.,
            within 30 days.
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              2.{' '}
            </Typography>
            If you have any questions about your tax residency, please contact your tax advisor. If
            you are a US citizen or resident or green card holder, please include United States in
            the foreign country information field along with your US Tax Identification Number. It
            is mandatory to supply a TIN or functional equivalent if the country in which you are
            tax resident issues such identifiers. If no TIN is yet available or has not yet been
            issued, please provide an explanation and attach this to form
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export const GuidelinesForFatca = ({ onClose, open }: { onClose: any; open: any }): JSX.Element => {
  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 800,
            p: { xs: 2, sm: '15px 5px 35px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: '90%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'start',
              fontFamily: 'Work Sans,sans-serif',
            },
          }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>1. Who is US person?</Typography>
            In case of individuals, U.S. person means a citizen or Resident of the United States.
            Persons who would qualify as US person could be born in the United States, Born outside
            the united states of a US parents, Naturalized Citizens, Green Card Holders,Tax
            residents.
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              2. Who is a reportable person (non US) under common reporting standards (CRS)?{' '}
            </Typography>
            Under common reporting standards (CRS), Reportable person means tax resident of a
            reportable foreign jurisdiction other than US
          </Typography>

          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {` 3. Document to be submitted if Customer's Country of birth is US but Declares that
              he/she is not a US person`}
            </Typography>
            <Typography>
              • Certificate of relinquishment of citizenship (loss of nationality certificate)
            </Typography>
            <Typography>
              • Self certification for stating reason for not having such a certificate despite
              relinquishing US citizenship
            </Typography>
            <Typography>
              • Self certification for stating reasons for not obtaining US citizenship at birth.
            </Typography>
          </Typography>

          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              4. Towards compliance with tax information sharing laws, such as FATCA, we would be
              required to seek additional personal, tax and
            </Typography>
            <Typography>
              • Beneficial owner information and certain certifications and documentation from our
              account holders. Such information may be sought either at the time of account opening
              or any time subsequently. In certain circumstances we may be obliged to share
              information on your account with relevant tax authorities. If you have any questions
              about your tax residency, please contact your tax advisor. Should there be any change
              in any information provided by you, please ensure you advise us promptly, i.e., within
              30 days. Towards compliance with such laws, we may also be required to provide
              information to any institutions such as withholding agents for the purpose of ensuring
              appropriate withholding from the account or any proceeds in relation thereto. As may
              be required by domestic or overseas regulators/ tax authorities, we may also be
              constrained to withhold and pay out any sums from your account or close or suspend
              your account(s).
            </Typography>
            <Typography>
              • If any controlling person of the entity is a citizen or resident or green card
              holder of outside India, please include all such foreign country information field
              along with the relevantTax Identification Number.
            </Typography>
            <Typography>
              • Also note that you may receive more than one request to provide this information, if
              you have multiple relationships with us or our group entities. Therefore it is
              important that you respond to our request, even if you believe that you have already
              provided this information earlier Please note that above information is provided only
              for quick reference to customers. You are requested to refer Rule 114F, 114G and 114H
              of Income-tax Rules, 1962 consult a legal/tax advisor for any clarification.
            </Typography>
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SubHeading>FATCA Details of {applicantName} Applicant</SubHeading>
      <Grid item xs={12}>
        <MFCheckbox
          name={`applicants.${index}.wishToUploadFatcaDocument`}
          label={'Wish to upload FATCA-CRS Declaration'}
          sx={{ my: 2 }}
          disabled={isFieldDisabled}
        />
      </Grid>
      {!values.wishToUploadFatcaDocument && (
        <>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
              label="Type of Address proof provided *"
              items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
            />
          </Grid>
          <Grid item xs={12}>
            <UseRadioGroup
              formLabel="Tax Resident of any country *"
              name={`applicants.${index}.taxIdResidentOfAnyCountryOtherThanIndia`}
              items={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              disabled={isFieldDisabled}
            />
          </Grid>
          {/* {applicant?.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' && ( */}
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.placeOfBirth`}
                label="Place of Birth"
                placeholder="Enter Place of Birth"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchableSelect
                name={`applicants.${index}.countryOfBirth`}
                label="Country of Birth *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchableSelect
                name={`applicants.${index}.countryOfNationality`}
                label="Country of Citizenship/Nationality *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
            {values?.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' && (
              <>
                <Grid item xs={12} sm={6}>
                  <SearchableSelect
                    name={`applicants.${index}.taxCountryName`}
                    label="Country of Tax Residency/TIN Issuing Country *"
                    items={nationalitiesMdmsResponse.map((nationality) => ({
                      key: nationality.name,
                      value: nationality.name,
                    }))}
                    disabled={isFieldDisabled}
                    searchFieldPlaceholder={'Search Country'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.idType`}
                    label="ID Type *"
                    items={IdTypeMasters.map((_idType) => ({
                      key: _idType,
                      value: _idType,
                    }))}
                    disabled={isFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxID`}
                    label="Tax Identification Number (TIN)/Equivalent Number *"
                    placeholder="Enter TIN/Equivalent Number"
                    disabled={isFieldDisabled}
                  />
                </Grid>
                {values.idType === 'OTHERS' && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`applicants.${index}.idTypeOthers`}
                        label="Please Specify ID Type *"
                        placeholder="Enter ID Type"
                        disabled={isFieldDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <MFSelectField
                          name={`applicants.${index}.tinNotAvailableReason`}
                          label="Reason (If TIN is not available) *"
                          items={Object.keys(ReasonMaster).map((reason) => ({
                            key: ReasonMaster[reason].code,
                            value: reason,
                          }))}
                          disabled={isFieldDisabled}
                        />
                        <Typography component="span" ml={1} sx={{ cursor: 'pointer' }}>
                          <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
                        </Typography>
                      </Box>
                    </Grid>
                    {ReasonMaster[values.tinNotAvailableReason || '']?.code ===
                      ReasonMaster.reason_c.code && (
                      <Grid item xs={12} sm={6}>
                        <MFTextField
                          name={`applicants.${index}.othersTinNotAvailableReason`}
                          label="Please Specify Reason *"
                          placeholder="Enter Reason"
                          disabled={isFieldDisabled}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
            <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
              <UseRadioGroup
                formLabel="Are You a US Person *"
                name={`applicants.${index}.usPerson`}
                items={Object.keys(YesNoMaster).map((option) => ({
                  label: YesNoMaster[option],
                  value: option,
                }))}
                disabled={isFieldDisabled}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <MFCheckbox
                name={`applicants.${index}.fatcaDeclaration`}
                label="I/We acknowledge and confirm that the information provided above is true and correct to the best of my knowledge and belief. In case any of the above specified information is found to be false or untrue or misleading or misrepresenting,I/am aware that I may liable for it. I/We hereby authorize you [Fund / Investment Manager] to disclose, share, rely, remit in any form, mode or manner, all/ any of the information provided by me to you including information to any Indian or foreign judicial authorities/ agencies, Financial Intelligence Unit-India (FIU-IND), the tax/ revenue authorities in India or outside India wherever it is legally required and other investigation agencies without any obligation of advising me of the same. Further , I authorize to share the given information to other SEBI Registered Intermediaries/or any regulated intermediaries registered with SEBI / RBI / IRDA / PFRDA to facilitate single submission/ update & for other relevant purposes. I also undertake to keep you informed in writing about any changes/ modification to the above information in future within 30 days and also undertake to provide any other additional information as may be required at your/ Fund's end or by domestic or overseas regulators/ tax authorities. I/We authorize Fund /AMC/ RTA to provide relevant information to upstream payors to enable withholding to occur and pay out any sums from my account or close or suspend my account(s) without any obligation of advising me of the same. I also confirm that I have read and understood the FATCA & CRS Terms and Conditions given below and hereby accept the same."
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)',
                  alignItems: 'flex-start',
                  '.MuiTypography-root': {
                    fontSize: 15,
                    fontWeight: 500,
                  },
                }}
              />
            </Grid>
          </>
          {/* )} */}
        </>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 800,
            p: { xs: 2, sm: '15px 5px 35px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: '90%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'start',
              fontFamily: 'Work Sans,sans-serif',
            },
          }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            *If a TIN is unavailable, please provide the appropriate reason A, B or C as defined
            below:
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason A –{' '}
            </Typography>
            {ReasonMaster.reason_a.description}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason B –{' '}
            </Typography>
            {ReasonMaster.reason_b.description}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason C –{' '}
            </Typography>
            {ReasonMaster.reason_c.description}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{ minWidth: { xs: '170px', sm: '220px' }, mt: 3 }}
              onClick={handleClose}>
              Done
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default function FatcaForegin(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openGuidelines, setOpenGuidelines] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setFatcaDetails({
          ...fatcaDetails,
          applicants: applicants?.map((applicant) => ({
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxIdResidentOfAnyCountryOtherThanIndia:
              applicant.taxIdResidentOfAnyCountryOtherThanIndia || 'Yes',
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth:
              applicant.countryOfBirth || applicant.nationality === 'INDIAN'
                ? 'INDIA'
                : applicant.nationality || '',
            countryOfNationality:
              applicant.countryOfNationality || applicant.nationality === 'INDIAN'
                ? 'INDIA'
                : applicant.nationality || '',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || applicant.taxIdNumber || '',
            idType: applicant.idType || 'TIN' || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
            idTypeOthers: applicant.idTypeOthers || '',
            tinNotAvailableReason: applicant.tinNotAvailableReason || '',
            othersTinNotAvailableReason: applicant.othersTinNotAvailableReason || '',
            usPerson: applicant.usPerson || '',
            fatcaDeclaration: applicant.fatcaDeclaration || true,
            wishToUploadFatcaDocument:
              applicant.wishToUploadFatcaDocument === null
                ? false
                : applicant.wishToUploadFatcaDocument,
          })),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleOpenGuideline = () => {
    setOpenGuidelines(true);
  };
  const handleCloseGuideline = () => {
    setOpenGuidelines(false);
  };

  const handleOpenTermsAndConditions = () => {
    setOpen(true);
  };
  const handleCloseTermsAndConditions = () => {
    setOpen(false);
  };

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        if (applicants[index].fatcaDeclaration === false) {
          throw 'Declaration is required';
        }
        const {
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];

        if (applicants[index].wishToUploadFatcaDocument) {
          return {
            ...applicant,
            ...applicantObj,
            wishToUploadFatcaDocument: applicants[index].wishToUploadFatcaDocument,
            dateOfIncorporation: null,
          };
        } else {
          return {
            ...applicant,
            // dateOfBirth: dateOfBirth ? dateOfBirth : null,
            dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
            ...rest,
            placeOfBirth: removeSingleQuote(rest.placeOfBirth),
            taxCountryName:
              rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' ? rest.taxCountryName : '',
            taxID: rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' ? rest.taxID : '',
            idType: rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' ? rest.idType : '',
            idTypeOthers:
              rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' && rest.idType === 'OTHERS'
                ? removeSingleQuote(rest.idTypeOthers)
                : '',
            tinNotAvailableReason:
              rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' && rest.idType === 'OTHERS'
                ? rest.tinNotAvailableReason
                : '',
            othersTinNotAvailableReason:
              rest.taxIdResidentOfAnyCountryOtherThanIndia === 'Yes' &&
              ReasonMaster[rest.tinNotAvailableReason || '']?.code === ReasonMaster.reason_c.code
                ? removeSingleQuote(rest.othersTinNotAvailableReason)
                : '',
          };
        }
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedPayload,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedPayload,
              status: statusRoleBased(role, application),
              currentStep: getStep(5, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationForeginSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <GridItem>
            <Typography component="div" sx={{ display: 'flex' }}>
              <InputLabel
                shrink
                sx={{
                  transform: 'unset',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)',
                  mr: 1,
                }}>
                {'Terms and conditions'}
              </InputLabel>
              <InfoOutlinedIcon
                color="info"
                fontSize="small"
                onClick={handleOpenTermsAndConditions}
                sx={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography component="div" sx={{ display: 'flex' }}>
              <InputLabel
                shrink
                sx={{
                  transform: 'unset',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)',
                  mt: 1,

                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {'Guidelines'}
                <InfoOutlinedIcon
                  color="info"
                  fontSize="small"
                  sx={{ ml: 1, cursor: 'pointer' }}
                  onClick={handleOpenGuideline}
                />
              </InputLabel>
            </Typography>
          </GridItem>
          <TermsAndConditions onClose={handleCloseTermsAndConditions} open={open} />
          <GuidelinesForFatca onClose={handleCloseGuideline} open={openGuidelines} />
          {values.applicants.map((applicant, index) => (
            <FatcaDetails
              applicantName={getApplicantName(index + 1)}
              index={index}
              key={index}
              values={applicant}
              nationalitiesMdmsResponse={mdmsCountriesList}
            />
          ))}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
