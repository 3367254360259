import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { RTAApprover } from '../../redux-store/types/api-types';
import {
  AifApproverAccess,
  AmcApproverAccess,
  getAMCApproverById,
  getAmcApproverAccess,
  getRTAApproverById,
  getRtaApproverAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldReadonly } from './AddressFields';
import { styles } from '../../Styles/styles';
import { ENABLE_RTA_APPROVER } from '../../utils/constant';

export default function AIFApproverDetails({
  location,
}: {
  location: Location<{ aifApproverId: number }>;
}): JSX.Element {
  const { aifApproverId: id } = location.state || { aifApproverId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [aifApproverDetails, setAifApproverDetails] = useState<RTAApprover>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        let approverAccess: boolean;
        if (ENABLE_RTA_APPROVER) {
          const { rtaApproverAccess } = (await dispatch(
            getRtaApproverAccess()
          )) as unknown as AifApproverAccess;
          approverAccess = rtaApproverAccess;
        } else {
          const { amcApproverAccess } = (await dispatch(
            getAmcApproverAccess()
          )) as unknown as AmcApproverAccess;
          approverAccess = amcApproverAccess;
        }
        if (id) {
          const getAifApproverDetails = (await dispatch(
            ENABLE_RTA_APPROVER ? getRTAApproverById(id) : getAMCApproverById(id)
          )) as unknown as RTAApprover;
          setAifApproverDetails(getAifApproverDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(approverAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="AIF Approver"
          accessForEditAdd={accessForEditAdd}
          onClickBack={() => history.push('aif-approver')}
          onClickEdit={() =>
            history.push('edit-aifapprover', { aifApproverId: aifApproverDetails?.id })
          }
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          aifApproverDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Name'} description={aifApproverDetails.user.firstName} />
                    <KeyValue title={'Email Id'} description={aifApproverDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={aifApproverDetails.user.phone} />
                    <AddressFieldReadonly AddressField={aifApproverDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
