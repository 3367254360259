import { Grid } from '@mui/material';
import { MFTextField } from '../../lib/formik';
import { KeyValue } from '../ApplicationDetails/contributor';

export const AddressFieldEditable = ({
  accessForEditAdd = true,
}: {
  accessForEditAdd?: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="pincode"
          label="Pincode"
          placeholder="Enter Pincode"
          disabled={!accessForEditAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="buildingNo"
          label="Building Number"
          placeholder="Enter Building Number"
          disabled={!accessForEditAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="streetName"
          label="Street Name"
          placeholder="Enter Street Name"
          disabled={!accessForEditAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="state"
          label="State"
          placeholder="Enter State"
          disabled={!accessForEditAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="city"
          label="City"
          placeholder="Enter City"
          disabled={!accessForEditAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="country"
          label="Country"
          placeholder="Enter Country"
          disabled={!accessForEditAdd}
        />
      </Grid>
    </>
  );
};

export const AddressFieldReadonly = ({
  AddressField,
}: {
  AddressField: {
    pincode?: string;
    buildingNo?: string;
    streetName?: string;
    state?: string;
    city?: string;
    country?: string;
  };
}): JSX.Element => (
  <>
    <KeyValue title={'Pincode'} description={AddressField.pincode} />
    <KeyValue title={'Building Number'} description={AddressField.buildingNo} />
    <KeyValue title={'Street Name'} description={AddressField.streetName} />
    <KeyValue title={'State'} description={AddressField.state} />
    <KeyValue title={'City'} description={AddressField.city} />
    <KeyValue title={'Country'} description={AddressField.country} />
  </>
);
