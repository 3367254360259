import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { fundAccess, getPlanList } from '../../redux-store/actions/funds';
import { accessType, FundProps } from '../../redux-store/types/funds';
import { Location } from 'history';
import { KeyValue } from '../ApplicationDetails/contributor';
import { styles } from '../../Styles/styles';
import { getFundInvestorCategory } from '../../redux-store/actions';
import { mdmsFundInvestorCategoryType } from '../../redux-store/types/mdms';

export default function FundDetails({
  location,
}: {
  location: Location<{ fundId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [fund, setFund] = useState<FundProps>();
  const [access, setAccess] = useState(false);
  const { fundId } = location.state || {};
  const history = useHistory();
  const [mdmsFundInvestorType, setMdmsFundInvestorType] = useState<mdmsFundInvestorCategoryType>(
    {}
  );

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const categoryMasters = (await dispatch(
          getFundInvestorCategory()
        )) as unknown as mdmsFundInvestorCategoryType;
        setMdmsFundInvestorType(categoryMasters);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (fundId) {
          const res = (await dispatch(getPlanList(fundId))) as unknown as FundProps;
          setFund(res);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              justifyContent: 'space-between',
              flexDirection: { lg: 'row', xs: 'column' },
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 12, xs: 2 },
              pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('fund-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('fund-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Fund Details'}
              </Typography>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-fund`, { fundId: fundId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>
          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {fund && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Fund Details'}
                </SubHeading>
              </Box>
              <Grid
                container
                sx={{
                  mt: 1,
                  pl: { xs: 2, sm: 10 },
                  pr: { xs: 2, sm: 10 },
                }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Scheme Code'} description={fund?.schemeCode} />
                      <KeyValue title={'Scheme Name'} description={fund?.schemeName} />
                      <KeyValue title={'AIF/AMC Code'} description={fund?.amcCode} />
                      <KeyValue
                        title={'Bank Account Number'}
                        description={fund?.bankAccountNumber}
                      />
                      <KeyValue title={'Bank Branch'} description={fund?.bankBranch} />
                      <KeyValue title={'Bank Name'} description={fund?.bankName} />
                      <KeyValue title={'Bank IFSC Code'} description={fund?.ifscCode} />
                      <KeyValue
                        title={'Stamp Paper Series'}
                        description={fund?.LEEGALITY_STAMP_SERIES}
                      />
                      <KeyValue
                        title={'Stamp Paper Group'}
                        description={fund?.LEEGALITY_STAMP_SERIES_GROUP}
                      />
                      <KeyValue
                        title={'Stamp Paper Value'}
                        description={fund?.LEEGALITY_STAMP_VALUE}
                      />
                      <KeyValue
                        title={'Fund Cetegory'}
                        description={mdmsFundInvestorType[fund?.fundCategory || '']?.displayText}
                      />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
