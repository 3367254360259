import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../../redux-store/types/api-types';
import { KeyValue } from '../contributor';
import { Gridstyles } from '../index';
import { currencyConversion, folioValidationCheck } from '../../../utils/utilityFunctions';

export default function InvestAndPaymentDetailsForegin({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue
                  title={'Class of Units Subscribed'}
                  description={application.plan?.planDescription}
                />
                {application?.applicationSource &&
                !folioValidationCheck(application?.applicationSource) ? (
                  <KeyValue
                    title={'Capital Commitment'}
                    description={application.commitmentAmount}
                    amount={currencyConversion(
                      application.commitmentAmount,
                      application.plan?.currency
                    )}
                  />
                ) : (
                  <KeyValue
                    title={'Capital Commitment'}
                    description={application.commitmentAmountForMigration}
                  />
                )}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
