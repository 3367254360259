import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { Trustee } from '../../redux-store/types/api-types';
import {
  gettrusteeAccess,
  getTrustesById,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { AddressFieldReadonly } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';

export default function TrusteeDetails({
  location,
}: {
  location: Location<{ trusteeId: number }>;
}): JSX.Element {
  const { trusteeId: id } = location.state || { trusteeId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [trusteeDetails, setTrusteeDetails] = useState<Trustee>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { trusteeAccess } = (await dispatch(gettrusteeAccess())) as unknown as trusteeAccess;
        if (id) {
          const getTrusteeDetails = (await dispatch(getTrustesById(id))) as unknown as Trustee;
          setTrusteeDetails(getTrusteeDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(trusteeAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="Trustee"
          accessForEditAdd={accessForEditAdd}
          onClickBack={() => history.push('trustee')}
          onClickEdit={() => history.push('edit-trustee', { trusteeId: trusteeDetails?.id })}
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          trusteeDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Name (Individual)'}
                      description={trusteeDetails.user.firstName}
                    />
                    <KeyValue title={'Leegality Name'} description={trusteeDetails.leegalityName} />
                    <KeyValue title={'Pan'} description={trusteeDetails.panNumber} />
                    <KeyValue title={'Email Id'} description={trusteeDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={trusteeDetails.user.phone} />
                    <AddressFieldReadonly AddressField={trusteeDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
