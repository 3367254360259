import { CardMedia, Grid, InputAdornment } from '@mui/material';
import { ProceedSaveLater, SubHeading } from '../components';
import { MFTextField } from '../../../lib/formik';
import { Formik, FormikHandlers, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFCheckbox from '../../../lib/formik/Checkbox';
import {
  allowOnlyNumbers,
  applicationComparison,
  getAddressData,
  getAddressFields,
  getApplicantName,
  preventSpecialCharacters,
  saveForLater,
  _updatedAddresses,
  checkForCorrespondenceAddress,
  removeSingleQuote,
  statusRoleBased,
  getStep,
} from '../../../utils/utilityFunctions';
import { ApplicantAddressType, ApplicationProps } from '../../../redux-store/types/api-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootStateType } from '../../../redux-store/reducers';
import { updateApplication } from '../../../redux-store/actions/application';
import { contactDetailsSchema } from '../../../utils/schema';
import {
  USER_ROLES,
  applicantStatusMasters,
  // eslint-disable-next-line
  ENABLE_EMAIL_OTP,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../../utils/constant';
import { MobileNumberField } from '../../commonComponents';
import { getNationalityList } from '../../../redux-store/actions';
import { mdmsCountriesList, nationaliyType } from '../../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../../lib/formik/searchSelectField';
import FieldValidationNote from '../FieldValidationNote';
import { nonDigitRegex } from '../../../utils/regex';

type ContactDetailsProps = {
  phoneNumberoffice: string;
  phoneNumberResidence: string;
  email: string;
  mobile: string;
  countryNameAndCode: string;
  countryCode: string;
  address: {
    nationality?: string | null;
    status?: string | null;
    permanent?: Partial<ApplicantAddressType>;
    correspondence?: Partial<ApplicantAddressType>;
    overseas?: Partial<ApplicantAddressType>;
    [key: string]: Partial<ApplicantAddressType> | string | null | undefined;
  };
  kraMobileNumber: string;
  kraEmailId: string;
};

export type Values = {
  hasPOA: boolean;
  applicants: ContactDetailsProps[];
  saveType: string;
  countryDropdown: string[];
  [key: string]: string | boolean | ContactDetailsProps[] | string[];
};

export const Address = ({
  addressType = '',
  index,
  nationalitiesMdmsResponse,
  fetchedFromKRACheck,
}: {
  addressType: string;
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  fetchedFromKRACheck: boolean | null;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled =
    (['overseas', 'permanent'].includes(addressType) &&
      [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)) ||
    (addressType !== 'correspondence' && fetchedFromKRACheck) ||
    false;
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.address1`}
          label={`Address Line 1 *`}
          placeholder="Enter Address Line 1"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.address2`}
          label={`Address Line 2 ${
            !fetchedFromKRACheck || (fetchedFromKRACheck && addressType !== 'permanent') ? '*' : ''
          }`}
          placeholder="Enter Address Line 2"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.pincode`}
          label={`Pincode *`}
          placeholder="Enter Pincode"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.city`}
          label={`City *`}
          placeholder="Enter City"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.state`}
          label={addressType === 'overseas' ? `State` : `State *`}
          placeholder="Enter State"
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* {!nationalitiesMdmsResponse && (
          <MFTextField
            name={`applicants.${index}.address.${addressType}.country`}
            label={`Country *`}
            placeholder="Enter Country"
            disabled={isFieldDisabled}
          />
        )} */}
        {/* {nationalitiesMdmsResponse && ( */}
        <SearchableSelect
          name={`applicants.${index}.address.${addressType}.country`}
          label="Country *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          disabled={isFieldDisabled}
          searchFieldPlaceholder={'Search Country'}
        />
        {/* )} */}
      </Grid>
    </>
  );
};

const ApplicantDetails = ({
  values,
  handleChange,
  setValues,
  mdmsCountriesList,
  application,
}: {
  values: Values;
  handleChange: FormikHandlers['handleChange'];
  setValues: FormikHelpers<Values>['setValues'];
  mdmsCountriesList: mdmsCountriesList[];
  application: ApplicationProps | null;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  return (
    <React.Fragment>
      {values.applicants.map((applicant, index) => (
        <React.Fragment key={index}>
          <SubHeading>Contact details of {getApplicantName(index + 1)} Applicant</SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.email`}
              label="E-Mail ID *"
              placeholder="Enter E-Mail ID"
              // readOnly={values.hasPOA ? false : true && ENABLE_EMAIL_OTP}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
                </InputAdornment>
              }
              // disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
              disabled={
                (!application?.editable && USER_ROLES.INVESTOR !== role) ||
                (USER_ROLES.INVESTOR === role && index !== 0)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MobileNumberField
              label="Mobile *"
              name={`applicants.${index}.mobile`}
              placeholder="Enter Mobile number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              regexForFilterValue={nonDigitRegex}
              countryCodeFieldName={`applicants.${index}.countryNameAndCode`}
              countryCodeValue={values.applicants[index].countryNameAndCode}
              disabled={
                (!application?.editable && USER_ROLES.INVESTOR !== role) ||
                (USER_ROLES.INVESTOR === role && index !== 0)
              }
            />
          </Grid>
          {applicant.kraMobileNumber && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraMobileNumber`}
                label={`KRA Mobile Number`}
                placeholder="Enter KRA Mobile Number"
                disabled={true}
              />
            </Grid>
          )}
          {applicant.kraEmailId && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraEmailId`}
                label={`KRA Email Id`}
                placeholder="Enter KRA Email Id"
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberoffice`}
              label="Phone (Office)"
              placeholder="Enter Phone (Office)"
              onChange={handleChange}
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberResidence`}
              label="Phone (Residential)"
              placeholder="Enter Phone (Residential)"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
            />
          </Grid>
          {/* {applicant.address.nationality?.toLowerCase() !== 'indian' && (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
              />
            </React.Fragment>
          )} */}
          {checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          ) ? (
            <>
              <SubHeading>
                Correspondence Address of {getApplicantName(index + 1)} Applicant
              </SubHeading>
              <Address
                addressType={'correspondence'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                key={values.applicants.length}
                fetchedFromKRACheck={applicant.address.correspondence?.fetchedFromKRA || null}
              />
            </>
          ) : (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                fetchedFromKRACheck={applicant.address.overseas?.fetchedFromKRA || null}
              />
            </React.Fragment>
          )}
          {applicant.address.nationality &&
            !applicant.address.permanent?.fetchedFromKRA &&
            !applicant.address.correspondence?.fetchedFromKRA &&
            !applicant.address.overseas?.fetchedFromKRA && (
              <MFCheckbox
                onChange={({ target: { checked } }) =>
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          address: checkForCorrespondenceAddress(
                            _applicant.address.nationality,
                            _applicant.address.status || ''
                          )
                            ? {
                                ..._applicant.address,
                                correspondence: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.correspondence!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              }
                            : {
                                ..._applicant.address,
                                overseas: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.overseas!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              },
                        };
                      }
                      return _applicant;
                    }),
                  })
                }
                name={`applicants.${index}.address.${
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'correspondence'
                    : 'overseas'
                }.permanentAddressSameAsCorresponding`}
                label={
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'Permanent address same as correspondence address.'
                    : 'Permanent address same as overseas address.'
                }
                sx={{ ml: '40px' }}
                disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
              />
            )}
          {(checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          )
            ? !values.applicants[index]?.address?.correspondence
                ?.permanentAddressSameAsCorresponding
            : !values.applicants[index]?.address?.overseas
                ?.permanentAddressSameAsCorresponding) && (
            <>
              <SubHeading>Permanent Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'permanent'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                fetchedFromKRACheck={applicant.address.permanent?.fetchedFromKRA || null}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default function ContactDetails(): JSX.Element {
  const initialValues: Values = {
    hasPOA: false,
    applicants: [
      {
        phoneNumberoffice: '',
        phoneNumberResidence: '',
        email: '',
        mobile: '',
        countryNameAndCode: 'India: +91',
        countryCode: '+91',
        address: {
          nationality: null,
          status: null,
          permanent: getAddressFields('permanent'),
          correspondence: getAddressFields('correspondence'),
          overseas: getAddressFields('overseas'),
        },
        kraMobileNumber: '',
        kraEmailId: '',
      },
    ],
    saveType: 'save and proceed',
    countryDropdown: [],
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [contactDetails, setContactDetails] = useState(initialValues);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
      } = application || {};
      const { saveType = '' } = values;
      const updatePayload = applicants
        .map((applicant, index: number) => {
          const { address: updatedAddresses = {}, ...rest } = values.applicants[index] || {};
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { nationality, status, ...addressProps } = updatedAddresses;
          return {
            ...applicant,
            ...rest,
            email: removeSingleQuote(rest.email),
            countryCode: rest.countryNameAndCode?.split(':')[1].trim(),
            addresses:
              !checkForCorrespondenceAddress(applicant.nationality, status) &&
              applicant.addresses?.map((address) => address.address_type).includes('correspondence')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('correspondence', applicant.addresses),
                      isActive: false,
                      address_type: 'correspondence',
                    },
                  ]
                : checkForCorrespondenceAddress(applicant.nationality, status) &&
                  applicant.addresses?.map((address) => address.address_type).includes('overseas')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('overseas', applicant.addresses),
                      isActive: false,
                      address_type: 'overseas',
                    },
                  ]
                : _updatedAddresses(updatedAddresses, applicant),
          };
        })
        .map((_applicant) => {
          return {
            ..._applicant,
            addresses: _applicant.addresses.map((address) => {
              return {
                ...address,
                address1: removeSingleQuote(address.address1),
                address2: removeSingleQuote(address.address2),
                city: removeSingleQuote(address.city),
                state: removeSingleQuote(address.state),
              };
            }),
          };
        });
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                addresses: applicant.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...application!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          applicants: updatePayload
            .map((applicant) => {
              return {
                ...applicant,
                addresses: applicant?.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          currentStep: !!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              applicants: updatePayload!,
              status: statusRoleBased(role, application),
              currentStep: getStep(3, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        !isSaveLater
          ? history.push('additional-KYC-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('additional-KYC-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };
  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  // }, []);
  useEffect(() => {
    const { applicants: exisitingApplicants = [], hasPOA = false } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setContactDetails({
          ...contactDetails,
          hasPOA,
          applicants:
            exisitingApplicants?.length === 0
              ? exisitingApplicants.map((applicant) => {
                  const {
                    nationality = '',
                    status = '',
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    // eslint-disable-next-line
                    countryNameAndCode = 'India: +91',
                    // eslint-disable-next-line
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmailId = '',
                  } = applicant;
                  const defaultPayload = {
                    nationality,
                    status,
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: null,
                      status: null,
                      permanent: getAddressFields('permanent'),
                    },
                    kraMobileNumber,
                    kraEmailId,
                  };
                  if (
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual
                  ) {
                    return {
                      ...defaultPayload,
                      address: {
                        ...defaultPayload.address,
                        correspondence: getAddressFields('correspondence'),
                      },
                    };
                  }
                  return {
                    ...defaultPayload,
                    address: {
                      ...defaultPayload.address,
                      overseasAddress: getAddressFields('overseas'),
                    },
                  };
                })
              : exisitingApplicants?.map((applicant) => {
                  const {
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    // eslint-disable-next-line
                    countryNameAndCode = 'India: +91',
                    // eslint-disable-next-line
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmailId = '',
                  } = applicant;
                  const correspondence = getAddressData('correspondence', applicant.addresses);
                  const permanent = getAddressData('permanent', applicant.addresses);
                  const overseas = getAddressData('overseas', applicant.addresses);
                  const defaultPayload = {
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: applicant.nationality,
                      status: applicant.status,
                    },
                    kraMobileNumber,
                    kraEmailId,
                  };
                  const permanentAddressPayload =
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual
                      ? correspondence.permanentAddressSameAsCorresponding
                        ? defaultPayload
                        : {
                            ...defaultPayload,
                            address: {
                              ...defaultPayload.address,
                              permanent: {
                                ...permanent,
                                country: permanent.country
                                  ? permanent.country.toUpperCase()
                                  : 'INDIA',
                              },
                            },
                          }
                      : overseas.permanentAddressSameAsCorresponding
                      ? defaultPayload
                      : {
                          ...defaultPayload,
                          address: {
                            ...defaultPayload.address,
                            permanent: {
                              ...permanent,
                              country: permanent.country
                                ? permanent.country.toUpperCase()
                                : 'INDIA',
                            },
                          },
                        };
                  return ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual
                    ? {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          correspondence: {
                            ...correspondence,
                            country: correspondence.country
                              ? correspondence.country.toUpperCase()
                              : 'INDIA',
                          },
                        },
                      }
                    : {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          overseas: {
                            ...overseas,
                            country: overseas.country ? overseas.country.toUpperCase() : '',
                          },
                        },
                      };
                }),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  return (
    <Formik
      initialValues={contactDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, contactDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, handleChange, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <ApplicantDetails
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            mdmsCountriesList={mdmsCountriesList}
            application={application}
          />
          <FieldValidationNote />
          {!!application?.applicants.length && (
            <ProceedSaveLater
              saveLater={() => setFieldValue('saveType', 'save for later')}
              saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
              loader={loading}
              clickedButton={values.saveType}
            />
          )}
        </Grid>
      )}
    </Formik>
  );
}
