import { AnyAction } from 'redux';
import { ProfilePayload, GET_PROFILE_DETAILS, SET_PROFILE_DETAILS } from '../types/profile';

const initState: ProfilePayload = {
  name: 'Himagni Patel',
  email: 'himangi.patel@web.email',
  contact: 988899999,
  address1: '123 hno',
  address2: '2 hno',
  address3: '3 hno',
  pan: '123455666',
  empcode: 12122,
};
// eslint-disable-next-line
function profileReducer(state = initState, action: AnyAction) {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...action.body,
      };
    case SET_PROFILE_DETAILS: {
      return {
        ...action.body,
      };
    }
    default:
      return state;
  }
}
export default profileReducer;
