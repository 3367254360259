import { Box, IconButton, Typography, CardMedia, Tooltip, Theme } from '@mui/material';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
  applicationDownloadStatusCheck,
  checkIfApplicationIsNonIndividual,
  displaySendBack,
  getFirstHolderName,
  isGetLinkVisible,
} from '../../utils/utilityFunctions';
import {
  ConfirmationDialog,
  CopyLinkfromApplication,
  LoadingDialog,
  RejectionDialog,
} from '../commonComponents';
import React, { useEffect, useState } from 'react';
import { getAllApplications, getApplicationDetails } from '../../redux-store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  investmentDetailsAplicationRefWithFlowType,
} from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { APPLICATION_LISTING_STATUS, USER_ROLES } from '../../utils/constant';
import { ApplicationDownload, ApplicationRefs } from '../../redux-store/actions/onBoarding';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RootStateType } from '../../redux-store/reducers';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';
import { reportDownload } from '../Reports/report-utils';

export const getTableHeader = (): ColumnType[] => {
  return [
    {
      header: 'Application Number',
      label: 'applicationNumber',
      valueGetter: (row: ApplicationProps) => row.applicationNumber || 'N/A',
    },
    {
      header: 'Investor Name',
      valueGetter: ({ applicants = [] }: ApplicationProps) =>
        getFirstHolderName(applicants) || 'N/A',
    },
    {
      header: 'Mode of Holding',
      renderCell: (row: ApplicationProps) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}>
            {row.modeOfHolding || 'Single'}
          </Typography>
        );
      },
    },
    {
      header: 'Investor PAN/Tax Id',
      valueGetter: ({ applicants = [] }: ApplicationProps) =>
        applicants[0]?.panNo || applicants[0]?.taxIdNumber || 'N/A',
    },
    {
      header: 'Distributor Name',
      label: 'viewDistributorRM',
      valueGetter: (row: ApplicationProps) =>
        row.distributor?.name || row.distributor?.arnCode || 'N/A',
    },
    {
      header: 'Filled By',
      label: 'filledBy',
      valueGetter: (row: ApplicationProps) => row.filledBy || 'N/A',
    },
    {
      header: 'Status',
      renderCell: (row: ApplicationProps) => {
        const applicationStatus = APPLICATION_LISTING_STATUS[row.status];
        return (
          <Typography
            className={applicationStatus}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 14,
              color: '#B78813',
              '&.Draft': {
                color: 'rgba(51, 127, 201, 0.7)',
              },
              '&.Completed': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
              '&.Rejected': {
                color: '#BD2932 !important',
                fontWeight: 500,
              },
              '& span': {
                display: 'block',
                color: 'rgba(51, 127, 201, 0.7)',
                mt: 0.5,
              },
            }}
            component={RouterLink}
            to={
              checkIfApplicationIsNonIndividual(row)
                ? `/non-individual-application-details/${row.id}`
                : `/application-details/${row.id}`
            }>
            {`${applicationStatus}  ${displaySendBack(row)}`}
            {APPLICATION_LISTING_STATUS.sent_to_applicant1 === applicationStatus && (
              <span>Leegality Document {row.leegalityDocumentId ? 'generated' : 'pending'}</span>
            )}
          </Typography>
        );
      },
    },
  ];
};

export default function DashboardAndApplicationsList({
  isLoading,
  applicationsList,
  boxStyles,
  DashboardApplicationsList,
}: {
  isLoading: boolean;
  applicationsList: ApplicationProps[];
  boxStyles?: SxProps<Theme>;
  DashboardApplicationsList?: boolean;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rejectionDialog, setRejectionDialog] = useState(false);

  const [rejectedApplication, setRejectedApplication] = useState<ApplicationProps>();
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [id, setId] = useState('');
  const [checkPoaOrNI, setCheckPoaOrNI] = useState(false);
  const [applicationRefsList, setApplicationRefsList] =
    useState<investmentDetailsAplicationRefWithFlowType>({ flowType: '', refLinks: [] });
  const [open, setOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState<{ id: string } | null>(null);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [applicationsListData, setApplicationsListData] = useState<ApplicationProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleDialogClose = () => {
    setDialogLoading(false);
    setOpen(true);
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialog(false);
  };
  useEffect(() => {
    setApplicationsListData(applicationsList);
  }, [applicationsList]);

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        if (id) {
          const responseApplicationRefsList = (await dispatch(
            ApplicationRefs(id)
          )) as unknown as investmentDetailsAplicationRefWithFlowType;
          if (!componentIsAlive) {
            return;
          }
          setApplicationRefsList(responseApplicationRefsList);
          handleDialogClose();
        }
      } catch (e) {
        setDialogLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [id]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: ApplicationProps): JSX.Element => {
      const [loadingPopup, setLoadingPopup] = useState(false);

      const handleLoadingPopupClose = () => {
        setLoadingPopup(false);
      };

      return (
        <Box display="flex">
          {APPLICATION_LISTING_STATUS[row.status] === APPLICATION_LISTING_STATUS.rejected && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/comment.svg"
                alt="comment"
                sx={{ width: '20px' }}
                onClick={() => {
                  setRejectedApplication(row);
                  setRejectionDialog(true);
                }}
              />
            </IconButton>
          )}
          {isGetLinkVisible(row.status) && (
            <Tooltip title="Link">
              <IconButton
                sx={{
                  p: 0,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}
                onClick={() => {
                  setId(row.id as string),
                    setDialogLoading(true),
                    setCheckPoaOrNI(row.hasPOA || checkIfApplicationIsNonIndividual(row));
                }}>
                <CardMedia
                  component="img"
                  src="/images/link-icon.svg"
                  alt="Link"
                  sx={{ width: '30px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={row.editable ? 'Edit' : ''}>
            <IconButton
              onClick={() => {
                row.editable &&
                  history.push(
                    checkIfApplicationIsNonIndividual(row)
                      ? `/non-individual-application`
                      : `/application`,
                    { id: row.id }
                  );
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                '&.Mui-disabled': {
                  cursor: row.editable ? 'pointer' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
              disabled={!row.editable}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
            <Tooltip title={row.deletable ? 'Delete' : 'Application can`t be Deleted'}>
              <IconButton
                sx={{
                  p: 0,
                  pr: 0.5,
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                  '&.Mui-disabled': {
                    cursor: row.deletable ? 'pointer' : 'not-allowed !important',
                    pointerEvents: 'all',
                  },
                }}
                onClick={() => row.deletable && setDeletePopUp({ id: row.id })}
                disabled={!row.deletable}>
                <DeleteOutlineIcon color={row.deletable ? 'error' : 'disabled'} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                ? 'Download'
                : 'Only Signed Documents can be Downloaded'
            }>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={async () => {
                try {
                  if (applicationDownloadStatusCheck(row.status || '')) {
                    setLoadingPopup(true);
                    const res = (await dispatch(ApplicationDownload(row.id))) as unknown as any;
                    reportDownload(res as unknown as Blob, `${row.id}.pdf`);
                    setLoadingPopup(false);
                    return;
                  }
                  row?.signedLeegalityFile?.url && window.open(row?.signedLeegalityFile?.url);
                } catch (e) {
                  setLoadingPopup(false);
                  console.error((e as Error).message);
                }
              }}>
              <CardMedia
                component="img"
                src={
                  applicationDownloadStatusCheck(row.status || '') || row?.signedLeegalityFile?.url
                    ? '/images/download-icon.png'
                    : '/images/download-icon-disabled.png'
                }
                alt="Download"
                //sx={{ width: '30px' }}
              />
            </IconButton>
          </Tooltip>
          <LoadingDialog
            loadingPopup={loadingPopup}
            onLoadingPopupClose={handleLoadingPopupClose}
            loaderText={'Downloading'}
          />
        </Box>
      );
    },
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        tableHeader={[...getTableHeader(), additionalCell]}
        tableData={applicationsListData}
        rowCustomStyles={{
          '&:last-child td, &:last-child th': { border: 0 },
          '.MuiTableCell-body ': { color: '#686e72' },
          background: 'transparent',
        }}
        boxStyles={boxStyles}
      />

      <CopyLinkfromApplication
        applicationRefsList={applicationRefsList}
        checkPoaOrNI={checkPoaOrNI}
        handleClose={handleClose}
        open={open}
      />
      <LoadingDialog
        loadingPopup={isDialogLoading}
        onLoadingPopupClose={handleDialogClose}
        loaderText={'Loading'}
      />
      <RejectionDialog
        handleRejectionDialogClose={handleRejectionDialogClose}
        rejectedApplication={rejectedApplication}
        rejectionDialog={rejectionDialog}
      />
      <ConfirmationDialog
        message={'Are you sure you want to delete Application ?'}
        open={deletePopUp !== null}
        setOpen={() => setDeletePopUp(null)}
        onSave={async () => {
          try {
            setDeletePopUp(null);
            // eslint-disable-next-line
            const _application = (await dispatch(
              getApplicationDetails(deletePopUp?.id || '', 'delete')
            )) as unknown as ApplicationProps;
            if (DashboardApplicationsList) {
              setDialogLoading(true);
              const response = (await dispatch(
                getAllApplications({
                  page: 1,
                  limit: 4,
                })
              )) as unknown as GetAllApplicantionsResponseBody;
              const { applications } = response || {};
              setApplicationsListData(applications);
              setDialogLoading(false);
            } else {
              setApplicationsListData(
                applicationsListData.filter(
                  (application) => application.id !== deletePopUp?.id || ''
                )
              );
            }
            enqueueSnackbar('Application Deleted Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } catch (e) {
            console.error((e as Error).message);
          } finally {
            setDialogLoading(false);
          }
        }}
        onCancel={() => setDeletePopUp(null)}
      />
    </>
  );
}
