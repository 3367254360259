import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, Bank } from '../../../redux-store/types/api-types';
import { KeyValue } from '../contributor';
import { Gridstyles } from '../index';
import { SubHeading } from '../../investors/components';
import { getBankAddress } from '../../../utils/utilityFunctions';
import { styles } from '../../../Styles/styles';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { applicantStatusMasters, bankOverseasDomesticMaster } from '../../../utils/constant';

export default function BankDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { banks = [] as Partial<Bank>[], investor_bank_type = '' } =
    application as ApplicationProps;
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Investor&apos;s Bank Details
              </Typography>
              {investor_bank_type &&
                applicantStatusMasters[application?.applicants[0]?.statusSubType as string] ===
                  applicantStatusMasters.NRI_FRI && (
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}>
                    Applicant Bank Type:{' '}
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: '16px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                        textTransform: 'capitalize',
                      }}>
                      {bankOverseasDomesticMaster[investor_bank_type]}
                    </Typography>
                  </Typography>
                )}
            </Grid>
            {banks.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Bank Details
              </Grid>
            ) : (
              banks.map((bank, index: number) => {
                const title = ['Bank 1 Details', 'Bank 2 Details', 'Bank 3 Details'][index];
                const bankAddress = getBankAddress(bank.address1, bank.address2, bank.address3);
                return (
                  <React.Fragment key={bank.id}>
                    <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        {bankOverseasDomesticMaster[bank.bank_type || ''] ===
                        bankOverseasDomesticMaster.overseas ? (
                          <>
                            <KeyValue title={'Swift Code'} description={bank.swiftCode} />
                            <KeyValue
                              title={'Bank Account Number'}
                              description={bank.bankAccountNumber}
                            />
                            <KeyValue title={'Account Type'} description={bank.bankAccountType} />
                            <KeyValue title={'Bank Name'} description={bank.bankName} />
                            <KeyValue title={'Bank Branch'} description={bank.bankBranch} />
                            <KeyValue
                              title={`Bank Address`}
                              description={`${bankAddress}`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                            <KeyValue
                              title={'IBAN (International Bank Account Number)'}
                              description={bank.ibanNumber}
                            />
                            <KeyValue
                              title={'CIF (Customer Information File) if any'}
                              description={bank.cif}
                            />
                            <KeyValue
                              title={`Default Bank Account`}
                              description={bank.defaultBankAccount ? 'Yes' : '-'}
                            />
                          </>
                        ) : (
                          <>
                            <KeyValue title={'IFSC Code'} description={bank.ifscCode} />
                            <KeyValue
                              title={'Bank Account Number'}
                              description={bank.bankAccountNumber}
                            />
                            <KeyValue title={'MICR Code'} description={bank.micrCode} />
                            <KeyValue title={'Bank Type'} description={bank.bankAccountType} />
                            <KeyValue title={'Bank Name'} description={bank.bankName} />
                            <KeyValue title={'Branch Name'} description={bank.bankBranch} />
                            <KeyValue
                              title={`Bank Address`}
                              description={`${bankAddress}`}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            />
                            <KeyValue
                              title={`Default Bank Account`}
                              description={bank.defaultBankAccount ? 'Yes' : '-'}
                            />
                            <KeyValue
                              title={`Penny Drop Verified`}
                              description={
                                bank.pennydropCheck ? (bank.pennydropVerified ? 'Yes' : 'No') : '-'
                              }
                            />
                          </>
                        )}
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
