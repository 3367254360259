import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MFSubmitButton, MFTextField } from '../../../lib/formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  ApplicationProps,
  IsApplicableForPOAResponseBody,
} from '../../../redux-store/types/api-types';
import {
  addJointHolderForeginFlow,
  createApplicationForeginFlow,
} from '../../../redux-store/actions/application';
import { emailRegex, nonDigitRegex, phoneRegExp } from '../../../utils/regex';
import { Location } from 'history';
import { DatePicker } from '../../../lib/formik/DatePicker';
import {
  getCountryCodes,
  getFieldLabelForGuardian,
  isMinor,
  isNonIndividualApplication,
  isOnboardingTypeMinor,
  preventSpecialCharacters,
} from '../../../utils/utilityFunctions';
import {
  IS_DOB_APPLICABLE_FOR_AMC,
  ONBOARDING_INVESTOR,
  onboardingTypeMasters,
} from '../../../utils/constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { MobileNumberField } from '../../commonComponents';
import UseRadioGroup from '../../../lib/formik/Radio';
import { FundCategoryAndInvestorTypeToDisplay } from '../Domestic/enterPAN';
import MFCheckbox from '../../../lib/formik/Checkbox';
import { IsApplicableForPOA } from '../../../redux-store/actions/onBoarding';
import { Notes } from '../../investors/components';
interface Values {
  taxIdNumber: string;
  dob: string;
  countryCode: string;
  countryNameAndCode: string;
  applicationType: string;
  mobile: string;
  emailId: string;
  hasPOA: boolean;
  onboardingType: string;
}

export default function EnterTaxId({
  location,
}: {
  location: Location<{
    firstHolderName: string;
    application: ApplicationProps;
    redirectTo: string;
    onboardingFundCategory: string;
    onboardingInvestorType: string;
  }>;
}): JSX.Element {
  const {
    redirectTo = '',
    onboardingFundCategory = '',
    onboardingInvestorType = '',
    application,
  } = location.state || {};
  const {
    id,
    applicants = [],
    onboardingFundCategory: onboardingFundCategoryfromApplication = '',
    onboardingInvestorType: onboardingInvestorTypeFromApplication = '',
    hasPOA: applicationHasPoa,
    onboardingType = '',
  } = application || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [disablePOA, setDisablePOA] = useState(false);

  const applicantPanNumbers = applicants.map((applicant) => applicant.taxIdNumber);
  const applicantMobileNumbers = applicants.map((applicant) => applicant.mobile);
  const validationSchema1 = yup.object().shape({
    taxIdNumber: yup.string().test('taxIdNumber', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError } = context;
      if (!fieldValue) {
        return createError({
          message: 'Tax Id is required',
          path,
        });
      }
      if (applicantPanNumbers.includes(fieldValue)) {
        return createError({
          message: 'There is already same Tax Id for an applicant associated with this application',
          path,
        });
      }
      return true;
    }),
    dob: yup.string().test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (IS_DOB_APPLICABLE_FOR_AMC) {
        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        }
      }
      if (
        fieldValue &&
        isMinor(fieldValue) &&
        ONBOARDING_INVESTOR[parent.applicationType] === ONBOARDING_INVESTOR.individual
      ) {
        return createError({
          message: 'Date of birth should be greater than 18',
        });
      }
      return true;
    }),
    emailId: yup.string().test('emailId', (value, context) => {
      const { createError, path, parent } = context;
      if (!value && !parent.hasPOA) {
        return createError({
          message: 'Email Id is required',
          path,
        });
      }
      if (value && !emailRegex.test(value)) {
        return createError({
          message: 'Invalid Email Id',
          path,
        });
      }
      return true;
    }),

    mobile: yup.string().test('mobile', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (
        !value &&
        ONBOARDING_INVESTOR[parent.applicationType] === ONBOARDING_INVESTOR.non_individual
      ) {
        return true;
      }
      if (!value && !parent.hasPOA) {
        return createError({
          message: 'Mobile number is required',
          path,
        });
      }
      if (value && parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (value && !isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (applicantMobileNumbers.includes(value)) {
        return createError({
          message:
            'There is already same mobile number for an applicant associated with this application',
          path,
        });
      }
      return true;
    }),
    countryNameAndCode: yup
      .string()
      .nullable()
      .test('countryNameAndCode', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === value) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (
          !value &&
          ONBOARDING_INVESTOR[parent.applicationType] === ONBOARDING_INVESTOR.non_individual
        ) {
          return true;
        }
        if (!value) {
          return createError({
            message: 'Country Code is required',
            path,
          });
        }
        if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }

        return true;
      }),
    applicationType: yup.string().nullable().required('Onboarding Investor Type is required'),
    onboardingType: yup
      .string()
      .nullable()
      .when('applicationType', {
        is: (applicationType: string) =>
          ONBOARDING_INVESTOR[applicationType] === ONBOARDING_INVESTOR.individual,
        then: yup.string().nullable().required('Investor Type is required'),
      }),
  });
  const initialValues1: Values = {
    taxIdNumber: '',
    dob: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    applicationType: id && redirectTo ? 'individual' : '',
    mobile: '',
    emailId: '',
    hasPOA: id && redirectTo ? applicationHasPoa : false,
    onboardingType: id ? onboardingType : 'normal',
  };

  useEffect(() => {
    (async function () {
      const res =
        !application?.hasPOA &&
        ((await dispatch(IsApplicableForPOA())) as unknown as IsApplicableForPOAResponseBody);
      const { powerOfAttorney } = res || { powerOfAttorney: false };
      setDisablePOA(application?.hasPOA ? application?.hasPOA : !powerOfAttorney);
    })();
  }, []);

  const handleSubmit1 = async (values: Values) => {
    try {
      const {
        taxIdNumber,
        dob,
        applicationType,
        countryNameAndCode,
        mobile: mobileNumber,
        emailId,
        hasPOA,
        onboardingType,
      } = values;

      if (id && redirectTo) {
        const res = (await dispatch(
          addJointHolderForeginFlow(
            {
              taxIdNumber,
              dob,
              applicationType,
              countryCode: countryNameAndCode?.split(':')[1].trim(),
              countryNameAndCode,
              mobileNumber,
              emailId,
              onboardingFundCategory: onboardingFundCategoryfromApplication,
              onboardingInvestorType: onboardingInvestorTypeFromApplication,
              powerOfAttorney: hasPOA,
              onboardingType,
            },
            id
          )
        )) as unknown as ApplicationProps;
        history.push(redirectTo, { ...(res || {}) });
        return;
      }
      const res = (await dispatch(
        createApplicationForeginFlow({
          taxIdNumber,
          dob,
          applicationType,
          countryCode: countryNameAndCode?.split(':')[1].trim(),
          countryNameAndCode,
          mobileNumber,
          emailId,
          onboardingFundCategory,
          onboardingInvestorType,
          powerOfAttorney: hasPOA,
          onboardingType,
        })
      )) as unknown as ApplicationProps;
      ONBOARDING_INVESTOR.individual === ONBOARDING_INVESTOR[applicationType]
        ? history.push('/application/distributor-details', { ...(res || {}) })
        : history.push('/non-individual-application', { ...(res || {}) });
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({ handleSubmit, values, setValues }) => {
          const { applicationType, onboardingType } = values;
          const label = getFieldLabelForGuardian(onboardingType);
          return (
            <>
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <FundCategoryAndInvestorTypeToDisplay
                  onboardingFundCategory={
                    onboardingFundCategory || onboardingFundCategoryfromApplication
                  }
                  onboardingInvestorType={
                    onboardingInvestorType || onboardingInvestorTypeFromApplication
                  }
                />
                <UseRadioGroup
                  disabled={id ? true : false}
                  formLabel="Investor Type *"
                  name="onboardingType"
                  items={Object.keys(onboardingTypeMasters)
                    .filter((item) =>
                      isNonIndividualApplication(applicationType)
                        ? item.toLowerCase() === 'normal'
                        : item
                    )
                    .map((type) => ({
                      value: type,
                      label: onboardingTypeMasters[type],
                    }))}
                />
                {isOnboardingTypeMinor(values.onboardingType) && (
                  <Box
                    sx={{
                      '& .MuiSvgIcon-root': { display: 'none' },
                      '& .MuiTypography-root': { ml: 0 },
                    }}>
                    <Notes
                      displayContent={
                        'You have selected Minor Investor Onboarding. Please provide Guardian PAN and Date of Birth'
                      }
                    />
                  </Box>
                )}
                <MFTextField
                  label={`${label} Tax Id *`}
                  name="taxIdNumber"
                  placeholder="Enter Tax Id"
                  required
                  autoComplete="off"
                />
                {IS_DOB_APPLICABLE_FOR_AMC && (
                  <DatePicker
                    label={`${label} Date of ${
                      isNonIndividualApplication(applicationType) ? 'Incorporation' : 'Birth'
                    } *`}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name="dob"
                  />
                )}
                <Box sx={{ mt: 1.5 }}>
                  <UseRadioGroup
                    formLabel="Onboarding Investor Type *"
                    name={`applicationType`}
                    items={Object.keys(ONBOARDING_INVESTOR)
                      .filter((_option) =>
                        id && redirectTo
                          ? ONBOARDING_INVESTOR[_option] !== ONBOARDING_INVESTOR.non_individual
                          : _option
                      )
                      .map((option) => ({
                        label: ONBOARDING_INVESTOR[option],
                        value: option,
                      }))}
                    onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        applicationType: value,
                        onboardingType: 'normal',
                        mobile: '',
                        countryCode: '+91',
                        countryNameAndCode: 'India: +91',
                      });
                    }}
                  />
                </Box>
                <MFCheckbox
                  name="hasPOA"
                  label="Power of Attorney"
                  color="primary"
                  sx={{
                    '.MuiTypography-root': {
                      color: 'primary.main',
                      fontWeight: 500,
                      fontSize: 14,
                      letterSpacing: '0.9625px',
                    },
                  }}
                  disabled={disablePOA}
                />
                {!values.hasPOA && (
                  <>
                    {ONBOARDING_INVESTOR[values.applicationType] !==
                      ONBOARDING_INVESTOR.non_individual && (
                      <MobileNumberField
                        label={`${label} Mobile Number *`}
                        name="mobile"
                        placeholder="Enter Mobile number"
                        onKeyDown={(e) => {
                          preventSpecialCharacters(e);
                        }}
                        countryCodeFieldName={'countryNameAndCode'}
                        countryCodeValue={values.countryNameAndCode}
                        regexForFilterValue={nonDigitRegex}
                      />
                    )}
                    <MFTextField
                      autoComplete="off"
                      // autoFocus
                      label={`${label} ${
                        isNonIndividualApplication(applicationType) ? 'Contact Person' : ''
                      } Email ID *`}
                      name="emailId"
                      placeholder="Enter Contact Person Email ID"
                      required
                    />
                  </>
                )}
                <MFSubmitButton label="Proceed" />
              </Box>
            </>
          );
        }}
      </Formik>
    </>
  );
}
