export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS';

export interface ProfilePayload{
    name: string;
    email: string; 
    contact: number;
    address1: string;
    address2?: string;
    address3?: string;
    pan: string;
    empcode: number;
    [key: string]: string | number | undefined;
} 