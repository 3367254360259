import { VariantType } from 'notistack';

import { AnyAction } from 'redux';
import { SHOW_TOAST } from '../types/auth';

export type ToastType = {
  toaster: number;
  message: string;
  severity: VariantType;
};
const initialState: ToastType = {
  toaster: 0,
  message: '',
  severity: 'success',
};

export default function toastMessages(
  state: ToastType = initialState,
  action: AnyAction
): ToastType {
  switch (action.type) {
    case SHOW_TOAST: {
      const toaster = ++state.toaster;
      return {
        ...state,
        toaster,
        message: action.message,
        severity: action.severity,
      };
    }
    default:
      return state;
  }
}
