import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Grid, Typography, IconButton, Avatar } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import EditIcon from '@mui/icons-material/Edit';
import { CommonLayout } from '../commonComponents';

import { MFSubmitButton, MFTextField } from '../../lib/formik';

import { onUpdateProfileDetails } from '../../redux-store/actions/profile';

import { ProfilePayload } from '../../redux-store/types/profile';
import { ProfileAndManagementInputBox } from '../../Styles/styles';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required Name!'),
  email: Yup.string().required('Required Email!'),
  contact: Yup.number().required('Required Contact Details!'),
  address1: Yup.string().required('Address 1 required!'),
  address2: Yup.string(),
  address3: Yup.string(),
  pan: Yup.string().required('PAN required!'),
  empcode: Yup.number().required('Emp Code required!'),
});
// type ProfileProps = {

// }
interface LabelTypes {
  name: string;
  email: string;
  contact: string;
  address1: string;
  address2: string;
  address3: string;
  pan: string;
  empcode: string;
  [k: string]: string;
}
const labels: LabelTypes = {
  name: 'Name',
  email: 'Email Id',
  contact: 'Contact Details',
  address1: 'Address 1',
  address2: 'Address 2',
  address3: 'Address 3',
  pan: 'PAN',
  empcode: 'EMP Code',
};
function ShowDetails({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}): JSX.Element {
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontSize: 14 }}>{label}</Typography>
      <Typography variant="h6" component="h6" sx={{ fontSize: 16, fontWeight: 600 }}>
        {value}
      </Typography>
    </Box>
  );
}
export default function Profile(): JSX.Element {
  const dispatch = useDispatch();

  const [isEditDisable, setEditDisable] = useState(true);

  const handleEditButton = () => {
    if (isEditDisable === true) {
      setEditDisable(false);
    }
  };
  const initialValues: ProfilePayload = {
    name: 'Himagni Patel',
    email: 'himangi.patel@web.email',
    contact: 988899999,
    address1: '123 hno',
    address2: '2 hno',
    address3: '3 hno',
    pan: '123455666',
    empcode: 12122,
  };
  return (
    <CommonLayout>
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton sx={{ height: 'fit-content', p: 0 }} component={RouterLink} to="/">
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Profile
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlined"
              onClick={handleEditButton}
              sx={{ width: 150, height: 40, fontWeight: 500, color: 'primary.main' }}
              startIcon={
                <Avatar
                  sx={{
                    border: `1px solid blue`,
                    height: 20,
                    width: 20,
                    bgcolor: 'white',
                  }}>
                  <EditIcon sx={{ fontSize: 8, color: 'primary.main' }} />
                </Avatar>
              }>
              Edit
            </Button>
          </Box>
        </Box>
        <Box sx={ProfileAndManagementInputBox}>
          <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validationSchema={Schema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              await dispatch(onUpdateProfileDetails(values));
              setEditDisable(true);
            }}>
            {/* eslint-disable-next-line */}
            {({ isSubmitting }) => (
              <Form>
                <Grid container>
                  {Object.keys(initialValues).map((key: string, index): JSX.Element => {
                    return (
                      <Grid px={3} key={index} item sm={6} xs={12} lg={4}>
                        {isEditDisable === false ? (
                          <MFTextField
                            label={labels[key]}
                            disabled={isEditDisable}
                            name={key}
                            id="standard-basic"
                            placeholder={`Enter ${key}`}
                          />
                        ) : (
                          <ShowDetails label={labels[key]} value={initialValues[key]} />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
                {isEditDisable === false && (
                  <Box
                    sx={{ width: { xs: 300, sm: 350, lg: 320 }, margin: '0 auto', paddingTop: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </>
    </CommonLayout>
  );
}
