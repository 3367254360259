//eslint-disable-next-line
import { FormControl, FormHelperText, InputLabel, TextField, Theme } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { SxProps } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import moment, { Moment } from 'moment-timezone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const defaultTextFieldStyles: SxProps<Theme> = {
  'label + &': {
    mt: 4,
  },
  '& .MuiInputBase-root': {
    border: '1px solid #DDEAF3',
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    backgroundColor: 'common.white',
    border: '1px solid #DDEAF3',
    fontSize: 16,
    '&:hover': {
      borderColor: 'primary.main',
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '.MuiInputBase-input': {
      p: '10px 12px',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
};

export const DatePicker = ({
  label = '',
  placeholder = '',
  name,
  inputLabelStyles,
  textFieldStyles = defaultTextFieldStyles,
  maxDate = new Date(),
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 125)),
  disabled = false,
  onClick,
}: {
  label?: string;
  placeholder: string;
  value?: string | null;
  onClick?: () => void;
  name: string;
  inputLabelStyles?: SxProps<Theme>;
  textFieldStyles?: SxProps<Theme>;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}): JSX.Element => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const onChange = (date: Moment | null) => {
    setFieldValue(name, moment(date));
    onClick && onClick();
  };
  const errorText = meta.error && meta.touched ? meta.error : '';
  const timeZoneFromServer = 'Asia/Kolkata';
  moment.tz.setDefault(timeZoneFromServer);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormControl variant="outlined" fullWidth sx={{ my: { xs: 1, md: 2 } }}>
        <InputLabel shrink htmlFor="date-picker" sx={{ ...inputLabelStyles }}>
          {label}
        </InputLabel>
        <MobileDatePicker
          shouldDisableDate={(date) => {
            if (maxDate && moment(maxDate).isBefore(moment(date))) {
              return true;
            } else if (minDate && moment(minDate).isAfter(moment(date))) {
              return true;
            }
            return false;
          }}
          shouldDisableMonth={(date) => {
            if (
              maxDate &&
              moment(new Date(maxDate).setMonth(new Date(maxDate).getMonth())).isBefore(
                moment(date)
              )
            ) {
              return true;
            } else if (minDate && moment(minDate).isAfter(moment(date))) {
              return true;
            }
            return false;
          }}
          shouldDisableYear={(date) => {
            if (
              maxDate &&
              moment(new Date(maxDate).setFullYear(new Date(maxDate).getFullYear() + 1)).isBefore(
                moment(date)
              )
            ) {
              return true;
            } else if (minDate && moment(minDate).isAfter(moment(date))) {
              return true;
            }
            return false;
          }}
          value={moment(field.value)}
          format="DD/MM/YYYY"
          onChange={onChange}
          disabled={disabled}
          // minDate={moment(minDate)}
          // maxDate={moment(maxDate)}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              id: 'date-picker',
              placeholder: placeholder,
              color: 'secondary',
              sx: {
                ...textFieldStyles,
                '& .Mui-disabled': {
                  cursor: !disabled ? 'text' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              },
            },
            toolbar: {
              hidden: true,
            },
          }}
          // renderInput={(params: any) => (
          //   <TextField
          //     fullWidth
          //     id="date-picker"
          //     placeholder={placeholder}
          //     {...params}
          //     sx={{
          //       ...textFieldStyles,
          //       '& .Mui-disabled': {
          //         cursor: !disabled ? 'text' : 'not-allowed !important',
          //         pointerEvents: 'all',
          //       },
          //     }}
          //   />
          // )}
          closeOnSelect={true}
          //showToolbar={false}
        />
        {errorText && (
          <FormHelperText error sx={{ marginLeft: 'unset' }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};
