import { Box, Typography, Grid, Stack, Switch } from '@mui/material';
import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { getPoaApproverList, updatePOAApprover } from '../../redux-store/actions/userManagement';
import { AddPoaApproverRequestBody, poaApprover } from '../../redux-store/types/api-types';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { styles } from '../../Styles/styles';
import { UserListHeader } from './userBackButton';

export default function PoaApprovers({
  location,
}: {
  location: Location<{ distributorId: number }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { distributorId } = location.state || {};
  const [poaApproverData, setpoaApproverData] = useState<poaApprover[]>([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    poaRowData: AddPoaApproverRequestBody;
  } | null>(null);

  const headers = [
    {
      header: `Name`,
      renderCell: (row: poaApprover) => {
        return (
          <Typography
            variant="h5"
            sx={styles.UsersClickbleColumn}
            component={RouterLink}
            to={{
              pathname: 'poa-approver-details',
              state: { poaApproverId: row.id },
            }}>
            {row.user?.firstName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'Mobile Number',
      valueGetter: (row: poaApprover) => row.user.phone || 'N/A',
    },
    {
      header: 'Email Id',
      valueGetter: (row: poaApprover) => row.user.email || 'N/A',
    },
    {
      header: AccessHeader,
      renderCell: (row: AddPoaApproverRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ poaRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: poaApprover): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{
    //                 pathname: 'edit-poaApprover',
    //                 state: { poaApproverId: row.id },
    //               }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{
    //               pathname: 'edit-poaApprover',
    //               state: { poaApproverId: row.id },
    //             }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (distributorId) {
          const res = (await dispatch(
            getPoaApproverList({ distributorId: distributorId })
          )) as unknown as poaApprover[];
          setpoaApproverData(res);
        } else {
          history.replace('/user-management');
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <UserListHeader
          addUser="Add POA Approvers"
          listName="List of POA Approvers"
          accessForEditAdd={true}
          onClickAdd={() =>
            history.push('add-poaApprover', { distributorId: distributorId.toString() })
          }
        />
        <Grid item xs={12} sm={12} lg={12}>
          {useMemo(
            () => (
              <DataTable
                tableData={poaApproverData}
                tableHeader={headers}
                isLoading={isLoading}
                currentPage={currentPage}
                noOfPages={1}
                paginationAction={handlePageChange}
                renderAdditionalRow={true}
                tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
                tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
              />
            ),
            [headers, isLoading, poaApproverData]
          )}
        </Grid>
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.poaRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                updatePOAApprover(openDialog.poaRowData.id as number, {
                  ...openDialog.poaRowData,
                  isActive: !openDialog?.poaRowData.isActive,
                })
              )) as unknown as poaApprover;
              setpoaApproverData(
                poaApproverData?.map((poaApprover) => {
                  if (Number(poaApprover.id) === Number(updatedResponse.id)) {
                    return {
                      ...poaApprover,
                      ...updatedResponse,
                    };
                  }
                  return poaApprover;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
