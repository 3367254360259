import { createTheme } from '@mui/material';
// import './types';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#2057A6',
    },
    secondary: {
      main: '#131836',
    },
    text: {
      primary: '#293139',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          borderRadius: '5px',
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '18px',
          padding: '10px',
        },
      },
    },
  },
});

export default theme;
