import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { styles } from '../../Styles/styles';
import { POAAuthorizedSignatry } from '../NonIndividualApplicationDetails/AuthorisedSignatories';
import {
  checkIfApplicationIsIndividualPOA,
  checkValidationBasedOnDate,
} from '../../utils/utilityFunctions';
import {
  POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE,
  poaValidationDate,
} from '../../utils/constant';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode}
                />
                <KeyValue title={'Distributor Name'} description={application?.distributor?.name} />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={
                    application?.subDistributor?.user?.email ||
                    application?.distributor?.user?.email
                  }
                />
                <KeyValue title={'AMCs RM Email Id'} description={application?.rm?.user?.email} />
              </Grid>
            </Gridstyles>
          </Grid>
          {application?.poaauthorisedsignatories?.length >= 0 &&
            checkValidationBasedOnDate(application.createdAt || '', poaValidationDate) &&
            checkIfApplicationIsIndividualPOA(application) &&
            POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE && (
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                  Authorised Signatories
                </Typography>
                {application?.poaauthorisedsignatories?.length === 0 ? (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Authorised Signatories
                  </Grid>
                ) : (
                  <POAAuthorizedSignatry />
                )}
              </Grid>
            )}
        </Box>
      </Box>
    </>
  );
}
