import { AnyAction } from 'redux';
import { distributorWise } from '../../components/dashboard/topDistributorsRM';
import { distributorWisecommitmentType } from '../types/api-types';
import { GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS } from '../types/application';
import { LOGOUT_SUCCESS } from '../types/auth';

function topDistributorReducer(
  state = distributorWise,
  action: AnyAction
): Partial<distributorWisecommitmentType> {
  switch (action.type) {
    case GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS:
      return {
        ...action.body,
      };
    case LOGOUT_SUCCESS: {
      return distributorWise;
    }
    default:
      return state;
  }
}

export default topDistributorReducer;
