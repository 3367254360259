import { AnyAction } from 'redux';
import { CLEAR_GLOBAL_ERROR, SHOW_GLOBAL_ERROR } from '../types/auth';

export type NetworkError = {
  error: string;
  errorType?: string;
  title?: string;
};

export type ErrorState = NetworkError | null;

const initialState: ErrorState = null;

export default function errorReducer(state = initialState, action: AnyAction): ErrorState {
  switch (action.type) {
    case SHOW_GLOBAL_ERROR:
      if (state && action) {
        if (state.error && action.message && state.error === action.message) {
          return state;
        } else {
          return { errorType: action.errorType, error: action.message, title: action.title || '' };
        }
      } else {
        return { errorType: action.errorType, error: action.message, title: action.title || '' };
      }
    case CLEAR_GLOBAL_ERROR:
      return null;
  }
  return state;
}
