import { AnyAction } from 'redux';
import { monthWise } from '../../components/dashboard/monthOnMonthAndStatus';
import { monthwiseOnboardingSummaryType } from '../types/api-types';
import { GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS } from '../types/application';
import { LOGOUT_SUCCESS } from '../types/auth';

function monthOnMonthReducer(
  state = monthWise,
  action: AnyAction
): Partial<monthwiseOnboardingSummaryType> {
  switch (action.type) {
    case GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS:
      return {
        ...action.body,
      };
    case LOGOUT_SUCCESS: {
      return monthWise;
    }
    default:
      return state;
  }
}

export default monthOnMonthReducer;
