import { Grid } from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments, updateApplication } from '../../redux-store/actions/application';
import {
  individuals_Poa_nonIndividuals_Documents,
  Document,
  Bank,
  KraDocumentType,
  ApplicationProps,
  Applicant,
} from '../../redux-store/types/api-types';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { RootStateType } from '../../redux-store/reducers';
import { RiskProfileEnabled, SAVE_LATER_VALIDATION_CHECK } from '../../utils/constant';
import { Notes, ProceedSaveLater, SubHeading } from '../investors/components';
import { useHistory } from 'react-router';
import {
  IsDomesticFlow,
  KRAEnabled,
  applicationComparison,
  checkIfApplicationIsNonIndividualPOA,
  isForeignDocumentFlow,
  nonIndividualApplicantDocument,
  getStep,
  saveForLater,
  statusRoleBased,
} from '../../utils/utilityFunctions';
import { nonIndividualDocumentDetailsSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import { KraDocument } from '../investors/kraDocument';
import { DocumentInfoDialog, DocumentwithLables } from '../commonComponents';
import MFCheckbox from '../../lib/formik/Checkbox';
import { fatcaDocumentCheck } from '../investors/Foregin/documentDetails';
import { userDocumentType, document_object } from '../investors/Resident/documentDetails';
import { DocumentDeclaration, IFSCGuidlines } from '../DocumentDeclairations';
import FieldValidationNote from '../investors/FieldValidationNote';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

type documentProps = {
  documents: userDocumentType[];
  kraDocument?: KraDocumentType[];
  wishToUploadFatcaDocument?: boolean | null;
};
export interface Values {
  applicants: documentProps[];
  saveType: string;
  banks: Bank[];
  documentDeclarationCheck?: boolean;
  onboardingFundCategory: string;
  [key: string]: documentProps[] | string | undefined | string[] | Bank[] | boolean;
}

export default function DocumentDetails(): JSX.Element {
  const [documentsData, setDocumentsData] = useState<individuals_Poa_nonIndividuals_Documents[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [documentUpload, setDocumentUpload] = useState(false);
  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const {
    applicants = [],
    banks = [],
    documentDeclarationCheck,
    onboardingFundCategory = '',
  } = application || {};
  useEffect(() => {
    (async function () {
      try {
        const getInvestorType = applicants[0]?.investorType?.toString();
        if (getInvestorType) {
          const response = (await dispatch(
            getDocuments(onboardingFundCategory)
          )) as unknown as Document;
          if (getInvestorType && Object.keys(response).includes(getInvestorType)) {
            if (checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)) {
              setDocumentsData(
                response[`${getInvestorType}_poa`] as individuals_Poa_nonIndividuals_Documents[]
              );
            } else {
              setDocumentsData(
                response[getInvestorType] as individuals_Poa_nonIndividuals_Documents[]
              );
            }
          }
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const initialValues: Values = {
    applicants: nonIndividualApplicantDocument(application, documentsData, role),
    saveType: 'save and proceed',
    banks: banks,
    documentDeclarationCheck,
    onboardingFundCategory,
  };

  const handleSubmit = async (values: Values) => {
    const { applicants, saveType } = values;
    const {
      applicants: exisitingApplicants = [],
      id,
      applicant1ReferenceId = '',
      currentStep,
      applicationNumber,
    } = application || {};
    const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const finalDocs = documents.map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...applicant,
        documents: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, ...rest } = doc;
          return rest;
        }),
        wishToUploadFatcaDocument: applicants[index].wishToUploadFatcaDocument,
      };
    }) as Partial<Applicant>[];
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          ?.map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                ?.map((document: any) => {
                  const {
                    // eslint-disable-next-line
                    documentData,
                    // id,
                    // applicantId,
                    // eslint-disable-next-line
                    filename,
                    // eslint-disable-next-line
                    updatedAt,
                    // eslint-disable-next-line
                    createdAt,
                    // eslint-disable-next-line
                    updatedBy,
                    // eslint-disable-next-line
                    createdBy,
                    ...rest
                  } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        ...application,
        applicants: updatedApplicants
          .map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                ?.map((document: any) => {
                  const {
                    // eslint-disable-next-line
                    documentData,
                    // eslint-disable-next-line
                    options,
                    // eslint-disable-next-line
                    infoText,
                    // eslint-disable-next-line
                    downloadTemplate,
                    // eslint-disable-next-line
                    maxFiles,
                    // eslint-disable-next-line
                    maxFileSize,
                    // eslint-disable-next-line
                    minFileSize,
                    // eslint-disable-next-line
                    uploadFormats,
                    ...rest
                  } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        currentStep:
          !!currentStep && currentStep > (RiskProfileEnabled ? 6 : 5)
            ? currentStep
            : Number(currentStep) + 1,
        documentDeclarationCheck: values?.documentDeclarationCheck,
      }
    );
    try {
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              currentStep: RiskProfileEnabled ? getStep(7, isSaveLater) : getStep(6, isSaveLater),
              status: statusRoleBased(role, application),
              documentDeclarationCheck: values?.documentDeclarationCheck,
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        saveType === 'save and proceed'
          ? history.push('authorised-signatory', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveType !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveType === 'save and proceed'
          ? history.push('authorised-signatory', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values
  ): string => {
    return `${documentName}  ${
      documentType === 'bankAccountProof'
        ? values.banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
            .length !== 0
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  const [open, setOpen] = useState(false);
  const [popUpText, setPopUpText] = useState<{ popUpInfoText: string[] } | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nonIndividualDocumentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, setFieldValue, handleChange }) => (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          display="flex"
          alignItems="center">
          <Grid sx={{ ml: '40px', width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
            />
          </Grid>
          {isForeignDocumentFlow(onboardingFundCategory) && (
            <>
              <IFSCGuidlines />
              <MFCheckbox
                name={'documentDeclarationCheck'}
                label={DocumentDeclaration}
                onChange={handleChange}
              />
            </>
          )}
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            // let num = 0;
            const num = documents.length;
            return (
              <>
                {' '}
                <SubHeading>Document Details</SubHeading>
                {!IsDomesticFlow(application?.flowType || '') && (
                  <Grid item xs={12}>
                    <MFCheckbox
                      name={`applicants.${ind}.wishToUploadFatcaDocument`}
                      label={'Wish to upload FATCA-CRS Declaration'}
                      sx={{ my: 2 }}
                      onChange={({ target: { checked } }) => {
                        const findExistingFatca = documents.find(
                          (fatcaDoc) => fatcaDoc.documentType === 'fatca'
                        );
                        const getFatcaFromMasterJson = documentsData.find(
                          (_fatcaDoc) => _fatcaDoc.documentType === 'fatca'
                        );
                        const updatedDoc = findExistingFatca
                          ? documents
                              .filter((document) => {
                                if (
                                  fatcaDocumentCheck(!checked as boolean, document.documentType)
                                ) {
                                  return;
                                }
                                return document;
                              })
                              .map((_doc) => {
                                if (fatcaDocumentCheck(checked as boolean, _doc.documentType)) {
                                  return { ..._doc, required: 'true' };
                                }
                                return _doc;
                              })
                          : [
                              ...documents,
                              checked && getFatcaFromMasterJson
                                ? {
                                    documentType: getFatcaFromMasterJson.documentType,
                                    documentName: getFatcaFromMasterJson.documentName,
                                    maxFileSize: getFatcaFromMasterJson.maxFileSize,
                                    minFileSize: getFatcaFromMasterJson.minFileSize,
                                    uploadFormats: getFatcaFromMasterJson.uploadFormats,
                                    maxFiles: getFatcaFromMasterJson.maxFiles,
                                    documentsList: [
                                      {
                                        documentType: getFatcaFromMasterJson.documentType,
                                        documentName: getFatcaFromMasterJson.documentName,
                                        required: 'true',
                                        options: getFatcaFromMasterJson.options,
                                        maxFileSize: getFatcaFromMasterJson.maxFileSize,
                                        minFileSize: getFatcaFromMasterJson.minFileSize,
                                        uploadFormats: getFatcaFromMasterJson.uploadFormats,
                                        maxFiles: getFatcaFromMasterJson.maxFiles,
                                        uniqueKey: (ind.toString() +
                                          '-' +
                                          documents.length.toString() +
                                          '-' +
                                          '0') as string,

                                        ...document_object,
                                      },
                                    ],
                                    required: 'true',
                                    multipleFiles: getFatcaFromMasterJson.multipleFiles,
                                    options: getFatcaFromMasterJson.options,
                                  }
                                : {},
                            ];
                        setFieldValue(`applicants.${ind}`, {
                          ...applicants[ind],
                          documents: updatedDoc,
                          wishToUploadFatcaDocument: checked,
                        });
                      }}
                    />
                  </Grid>
                )}
                <DocumentwithLables
                  disabled={false}
                  documentFrom={`applicants.${ind}.documents`}
                  documents={documents}
                  // eslint-disable-next-line
                  getLabel={(documentName, documentType, required, values, ind) =>
                    getLabel(documentName, documentType, required, values as Values)
                  }
                  handleOpen={handleOpen}
                  onFileUpload={handleUpload}
                  onFileUploaded={handleUploaded}
                  setPopUpText={(infoText) => setPopUpText({ popUpInfoText: infoText })}
                  userIndex={ind}
                  values={values}
                  key={ind}
                />
                {KRAEnabled(application) && (
                  <KraDocument KraDocument={applicant.kraDocument} num={num + 1} />
                )}
              </>
            );
          })}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
            disabled={documentUpload}
          />
          <DocumentInfoDialog handleClose={handleClose} open={open} popUpText={popUpText} />
        </Grid>
      )}
    </Formik>
  );
}
