const replaceUndefined = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === 'undefined') {
      obj[key] = undefined;
    } else if (typeof obj[key] === 'object') {
      replaceUndefined(obj[key]); //works for object,nested objects
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => {
        if (typeof item === 'object') {
          replaceUndefined(item); //works for array, nested Arrays
        }
      });
    }
  }
};

//eslint-disable-next-line
export default function trimOnPayload(payload: any): any {
  const stringifyObj = JSON.stringify(payload, (key, value) => {
    if (typeof value === 'string') {
      return value.trim();
    }
    //JSON.stringify removing undefined property, for keeping that we need to convert undefined value to string
    if (typeof value === 'undefined') {
      return 'undefined';
    }
    return value;
  });
  const parsedObj = JSON.parse(stringifyObj);
  replaceUndefined(parsedObj); //reverting string form of undefined to actual undefined value
  return parsedObj;
}
