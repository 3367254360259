import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { FundManager } from '../../redux-store/types/api-types';
import {
  getFundManagersById,
  getIMAccess,
  IMAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { AddressFieldReadonly } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';

export default function IMDetails({
  location,
}: {
  location: Location<{ fundManagerId: number }>;
}): JSX.Element {
  const { fundManagerId: id } = location.state || { fundManagerId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [fundManagerDetails, setFundManagerDetails] = useState<FundManager>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        if (id) {
          const getFundManagerDetails = (await dispatch(
            getFundManagersById(id)
          )) as unknown as FundManager;
          setFundManagerDetails(getFundManagerDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(fundManagerAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="Investment Manager"
          accessForEditAdd={accessForEditAdd}
          onClickBack={() => history.push('invest-managers')}
          onClickEdit={() =>
            history.push('edit-Invest-manager', { fundManagerId: fundManagerDetails?.id })
          }
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          fundManagerDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Name (Individual)'}
                      description={fundManagerDetails.user.firstName}
                    />
                    <KeyValue
                      title={'Leegality Name'}
                      description={fundManagerDetails.leegalityName}
                    />
                    <KeyValue title={'Email Id'} description={fundManagerDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={fundManagerDetails.user.phone} />
                    <AddressFieldReadonly AddressField={fundManagerDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
