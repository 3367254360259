import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux-store/actions/auth';
import { useHistory } from 'react-router';
import { LoadingButton } from '@mui/lab';

export const NotAuthorized = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    try {
      setLoading(true);
      await dispatch(logout());
      history.replace('/login');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& .MuiButton-root': {
            width: '160px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: '18px',
            py: 1,
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      <DialogContent>
        <DialogContentText>You are not authorized to view this page</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loadingPosition="start"
          type="submit"
          loading={loading}
          variant="contained"
          fullWidth={true}
          onClick={onClick}>
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
