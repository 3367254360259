import { Box } from '@mui/system';
import { CommonLayout } from '../commonComponents';

export const RiskProfile = (): JSX.Element => {
  return (
    <CommonLayout>
      <Box>Risk Profile Content</Box>
    </CommonLayout>
  );
};
