import { Box, Typography, Switch, Stack } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AmcApproverAccess,
  getAMCApproverList,
  getAmcApproverAccess,
  UpdateAmcApprover,
} from '../../redux-store/actions/userManagement';
import { AddAMCApproverRequestBody, AmcApprover } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { UserListHeader } from './userBackButton';
import { styles } from '../../Styles/styles';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: AmcApprover) => {
      return (
        <Typography
          variant="h5"
          sx={styles.UsersClickbleColumn}
          component={RouterLink}
          to={{ pathname: 'amc-approver-details', state: { amcApproverId: row.id } }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: AmcApprover) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: AmcApprover) => row.user.email || 'N/A',
  },
];

function amcApprover(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [amcApproverList, setAmcApproverList] = useState<AmcApprover[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    amcApproverRowData: AddAMCApproverRequestBody;
  } | null>(null);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getAMCApproverList())) as unknown as AmcApprover[];
        const { amcApproverAccess } = (await dispatch(
          getAmcApproverAccess()
        )) as unknown as AmcApproverAccess;
        if (!isComponentAlive) return;
        setAmcApproverList(res);
        setAccessForEditAdd(amcApproverAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddAMCApproverRequestBody): JSX.Element => {
        return (
          <>
            <Stack sx={styles.UsersAccessViewOrEditCellStack}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ amcApproverRowData: row })}
                disabled={!accessForEditAdd}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <UserListHeader
          addUser=" Add AMC Approver"
          listName="List of AMC Approvers"
          accessForEditAdd={accessForEditAdd}
          onClickAdd={() => history.push('add-amc-approver')}
        />
        {useMemo(
          () => (
            <DataTable
              tableData={amcApproverList}
              tableHeader={[...columns, ...AccessViewOrEditCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
              tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
            />
          ),
          [amcApproverList, currentPage, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.amcApproverRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                UpdateAmcApprover(openDialog.amcApproverRowData.id as number, {
                  ...openDialog.amcApproverRowData,
                  isActive: !openDialog?.amcApproverRowData.isActive,
                })
              )) as unknown as AmcApprover;
              setAmcApproverList(
                amcApproverList?.map((amcApprover) => {
                  if (Number(amcApprover.id) === Number(updatedResponse.id)) {
                    return {
                      ...amcApprover,
                      ...updatedResponse,
                    };
                  }
                  return amcApprover;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default amcApprover;
