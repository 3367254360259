export const GET_DISTRIBUTOR_CODES = 'GET_DISTRIBUTOR_CODES';
export const GET_DISTRIBUTOR_CODES_SUCCESS = 'GET_DISTRIBUTOR_CODES_SUCCESS';

export const GET_IFSC_DETAILS_SUCCESS = 'GET_IFSC_DETAILS_SUCCESS';
export const GET_IFSC_DETAILS = 'GET_IFSC_DETAILS';

export const GET_DISTRIBUTORS_SUCCESS = 'GET_DISTRIBUTORS_SUCCESS';
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';

export const GET_RMS = 'GET_RMS';
export const GET_RMS_SUCCESS = 'GET_RMS_SUCCESS';

export const GET_Countries_SUCCESS = 'GET_Countries_SUCCESS';
export const GET_Countries = 'GET_Countries';

export const GET_RISKPROFILEMDMS = 'GET_RISKPROFILEMDMS';
export const GET_RISKPROFILEMDMS_SUCCESS = 'GET_RISKPROFILEMDMS_SUCCESS';

export type IfscType = {
  BankAdd1: string;
  BankAdd2: string;
  BankAdd3: string;
  BankCity: string;
  BankName: string;
  BankPin: number;
  IFSCCode: string;
  IFSCOwnedBank: string;
  MicrNo: string;
};
export type mdmsCountriesList = {
  name: string;
  dial_code: string;
  code: string;
  nationality: string;
};
export type nationaliyType = {
  countries: mdmsCountriesList[];
};

export const GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS = 'GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS';
export const GET_DISTRIBUTORS_FOR_FILTERS = 'GET_DISTRIBUTORS_FOR_FILTERS';

export const GET_JOINTHOLDERLIST_SUCCESS = 'GET_JOINTHOLDERLIST_SUCCESS';
export const GET_JOINTHOLDERLIST = 'GET_JOINTHOLDERLIST';

export const GET_RMTEMPLATE_SUCCESS = 'GET_RMTEMPLATE_SUCCESS';
export const GET_RMTEMPLATE = 'GET_RMTEMPLATE';

export const GET_FUNDTEMPLATE_SUCCESS = 'GET_FUNDTEMPLATE_SUCCESS';
export const GET_FUNDTEMPLATE = 'GET_FUNDTEMPLATE';

export const GET_PLANTEMPLATE_SUCCESS = 'GET_PLANTEMPLATE_SUCCESS';
export const GET_PLANTEMPLATE = 'GET_PLANTEMPLATE';

export const GET_INDIVIDUALDISTRIBUTORTEMPLATE_SUCCESS =
  'GET_INDIVIDUALDISTRIBUTORTEMPLATE_SUCCESS';
export const GET_INDIVIDUALDISTRIBUTORTEMPLATE = 'GET_INDIVIDUALDISTRIBUTORTEMPLATE';

export const GET_COMPANYDISTRIBUTORTEMPLATE_SUCCESS = 'GET_COMPANYDISTRIBUTORTEMPLATE_SUCCESS';
export const GET_COMPANYDISTRIBUTORTEMPLATE = 'GET_COMPANYDISTRIBUTORTEMPLATE';

export const GET_DISTRIBUTORRMTEMPLATE_SUCCESS = 'GET_DISTRIBUTORRMTEMPLATE_SUCCESS';
export const GET_DISTRIBUTORRMTEMPLATE = 'GET_DISTRIBUTORRMTEMPLATE';

export const GET_AUTHORISEDSIGNATORYTEMPLATE_SUCCESS = 'GET_AUTHORISEDSIGNATORYTEMPLATE_SUCCESS';
export const GET_AUTHORISEDSIGNATORYTEMPLATE = 'GET_AUTHORISEDSIGNATORYTEMPLATE';

export const GET_POAAPPROVERTEMPLATE_SUCCESS = 'GET_POAAPPROVERTEMPLATE_SUCCESS';
export const GET_POAAPPROVERTEMPLATE = 'GET_POAAPPROVERTEMPLATE';

export const GET_TRUSTEETEMPLATE_SUCCESS = 'GET_TRUSTEETEMPLATE_SUCCESS';
export const GET_TRUSTEETEMPLATE = 'GET_TRUSTEETEMPLATE';

export const GET_IMTEMPLATE_SUCCESS = 'GET_IMTEMPLATE_SUCCESS';
export const GET_IMTEMPLATE = 'GET_IMTEMPLATE';

export const GET_AIFAPPROVERTEMPLATE_SUCCESS = 'GET_AIFAPPROVERTEMPLATE_SUCCESS';
export const GET_AIFAPPROVERTEMPLATE = 'GET_AIFAPPROVERTEMPLATE';

export const GET_AMCAPPROVERTEMPLATE_SUCCESS = 'GET_AMCAPPROVERTEMPLATE_SUCCESS';
export const GET_AMCAPPROVERTEMPLATE = 'GET_AMCAPPROVERTEMPLATE';

export const GET_AMCADMINTEMPLATE_SUCCESS = 'GET_AMCADMINTEMPLATE_SUCCESS';
export const GET_AMCADMINTEMPLATE = 'GET_AMCADMINTEMPLATE';

export const GET_FUND_INVESTOR_CATEGORY_SUCCESS = 'GET_FUND_INVESTOR_CATEGORY_SUCCESS';
export const GET_FUND_INVESTOR_CATEGORY = 'GET_FUND_INVESTOR_CATEGORY';

export type keyLabelPair = { key: string; label: string; disabled: boolean };
export type mdmsFundInvestorCategoryType = {
  [key: string]: { displayText: string; onboardingInvestorType: keyLabelPair[] };
};
