/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LayoutItem, layouts } from 'chart.js';
import CenterTitle from './center-title-ts';

const centerDoughnutPlugin = {
  id: 'centerTitle',
  _element: CenterTitle,
  start: function (chart: any, _args: any, options: any) {
    const title = new CenterTitle({
      ctx: chart.ctx,
      options: { ...this.defaults, ...options },
      chart,
    }) as unknown as LayoutItem;
    layouts.configure(chart, title, options);
    layouts.addBox(chart, title);
    chart.chartMidTitleBlock = title;
  },
  stop(chart: any) {
    const titleBlock = chart.chartMidTitleBlock;
    layouts.removeBox(chart, titleBlock);
    delete chart.chartMidTitleBlock;
  },
  beforeUpdate(chart: any, _args: any, options: any) {
    options = { ...this.defaults, ...options };
    const title = chart.chartMidTitleBlock;
    layouts.configure(chart, title, options);
    title.options = options;
  },
  defaults: {
    align: 'center',
    display: true,
    font: {
      weight: 'bold',
    },
    fullSize: true,
    padding: 10,
    position: 'chartArea',
    text: '',
    weight: 2000,
  },
  defaultRoutes: {
    color: 'color',
  },
  descriptors: {
    _scriptable: true,
    _indexable: false,
  },
};

export default centerDoughnutPlugin;
