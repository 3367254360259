import { SAVE_LATER_VALIDATION_CHECK } from '../../utils/constant';
import { Grid } from '@mui/material';
import { Notes } from './components';

const FieldValidationNote = ({ finalPage }: { finalPage?: boolean }): JSX.Element => {
  return !SAVE_LATER_VALIDATION_CHECK ? (
    <Grid sx={{ width: '100%', mt: 4 }}>
      <Notes
        displayContent={
          finalPage
            ? 'Mandatory field will be verified on clicking Preview'
            : 'Mandatory field will be verified on clicking Save and Proceed'
        }
      />
    </Grid>
  ) : (
    <></>
  );
};

export default FieldValidationNote;
