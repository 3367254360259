import { Box, Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MFTextField } from '../../../lib/formik';
import UseRadioGroup from '../../../lib/formik/Radio';
import { getUboTypes, updateApplication } from '../../../redux-store/actions/application';
import { RootStateType } from '../../../redux-store/reducers';
import { AmlSystemMatch, Applicant, ubo, uboTypes } from '../../../redux-store/types/api-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FormControl } from '@mui/material';
import { useField } from 'formik';
import { ProceedSaveLater, SubHeading } from '../../investors/components';
import {
  allowOnlyNumbers,
  applicationComparison,
  getStep,
  removeSingleQuote,
  saveForLater,
  shouldValidateUponSaveLater,
  statusRoleBased,
} from '../../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { showError } from '../../../redux-store/actions/auth';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '../../../lib/formik/DatePicker';
import MFSelectField from '../../../lib/formik/SelectField';
import {
  CpUboCodesMaster,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  genderMasters,
  occupationDetailsMasters,
} from '../../../utils/constant';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataTable } from '../../DataTable';
import { AmlPopup, AmlPopupValues, ConfirmationDialog } from '../../commonComponents';
import { nonIndividualUboForegin } from '../../../utils/schema';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../../redux-store/types/mdms';
import { getNationalityList } from '../../../redux-store/actions';
import { SearchableSelect } from '../../../lib/formik/searchSelectField';
import { percentageError } from '../Resident/ubo';
import FieldValidationNote from '../../investors/FieldValidationNote';

export const UboListingObject: ubo = {
  dob: '',
  taxIdNumber: '',
  name: '',
  identificationType: '',
  percentageOfBeneficialInterest: 0,
  countryOfTaxResidency: '',
  cpUboCode: '',
  placeAndCountryOfBirth: '',
  occupation: '',
  gender: '',
  nationality: '',
  fatherName: '',
  ckycNumber: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  isActive: true,
  isFetchData: false,
  amlCheck: true,
  amlComments: '',
  amlAcknowledgement: false,
};

export type Values = {
  ubo_declaration_type: string;
  ubo_declaration_value: string;
  saveType: string;
  applicants: Partial<Applicant>[];
  ubo_declaration_others_value: string;
  ubos: ubo[];
};

const initialValues: Values = {
  ubo_declaration_type: '',
  ubo_declaration_value: '',
  applicants: [],
  saveType: 'save and proceed',
  ubo_declaration_others_value: '',
  ubos: [],
};

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: ubo) => row.name || 'N/A',
  },
  {
    header: 'Tax ID',
    valueGetter: (row: ubo) => row.taxIdNumber || 'N/A',
  },
  {
    header: 'Percentage of beneficial interest',
    valueGetter: (row: ubo) =>
      row.percentageOfBeneficialInterest === 0 ? 0 : row.percentageOfBeneficialInterest || 0,
  },
  {
    header: 'UBO Code',
    valueGetter: (row: ubo) => row.cpUboCode || 'N/A',
  },
];

function Declaration({ data, values }: { data: uboTypes; values: Values }): JSX.Element {
  const [field] = useField('ubo_declaration_type');
  const { setFieldValue } = useFormikContext();
  const fieldValue = field.value;
  return (
    <FormControl component="fieldset" sx={{ width: '100%', marginLeft: { sm: 4 } }}>
      {data.uboType.map((dataValues, ind) => {
        const declarationValues = dataValues.displayOthers
          ? dataValues.key === 'none' && values.ubo_declaration_type !== 'none'
            ? []
            : [...dataValues.values, { key: `${dataValues.key}_others`, label: 'Others' }]
          : dataValues.values;
        return (
          <RadioGroup
            row
            {...field}
            value={fieldValue}
            onChange={({ target: { value } }) => {
              setFieldValue(`ubo_declaration_value`, '');
              setFieldValue(`ubo_declaration${ind}`, '');
              setFieldValue(`ubo_declaration_others${ind}`, '');
              setFieldValue(`ubo_declaration_others_value`, '');
              setTimeout(() => {
                setFieldValue(`ubo_declaration_type`, value);
              }, 0);
            }}
            key={ind}
            sx={{
              '& .Mui-disabled': {
                cursor: 'pointer',
                pointerEvents: 'all',
              },
              display: 'flex',
              flexDirection: 'column',
            }}>
            <FormControlLabel
              value={dataValues.key}
              control={
                <Radio disableRipple size="small" sx={{ ':hover': { background: 'none' } }} />
              }
              label={dataValues.displayText}
            />

            {dataValues.openEntryField ? (
              <Grid xs={12} sm={6} sx={{ pb: { xs: 1 }, px: '28px' }}>
                <MFTextField
                  name={`${
                    values.ubo_declaration_type === dataValues.key
                      ? 'ubo_declaration_value'
                      : `ubo_declaration${ind}`
                  }`}
                  placeholder={
                    dataValues.key === 'listedCompany'
                      ? `Please specify name of stock exchange`
                      : `Please specify the listed company`
                  }
                  disabled={dataValues.key !== values.ubo_declaration_type}
                  sx={{
                    '&.MuiInputBase-root': {
                      position: 'relative',
                      border: 0,
                      borderBottom: '1px solid #DDEAF3',
                      fontSize: 16,
                    },
                  }}
                />
              </Grid>
            ) : (
              dataValues.values && (
                <Grid
                  xs={12}
                  sm={12}
                  sx={{
                    mt: dataValues.key === 'none' && values.ubo_declaration_type === 'none' ? 2 : 0,
                    pt: '25px !important',
                    border:
                      dataValues.key === 'none' && values.ubo_declaration_type === 'none'
                        ? '2px dashed #ccc'
                        : 'none',
                    borderRadius:
                      dataValues.key === 'none' && values.ubo_declaration_type === 'none'
                        ? '5px'
                        : 0,
                    px: 4,
                    pb: { xs: 1 },
                  }}>
                  {dataValues.key === 'none' && values.ubo_declaration_type === 'none' && (
                    <Typography sx={{ pb: 3, color: 'primary.main', fontWeight: 500 }}>
                      Non Individual Other than Listed Company/Subsidiary Company
                    </Typography>
                  )}
                  <UseRadioGroup
                    name={'ubo_declaration_value'}
                    items={declarationValues.map((typeValue) => ({
                      label: typeValue.label,
                      value: typeValue.key,
                    }))}
                    disabled={dataValues.key !== values.ubo_declaration_type}
                  />

                  {values.ubo_declaration_value === `${dataValues.key}_others` && (
                    <Grid
                      sx={{
                        display: 'inline-block',
                      }}>
                      <MFTextField
                        name={`${
                          values.ubo_declaration_type === dataValues.key
                            ? 'ubo_declaration_others_value'
                            : `ubo_declaration_others${ind}`
                        }`}
                        placeholder={`Please specify Others`}
                        sx={{
                          '&.MuiInputBase-root': {
                            position: 'relative',
                            border: 0,
                            borderBottom: '1px solid #DDEAF3',
                            fontSize: 16,
                          },
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )
            )}
          </RadioGroup>
        );
      })}
    </FormControl>
  );
}

export default function UboForeign(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [ubo, setUbo] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [uboTypesMdms, setUboTypesMdms] = useState<uboTypes>();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const history = useHistory();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const {
          applicants = [],
          ubo_declaration_type = '',
          ubo_declaration_value = '',
          ubos = [],
          flowType = '',
        } = application || {};
        const response = (await dispatch(getUboTypes(flowType))) as unknown as uboTypes;
        setUboTypesMdms(response);
        setUbo({
          ...ubo,
          applicants: applicants,
          ubo_declaration_type,
          ubo_declaration_others_value: ubo_declaration_value
            ? response.uboType
                .map((type) => {
                  if (type.key === ubo_declaration_type && type.displayOthers) {
                    const filteredArr = type.values.filter(
                      (value) => value.key === ubo_declaration_value
                    ).length
                      ? ''
                      : ubo_declaration_value;
                    return filteredArr;
                  }
                  return;
                })
                .filter((ele) => ele)
                .toString()
            : '',
          ubo_declaration_value: ubo_declaration_value
            ? response.uboType
                .map((type) => {
                  if (type.key === ubo_declaration_type) {
                    if (type.displayOthers) {
                      const filteredArr = type.values.filter(
                        (value) => value.key === ubo_declaration_value
                      ).length
                        ? ubo_declaration_value
                        : `${ubo_declaration_type}_others`;
                      return filteredArr;
                    }
                    return ubo_declaration_value;
                  }
                  return;
                })
                .filter((ele) => ele)
                .toString()
            : '',
          ubos: ubos.length ? ubos : [],
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const handleSubmit = async (values: Values) => {
    const {
      saveType,
      ubo_declaration_value,
      ubo_declaration_type,
      ubo_declaration_others_value,
      ubos,
    } = values;
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicants = [],
        applicationNumber,
      } = application || {};
      const applicantsCkycNumbers = applicants?.map((applicant) => applicant.ckycNo);
      const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
      const countryDropdown = mdmsCountriesList.map((list) => list.name);
      if (shouldValidateUponSaveLater(saveType)) {
        if (!ubo_declaration_type) {
          throw 'Please Select Declaration Type';
        }
        if (!ubo_declaration_value) {
          throw 'Please Select Declaration value';
        }
        if (
          ubo_declaration_value === `${ubo_declaration_type}_others` &&
          !ubo_declaration_others_value
        ) {
          throw 'Please Specify Others';
        }
        if (ubo_declaration_type === 'none' && !ubos.filter((_item) => _item.isActive).length) {
          throw 'Please Add Ultimate Beneficiary Owners(UBO)';
        }
        if (ubos.filter((_item) => _item.isActive).length) {
          const activeUbos = ubos.filter((_item) => _item.isActive);
          activeUbos.map((ubo) => {
            if (ubo.ckycNumber && applicantsCkycNumbers.includes(ubo.ckycNumber)) {
              throw `CKYC Number of ${ubo.name} should not be same as CKYC Number exist in Contributor Details`;
            }
            if (!nationalityDropdown.includes(ubo.nationality || '')) {
              throw `Invalid Nationality for ${ubo.name}`;
            }
            if (!countryDropdown.includes(ubo.country || '')) {
              throw `Invalid Country for ${ubo.name}`;
            }
            if (!countryDropdown.includes(ubo.countryOfTaxResidency || '')) {
              throw `Invalid Country of Tax Residency for ${ubo.name}`;
            }
          });
          percentageError(ubos);
        }
      }
      const checkApplication = applicationComparison(
        {
          ...application,
        },
        {
          ...application,
          applicants: applicants,
          ubo_declaration_type: ubo_declaration_type,
          ubo_declaration_value:
            ubo_declaration_value === `${ubo_declaration_type}_others`
              ? removeSingleQuote(ubo_declaration_others_value)
              : removeSingleQuote(ubo_declaration_value),
          ubos: ubos.map((ubo) => {
            // eslint-disable-next-line
            const { isFetchData, percentageOfBeneficialInterest, ...rest } = ubo;
            return {
              ...rest,
              percentageOfBeneficialInterest: percentageOfBeneficialInterest
                ? percentageOfBeneficialInterest
                : 0,
              name: removeSingleQuote(ubo.name),
              address2: removeSingleQuote(ubo.address2),
              address3: removeSingleQuote(ubo.address3),
              city: removeSingleQuote(ubo.city),
              state: removeSingleQuote(ubo.state),
              identificationType: removeSingleQuote(ubo.identificationType),
              placeAndCountryOfBirth: removeSingleQuote(ubo.placeAndCountryOfBirth),
              fatherName: removeSingleQuote(ubo.fatherName),
            };
          }),
          currentStep:
            !!currentStep && currentStep > (RiskProfileEnabled ? 8 : 7)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: applicants,
              ubo_declaration_type: ubo_declaration_type,
              ubo_declaration_value:
                ubo_declaration_value === `${ubo_declaration_type}_others`
                  ? removeSingleQuote(ubo_declaration_others_value)
                  : removeSingleQuote(ubo_declaration_value),
              ubos: ubos.map((ubo) => ({
                ...ubo,
                percentageOfBeneficialInterest: ubo.percentageOfBeneficialInterest
                  ? ubo.percentageOfBeneficialInterest
                  : 0,
                name: removeSingleQuote(ubo.name),
                address2: removeSingleQuote(ubo.address2),
                address3: removeSingleQuote(ubo.address3),
                city: removeSingleQuote(ubo.city),
                state: removeSingleQuote(ubo.state),
                identificationType: removeSingleQuote(ubo.identificationType),
                placeAndCountryOfBirth: removeSingleQuote(ubo.placeAndCountryOfBirth),
                fatherName: removeSingleQuote(ubo.fatherName),
              })),
              status: statusRoleBased(role, application),
              currentStep: RiskProfileEnabled ? getStep(9, isSaveLater) : getStep(8, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(saveType !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );

        saveType === 'save and proceed'
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveType !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveType === 'save and proceed'
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  function UboListing({ Ubovalues }: { Ubovalues: Values }): JSX.Element {
    const [open, setOpen] = useState(false);
    const [uboListing, setUboListing] = useState(UboListingObject);
    const [rowIndex, setRowIndex] = useState(Number);
    const [deletePopup, setDeletePopup] = useState(false);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [fetchDataDisabled, setFetchDataDisabled] = useState(false);
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();
    // eslint-disable-next-line
    const [amlCheck, setAmlCheck] = useState(false);
    const [amlPopup, setAmlPopup] = useState<{
      panNumber: string;
      dob: string;
      amlCheck: boolean;
    } | null>(null);
    // eslint-disable-next-line
    const [amlFailedresponse, setAmlFailedresponse] = useState<AmlSystemMatch>();

    const handleClose = () => {
      setUboListing(UboListingObject);
      setRowIndex(Number);
      setFetchDataDisabled(false);
      setAmlCheck(false);
      setOpen(false);
    };
    const handleAmlPopupClose = () => {
      setAmlPopup(null);
    };
    const onSubmit = (values: ubo) => {
      const editUbo = Ubovalues.ubos
        .filter((_item) => _item.isActive)
        .map((_ubo, ind) => {
          if (ind === rowIndex) {
            return { ...values, isFetchData: false };
          }
          return { ..._ubo, isFetchData: false };
        });
      const deletedUbos = Ubovalues.ubos.filter((_item) => !_item.isActive && _item.id);
      setUbo({
        ...Ubovalues,
        ubos:
          uboListing.taxIdNumber === ''
            ? [...Ubovalues.ubos, { ...values, isFetchData: false }]
            : [...editUbo, ...deletedUbos],
      });
      handleClose();
    };

    const editCell = {
      header: '',
      renderEditDeleteCell: (row: ubo, index: number): JSX.Element => {
        return (
          <Box display="flex">
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setUboListing({ ...row, isFetchData: false }),
                    setRowIndex(index),
                    setIsLoading(false),
                    setFetchDataDisabled(true),
                    setAmlCheck(true),
                    setOpen(true);
                }}
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}>
                <EditIcon sx={{ color: 'primary.main' }} fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{
                  ':hover': {
                    bgcolor: '#F4FCFC',
                  },
                }}
                onClick={() => {
                  setUboListing(row), setRowIndex(index), setDeletePopup(true);
                }}>
                <DeleteOutlineIcon color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    };

    return (
      <>
        {Ubovalues.ubos.filter((_item) => _item.isActive).length !== 0 && (
          <Grid xs={12} sm={12} lg={12} mt={3}>
            <DataTable
              tableData={Ubovalues.ubos.filter((_item) => _item.isActive)}
              tableHeader={[...headers, editCell]}
              renderAdditionalRow={false}
              tableHeaderCustomStyles={{
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
              rowCustomStyles={{
                '.MuiTableCell-root': {
                  py: '8px',
                  overflowWrap: 'anywhere',
                },
              }}
              boxStyles={{ minHeight: '0px', minWidth: 450 }}
            />
          </Grid>
        )}
        <Grid
          xs={12}
          sm={12}
          lg={12}
          display="flex"
          sx={{
            justifyContent: {
              xs:
                Ubovalues.ubos.filter((_item) => _item.isActive).length === 0 ? 'start' : 'center',
              sm: Ubovalues.ubos.filter((_item) => _item.isActive).length === 0 ? 'start' : 'end',
            },
          }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: 13,
              mr: 2.5,
              whiteSpace: 'nowrap',
              mt: '20px',
            }}
            onClick={() => {
              setUboListing(UboListingObject), setIsLoading(false), setOpen(true);
            }}>
            Add Ultimate Beneficiary Owners
          </Button>

          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                px: 1,
                maxWidth: 900,
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                boxShadow: '0 2px #ccc 0.6',
                zIndex: 10,
                bgcolor: 'white',
                py: 3,
              }}>
              <Typography
                sx={{
                  color: '#0D2762',
                  fontSize: '20px',
                  fontWeight: 500,
                  ml: { xs: 2, sm: 5 },
                }}>
                {uboListing.taxIdNumber
                  ? 'Edit Ultimate Beneficiary Ownership (UBO)'
                  : 'Add Ultimate Beneficiary Ownership (UBO)'}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '90%' },
                mx: 'auto',
                px: { xs: 3, sm: 0 },
                pb: 4,
              }}>
              <Formik
                initialValues={uboListing}
                onSubmit={onSubmit}
                validate={(values: ubo) => {
                  const setValuesForEdit = ubo.ubos
                    .filter((_item) => _item.isActive)
                    .map((_ubo, index) => {
                      if (index === rowIndex) {
                        if (values.taxIdNumber !== _ubo.taxIdNumber) {
                          return _ubo;
                        }
                        if (values.taxIdNumber === _ubo.taxIdNumber) {
                          // eslint-disable-next-line
                          const { taxIdNumber, ...rest } = _ubo;
                          return { ...rest };
                        }
                        return;
                      }
                      return _ubo;
                    });
                  try {
                    validateYupSchema(
                      values,
                      nonIndividualUboForegin,
                      true,
                      uboListing.taxIdNumber
                        ? setValuesForEdit
                        : ubo.ubos.filter((_item) => _item.isActive)
                    );
                  } catch (e) {
                    return yupToFormErrors(e);
                  }
                }}
                enableReinitialize={true}>
                {({ handleSubmit, setFieldValue, setValues }) => (
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{
                      width: '100%',
                      ml: 0,
                      '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                      mt: 1,
                    }}
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`taxIdNumber`}
                        label="Taxpayer ID Number/Equivalent ID Number *"
                        placeholder="Enter ID Number"
                        onChange={(e) => {
                          setFieldValue('taxIdNumber', removeSingleQuote(e.target.value));
                          setFetchDataDisabled(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`identificationType`}
                        label="Identification Type *"
                        placeholder="Enter Identification Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`name`}
                        label="Name of UBO *"
                        placeholder="Enter Name of UBO"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label={'Date of Birth/Incorporation *'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'rgba(0,0,0,0.7)',
                        }}
                        placeholder={'DD/MM/YYYY'}
                        name={`dob`}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        onClick={() => {
                          setFetchDataDisabled(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`percentageOfBeneficialInterest`}
                        label="Percentage of beneficial interest"
                        placeholder="Enter Percentage of beneficial interest"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SearchableSelect
                        name={`countryOfTaxResidency`}
                        label="Country of Tax Residency *"
                        items={mdmsCountriesList.map((nationality) => ({
                          key: nationality.name,
                          value: nationality.name,
                        }))}
                        searchFieldPlaceholder={'Search Country'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MFSelectField
                        name={`cpUboCode`}
                        label="CP/UBO Code *"
                        items={Object.keys(CpUboCodesMaster).map((CpUboCode) => ({
                          key: CpUboCodesMaster[CpUboCode],
                          value: CpUboCode,
                        }))}
                        indexing={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`placeAndCountryOfBirth`}
                        label="Place &#38; Country of Birth *"
                        placeholder="Enter Place &#38; Country of Birth"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFSelectField
                        name={`occupation`}
                        label="Occupation"
                        items={occupationDetailsMasters.map((occupation) => ({
                          key: occupation,
                          value: occupation,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <UseRadioGroup
                        name={'gender'}
                        formLabel="Gender *"
                        items={genderMasters.map((gender) => ({
                          label: gender,
                          value: gender,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SearchableSelect
                        name={`nationality`}
                        label="Nationality *"
                        items={mdmsCountriesList.map((nationality) => ({
                          key: nationality.nationality,
                          value: nationality.nationality,
                        }))}
                        searchFieldPlaceholder={'Search Nationality'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`fatherName`}
                        label="Father&#39;s Name"
                        placeholder="Enter Father&#39;s Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        sx={{
                          color: '#0D2762',
                          fontSize: '18px',
                          fontWeight: 500,
                          py: 2,
                        }}>
                        Address Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`address2`}
                        label={`Address Line 1 *`}
                        placeholder="Enter Address Line 1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`address3`}
                        label={`Address Line 2 *`}
                        placeholder="Enter Address Line 2"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`pincode`}
                        label={`Pincode *`}
                        placeholder="Enter Pincode"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField name={`city`} label={`City *`} placeholder="Enter City" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MFTextField name={`state`} label={`State *`} placeholder="Enter State" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SearchableSelect
                        name={`country`}
                        label="Country *"
                        items={mdmsCountriesList.map((nationality) => ({
                          key: nationality.name,
                          value: nationality.name,
                        }))}
                        searchFieldPlaceholder={'Search Country'}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} lg={12} display="flex" sx={{ justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: 15,
                          minWidth: 180,
                          letterSpacing: 2,
                          fontWeight: 500,
                          alignSelf: 'center',
                          mt: 2,
                        }}
                        type="submit">
                        {uboListing.taxIdNumber ? 'Save' : 'Add'}
                      </Button>
                    </Grid>

                    <AmlPopup
                      handleClose={handleAmlPopupClose}
                      amlPopupOpen={amlPopup !== null}
                      onSubmit={(values: AmlPopupValues) => {
                        setValues({
                          ...uboListing,
                          ...amlPopup,
                          amlComments: values.amlComments,
                          amlAcknowledgement: values.amlAcknowledgement,
                        });
                        handleAmlPopupClose();
                      }}
                      FURTHER_INFORMATION={amlFailedresponse?.FURTHER_INFORMATION}
                      EXTERNAL_SOURCES={amlFailedresponse?.EXTERNAL_SOURCES}
                    />
                  </Grid>
                )}
              </Formik>
            </Box>
          </Dialog>

          <ConfirmationDialog
            message={'Are you sure you want to delete Ultimate Beneficiary Ownership (UBO) ?'}
            open={deletePopup}
            setOpen={() => setDeletePopup(false)}
            onSave={() => {
              setDeletePopup(false);
              const deleteUbo = ubo.ubos
                .filter((_item) => _item.isActive)
                .map((ubo, _ind) => {
                  if (rowIndex === _ind) {
                    return { ...ubo, isActive: false };
                  }
                  return ubo;
                });
              const deletedUbos = ubo.ubos.filter((_item) => !_item.isActive && _item.id);
              const active_DeletedUbos = deleteUbo.filter(
                (_item) => _item.isActive || (!_item.isActive && _item.id)
              );
              setUbo({
                ...ubo,
                ubos: [...active_DeletedUbos, ...deletedUbos],
              });
              setUboListing(UboListingObject);
              setRowIndex(Number);
            }}
            onCancel={() => {
              setUboListing(UboListingObject);
              setRowIndex(Number);
              setDeletePopup(false);
            }}
          />
        </Grid>
      </>
    );
  }

  return (
    <Formik initialValues={ubo} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`applicants.${index}.taxIdNumber`}
                  label="Tax Id"
                  placeholder="Enter Tax ID"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`applicants.${index}.name`}
                  label="Entity Name"
                  placeholder="Enter Name"
                  disabled={true}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Typography ml={{ sm: 4 }} my={3} sx={{ color: 'secondary.main', fontWeight: 500 }}>
            We hereby declare that:
          </Typography>
          {uboTypesMdms && (
            <>
              <Declaration data={uboTypesMdms} values={values} />
              <SubHeading sx={{ mt: values.ubo_declaration_type !== 'none' ? 0 : '30px' }}>
                List of Ultimate Beneficiary Owners
              </SubHeading>
              <UboListing Ubovalues={values} />
            </>
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
