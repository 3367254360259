import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import { poaApprover } from '../../redux-store/types/api-types';
import { getPoaApproverById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldReadonly } from './AddressFields';

export default function PoaApproverDetails({
  location,
}: {
  location: Location<{ poaApproverId: number }>;
}): JSX.Element {
  const { poaApproverId: id } = location.state || { poaApproverId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [poaApproverDetails, setPoaApproverDetailsDetails] = useState<poaApprover>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getPoaApproverDetails = (await dispatch(
            getPoaApproverById(id)
          )) as unknown as poaApprover;
          setPoaApproverDetailsDetails(getPoaApproverDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box sx={styles.UsersFormikBox}>
        <BackButtonAndHeader
          User="POA Approver"
          accessForEditAdd={true}
          onClickBack={() =>
            history.push('poa-approver', {
              distributorId: poaApproverDetails?.distributorId,
            })
          }
          onClickEdit={() =>
            history.push('edit-poaApprover', {
              poaApproverId: poaApproverDetails?.id,
            })
          }
        />
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          poaApproverDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Name'} description={poaApproverDetails.user.firstName} />
                    <KeyValue title={'Email Id'} description={poaApproverDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={poaApproverDetails.user.phone} />
                    <KeyValue title={'Pan'} description={poaApproverDetails.panNumber} />
                    <AddressFieldReadonly AddressField={poaApproverDetails} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
