import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater } from '../components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../../lib/formik';
import { ApplicationProps, DistributorListType } from '../../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { updateApplication } from '../../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { ClassPlanProps, FundProps } from '../../../redux-store/types/funds';
import { investmentPaymentDetailsForeginSchema } from '../../../utils/schema';
import {
  APPLICATION_TYPE,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  currencyConversion,
  folioValidationCheck,
  getStep,
  saveForLater,
  statusRoleBased,
} from '../../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../../redux-store/actions/funds';
import { SearchableSelect } from '../../../lib/formik/searchSelectField';
import { getDistributorById } from '../../../redux-store/actions/userManagement';
import FieldValidationNote from '../FieldValidationNote';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  commitmentAmountForMigration: '',
};

const initialValues: Values = {
  ...fundInitialValues,
  saveType: 'save and proceed',
  applicationSource: 'digitalonboarding',
};

export default function InvestmentPaymentDetailsForegin(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [fundsListLoader, setFundsListLoader] = useState<boolean>(true);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { minCommitmentAmount = 10000000, maxCommitmentAmount = 20000000 } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      managementFee = 0,
      currency = 'usd',
      setupFeeApplicable = '',
      setupFeeValues = [],
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            setupFeeApplicable: setupFeeApplicable,
            setupFeeValues: setupFeeValues,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            managementFee: Number(managementFee),
            currency,
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount:
        Number(application?.commitmentAmount) === 0
          ? application?.commitmentAmount
          : folioValidationCheck(application?.applicationSource || 'digitalonboarding')
          ? 0
          : application?.commitmentAmount || null,
      commitmentAmountForMigration: folioValidationCheck(
        application?.applicationSource || 'digitalonboarding'
      )
        ? application?.commitmentAmountForMigration || ''
        : '',
      applicationSource: application?.applicationSource || 'digitalonboarding',
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
      } = application || {};
      const { saveType, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application },
        {
          ...application,
          ...rest,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 4 : 3)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > (RiskProfileEnabled ? 8 : 7)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ...rest,
              schemeId: rest.schemeId || null,
              planId: rest.planId || null,
              status: statusRoleBased(role, application),
              currentStep:
                applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? RiskProfileEnabled
                    ? getStep(5, isSaveLater)
                    : getStep(4, isSaveLater)
                  : RiskProfileEnabled
                  ? getStep(9, isSaveLater)
                  : getStep(8, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('bank-details', { id, applicant1ReferenceId })
            : history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    setFundsListLoader(true);
    (async function () {
      try {
        const { scheme, onboardingFundCategory = '' } = application || {};
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id, onboardingFundCategory)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true, fundCategory: onboardingFundCategory })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const updatedFundPlan = application?.schemeId
          ? !application.scheme.isActive ||
            !(response.map((res) => res.id?.toString()) as unknown as string[]).includes(
              application?.schemeId?.toString()
            )
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : (response.map((res) => res.id?.toString()) as unknown as string[]).includes(
                application?.schemeId?.toString()
              )
            ? response
            : [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
          : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    setFundsListLoader(false);
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsForeginSchema(
              minCommitmentAmount,
              maxCommitmentAmount,
              selectedPlan?.currency || ''
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
              loading={fundsListLoader}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class of Units Subscribed *"
              items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: 0,
                });
              }}
              searchFieldPlaceholder={'Search Class of Units Subscribed'}
              loading={fundsListLoader}
            />
          </Grid>
          {application?.applicationSource &&
          !folioValidationCheck(application?.applicationSource) ? (
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'commitmentAmount'}
                label="Capital Commitment *"
                type="number"
                placeholder="Enter Capital Commitment"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    commitmentAmount: value ? Number(value) : null,
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled}
              />
              {values.commitmentAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'commitmentAmountForMigration'}
                label="Capital Commitment *"
                placeholder="Enter Capital Commitment"
                disabled={isFieldDisabled}
              />
            </Grid>
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
