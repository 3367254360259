import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from '../contributor';
import React from 'react';
import { Gridstyles } from '../index';
import { NomineeType } from '../../../redux-store/types/api-types';
import { formatShortDate } from '../../../utils/date';
import { SubHeading } from '../../investors/components';
import { isMinor } from '../../../utils/utilityFunctions';

export default function NomineeDetailsForegin({
  nominees = [],
}: {
  nominees: Partial<NomineeType>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Nominee Details
            </Typography>
            {nominees.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Nominees
              </Grid>
            ) : (
              nominees.map((nominee, index: number) => {
                const title = [
                  'First Nominee Details',
                  'Second Nominee Details',
                  'Third Nominee Details',
                ][index];
                return (
                  <React.Fragment key={nominee.id}>
                    <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                      {title}
                    </SubHeading>
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <KeyValue
                          title={`Nominee Name - ${index + 1}`}
                          description={nominee.nomineeName}
                        />
                        <KeyValue
                          title={'Nominee Relationship with the Security Holder'}
                          description={nominee.nomineeRelationship}
                        />
                        <KeyValue
                          title={'Nominee Percentage'}
                          description={nominee.nomineePercentage}
                        />
                        <KeyValue
                          title={'Nominee Country tax identification number'}
                          description={nominee.taxIdNumber}
                        />
                        <KeyValue
                          title={'Nominee Date Of Birth'}
                          description={formatShortDate(nominee.dateOfBirth)}
                        />

                        {nominee.dateOfBirth && isMinor(nominee.dateOfBirth) && (
                          <>
                            <KeyValue
                              title={'Date of Attaining Majority'}
                              description={formatShortDate(nominee.dateOfAttainingMajority)}
                            />
                            <KeyValue title={'Guardian Name'} description={nominee.guardianName} />
                            <KeyValue
                              title={'Guardian Address'}
                              description={nominee.guardianAddress}
                            />
                            <KeyValue
                              title={'Guardian Country tax identification number'}
                              description={nominee.guardianTaxIdNumber}
                            />
                          </>
                        )}
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                );
              })
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
