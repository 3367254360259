/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  accessType,
  addFundType,
  addPlanType,
  ADD_FUND,
  ADD_FUND_SUCCESS,
  ADD_PLAN,
  ADD_PLAN_SUCCESS,
  ClassPlanProps,
  FundProps,
  GetFundsListResponseBody,
  GET_FUNDS,
  GET_FUNDS_ACCESS,
  GET_FUNDS_ACCESS_SUCCESS,
  GET_FUNDS_LIST,
  GET_FUNDS_LIST_SUCCESS,
  GET_FUNDS_SUCCESS,
  GET_PLAN_DETAILS,
  GET_PLAN_DETAILS_SUCCESS,
  GET_PLAN_SUCCESS,
  UPDATE_FUND,
  UPDATE_FUND_SUCCESS,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPLOAD_BULK_FUND,
  UPLOAD_BULK_FUND_SUCCESS,
  GET_DIRECTAPPLICABLEFUNDS_SUCCESS,
  GET_DIRECTAPPLICABLEFUNDS,
} from '../types/funds';

export type fundsParams = {
  isActive?: boolean;
  trusteeId?: number;
  fundManagerId?: number;
  fundCategory?: string;
};

export const getFundsList =
  (params?: fundsParams) =>
  async (dispatch: any): Promise<GetFundsListResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/list`,
        method: 'GET',
        types: [GET_FUNDS_LIST_SUCCESS, GET_FUNDS_LIST],
        params,
      },
    });
  };

export const addFund =
  (body: addFundType) =>
  async (dispatch: any): Promise<FundProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/addScheme`,
        method: 'POST',
        types: [ADD_FUND_SUCCESS, ADD_FUND],
        body,
      },
    });
  };

export const addBulkFunds =
  (body: any) =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/bulk-upload-funds`,
        method: 'POST',
        types: [UPLOAD_BULK_FUND_SUCCESS, UPLOAD_BULK_FUND],
        body,
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const addPlan =
  (body: addPlanType) =>
  async (dispatch: any): Promise<ClassPlanProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/addPlan`,
        method: 'POST',
        types: [ADD_PLAN_SUCCESS, ADD_PLAN],
        body,
      },
    });
  };

export const updateFund =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<FundProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/updateScheme/${Id}`,
        method: 'POST',
        types: [UPDATE_FUND_SUCCESS, UPDATE_FUND],
        body,
      },
    });
  };

export const updatePlan =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<ClassPlanProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/updatePlan/${Id}`,
        method: 'POST',
        types: [UPDATE_PLAN_SUCCESS, UPDATE_PLAN],
        body,
      },
    });
  };

export const fundAccess =
  () =>
  async (dispatch: any): Promise<accessType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/fundSetupAccess`,
        method: 'GET',
        types: [GET_FUNDS_ACCESS_SUCCESS, GET_FUNDS_ACCESS],
      },
    });
  };

export const getPlanList =
  (Id: string) =>
  async (dispatch: any): Promise<GetFundsListResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/schemes/${Id}`,
        method: 'GET',
        types: [GET_PLAN_SUCCESS, GET_PLAN_SUCCESS],
      },
    });
  };

export const getPlanDetail =
  (Id: string) =>
  async (dispatch: any): Promise<ClassPlanProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/plans/${Id}`,
        method: 'GET',
        types: [GET_PLAN_DETAILS_SUCCESS, GET_PLAN_DETAILS],
      },
    });
  };

export const getFunds =
  (params?: fundsParams) =>
  async (dispatch: any): Promise<GetFundsListResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds`,
        method: 'GET',
        types: [GET_FUNDS_SUCCESS, GET_FUNDS],
        params,
      },
    });
  };

export const getDirectApplicableFunds =
  (params: { isActive?: boolean; fundCategory?: string | undefined }) =>
  async (dispatch: any): Promise<FundProps[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/funds/direct-applicable-plans`,
        method: 'GET',
        types: [GET_DIRECTAPPLICABLEFUNDS_SUCCESS, GET_DIRECTAPPLICABLEFUNDS],
        params,
      },
    });
  };
