import { Box } from '@mui/system';
import { CommonLayout } from '../commonComponents';

export const AMCAdminsList = (): JSX.Element => {
  return (
    <CommonLayout>
      <Box>AMC admins Content</Box>
    </CommonLayout>
  );
};
