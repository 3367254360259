//Write a External css here if that was used more then one file otherwise use Inline css(if only used one place) or Internal(Embedded) css(if used more then once but in same file).

export const styles = {
  ApplicationDetailsCommonLayout: {
    bgcolor: 'white',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
  },
  UsersTableBodyCustomStyles: { '.MuiTableCell-root': { borderBottom: 'none' } },
  UsersTableHeaderCustomStyles: {
    '&:last-child th': { border: 0 },
    '.MuiTableCell-head': {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      color: 'primary.main',
    },
  },
  UsersAccessViewOrEditCellStack: {
    alignItems: { xs: 'baseline', sm: 'center' },
    '.MuiSwitch-root': {
      paddingLeft: { xs: '0px', sm: '12px' },
      width: { xs: '52px', sm: '58px' },
    },
    '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
  },
  UsersClickbleColumn: {
    color: '#61D1D6',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
    ':hover': { color: 'primary.main' },
  },
  UsersFormikBox: {
    bgcolor: 'white',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    py: { xs: 2, sm: 5 },
    pl: { xs: 0, sm: 5 },
    mt: { xs: 2, sm: 5 },
  },
  ApplicationApproveRejectSendbackDialog: {
    '.MuiPaper-root ': { p: 4, width: { xs: '70%', sm: '70%' } },
  },
  ApplicationApproveRejectSendbackDialogBox: {
    width: { xs: '100%', sm: '80%' },
    mx: 'auto',
    '.MuiTypography-root': { fontSize: 18, fontWeight: 500, textAlign: 'center' },
  },
};
// eslint-disable-next-line
export const ProfileAndManagementInputBox: Record<string, any> = {
  px: 5,
  py: 3,
  marginTop: 2,
  bgcolor: 'white',
  display: 'flex',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
  height: { xs: 'calc(90vh - 191px)', md: 'calc(90vh - 200px)' },
  overflowY: 'auto',
};
// eslint-disable-next-line
export const FiltersForApplicationStyles: Record<string, any> = {
  width: '100%',
  position: 'relative',
  padding: { xs: 1, sm: 2 },
  paddingBottom: 0,
  '& .MuiFormLabel-root': { color: 'primary.main', fontWeight: 600, marginBottom: 0.5 },
  '& .MuiFormControlLabel-root': {
    marginLeft: '-2px',
    marginBottom: 0.75,
    width: '100%',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      color: 'text.primary',
      fontWeight: 500,
      fontSize: 14,
      overflow: { xs: 'unset', md: 'hidden' },
      whiteSpace: { xs: 'unset', md: 'nowrap' },
      textOverflow: { xs: 'unset', md: 'ellipsis' },
      wordBreak: { xs: 'break-word', md: 'unset' },
    },
    '& .MuiRadio-root, & .MuiCheckbox-root, & .Mui-checked': { color: 'text.primary' },
    '& .MuiRadio-root, & .MuiCheckbox-root': { padding: 0, marginRight: 0.5 },
  },
  '& .MuiAccordionDetails-root': { paddingTop: 0 },
  '& .MuiAccordionSummary-content.Mui-expanded': { marginBottom: '10px' },
};
