import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from '../components';
import { Formik, useField, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import UseRadioGroup from '../../../lib/formik/Radio';
import { MFTextField } from '../../../lib/formik';
import AddIcon from '@mui/icons-material/Add';
import { ApplicationProps, Bank } from '../../../redux-store/types/api-types';
import { RootStateType } from '../../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateApplication, pennyDropVerification } from '../../../redux-store/actions/application';
import MFCheckbox from '../../../lib/formik/Checkbox';
import {
  applicationComparison,
  checkBankTypeExistOrNot,
  getApplicantName,
  getBankAddress,
  saveForLater,
  statusRoleBased,
  updatedPayloadBanks,
  getStep,
} from '../../../utils/utilityFunctions';
import { Box } from '@mui/system';
import { ConfirmationDialog } from '../../commonComponents';
import { getIFSCDetails } from '../../../redux-store/actions';
import { IfscType } from '../../../redux-store/types/mdms';
import { bankDetailsSchema, overseasBankDetailsSchema } from '../../../utils/schema';
import {
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  NRIBankAccountTypeMaster,
  USER_ROLES,
  RiskProfileEnabled,
  bankOverseasDomesticMaster,
  BankAccountTypeForVenture,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../../utils/constant';
import { Notes } from '../components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { showError } from '../../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { accountNumberRegex, ifscRegex } from '../../../utils/regex';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MFSelectField from '../../../lib/formik/SelectField';
import FieldValidationNote from '../FieldValidationNote';

export type updatedBank = Bank & {
  loading: boolean;
  bankAddress: string;
};

export const bankObject = (bankType: string): updatedBank => {
  return {
    ifscCode: '',
    bankAccountNumber: '',
    bankAccountType: '',
    bankName: '',
    bankBranch: '',
    defaultBankAccount: false,
    isActive: true,
    pennydropCheck: false,
    pennydropVerified: false,
    address1: '',
    address2: '',
    address3: '',
    micrCode: '',
    bankAddress: '',
    loading: false,
    bank_type: bankType,
    ibanNumber: '',
    cif: '',
    swiftCode: '',
  };
};

type Values = {
  status?: string;
  statusSubType: string | null;
  applicationType?: string;
  banks: {
    domestic?: updatedBank[];
    overseas?: updatedBank[];
  };
  saveType: string;
  investor_bank_type: string;
};

const initialValues: Values = {
  status: '',
  statusSubType: null,
  applicationType: '',
  investor_bank_type: 'domestic',
  banks: { domestic: [bankObject('domestic')], overseas: [bankObject('overseas')] },
  saveType: 'save and proceed',
};

function BankTextField({
  name,
  bankFieldName,
  branchFieldName,
  disabled = false,
  pennydropFieldCheck,
  address1FieldName,
  address2FieldName,
  address3FieldName,
  micrFieldName,
  addressFieldName,
}: {
  name: string;
  bankFieldName: string;
  branchFieldName: string;
  disabled?: boolean;
  pennydropFieldCheck: string;
  address1FieldName: string;
  address2FieldName: string;
  address3FieldName: string;
  micrFieldName: string;
  addressFieldName: string;
}): JSX.Element {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_field, _meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { setValue, setError } = helpers;
  const inputRef = useRef<HTMLInputElement>();

  const getIFSCResponse = (value: string) => {
    let isComponentAlive = true;
    if (!value || value.length < 12) {
      setValue((value || '').toUpperCase());
      setFieldValue(bankFieldName, '');
      setFieldValue(branchFieldName, '');
      setFieldValue(address1FieldName, '');
      setFieldValue(address2FieldName, '');
      setFieldValue(address3FieldName, '');
      setFieldValue(micrFieldName, '');
      setFieldValue(addressFieldName, '');
    }
    if (value?.length !== 11) {
      return;
    }
    inputRef.current && inputRef.current.blur();
    (async function () {
      try {
        const { BankName, BankCity, BankAdd1, BankAdd2, BankAdd3, MicrNo } = (await dispatch(
          getIFSCDetails(value)
        )) as unknown as IfscType;
        if (!isComponentAlive) {
          return;
        }
        setFieldValue(bankFieldName, BankName);
        setFieldValue(branchFieldName, BankCity);
        setFieldValue(address1FieldName, BankAdd1);
        setFieldValue(address2FieldName, BankAdd2);
        setFieldValue(address3FieldName, BankAdd3);
        setFieldValue(micrFieldName, MicrNo);
        setFieldValue(
          addressFieldName,
          (BankAdd2 ? BankAdd1 + ',' : BankAdd1) + (BankAdd3 ? BankAdd2 + ',' : BankAdd2) + BankAdd3
        );
      } catch (error) {
        setError('IFSC Code is invalid');
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  };
  function onChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(pennydropFieldCheck, false);
    getIFSCResponse(value);
  }
  return (
    <MFTextField
      name={name}
      label="IFSC Code *"
      placeholder="Enter IFSC Code"
      onChange={onChange}
      inputRef={inputRef}
      disabled={disabled}
      trimOnBlur={false}
    />
  );
}

export function IndianBankDetails({
  values,
  isFieldDisabled,
  pennyDropCheck,
  setBankToDelete,
  role,
  handleBankVerification,
  application,
}: {
  values: Values;
  isFieldDisabled: boolean;
  handleBankVerification: any;
  pennyDropCheck: any;
  setBankToDelete: any;
  role: any;
  application: ApplicationProps | null;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      {values.banks?.domestic?.map((bank, index) => (
        <React.Fragment key={index}>
          <SubHeading
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            {getApplicantName(index + 1, true)} Bank Account Details
            {index !== 0 && ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                  cursor: pennyDropCheck(values) ? 'not-allowed' : 'pointer',
                  pointerEvents: pennyDropCheck(values) ? 'none' : 'all',
                }}
                onClick={() => setBankToDelete({ bank, index })}>
                <Typography
                  sx={{
                    fontSize: 13,
                    ml: 'auto',
                    letterSpacing: '-0.2px',
                  }}>
                  Remove Bank{' '}
                </Typography>
                <IconButton sx={{ p: '2px' }}>
                  <DeleteOutlineIcon
                    color={pennyDropCheck(values) ? 'disabled' : 'error'}
                    fontSize="small"
                  />
                </IconButton>
              </Box>
            )}
          </SubHeading>
          <Grid item xs={12} sm={6}>
            <BankTextField
              name={`banks.domestic.${index}.ifscCode`}
              bankFieldName={`banks.domestic.${index}.bankName`}
              branchFieldName={`banks.domestic.${index}.bankBranch`}
              disabled={isFieldDisabled}
              pennydropFieldCheck={`banks.domestic.${index}.pennydropCheck`}
              address1FieldName={`banks.domestic.${index}.address1`}
              address2FieldName={`banks.domestic.${index}.address2`}
              address3FieldName={`banks.domestic.${index}.address3`}
              micrFieldName={`banks.domestic.${index}.micrCode`}
              addressFieldName={`banks.domestic.${index}.bankAddress`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.domestic.${index}.bankAccountNumber`}
              label="Bank Account Number *"
              placeholder="Enter Bank Account Number"
              disabled={isFieldDisabled}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'banks.domestic',
                  values.banks?.domestic?.map((bank, ind) => {
                    if (ind === index) {
                      return {
                        ...bank,
                        bankAccountNumber: value,
                        pennydropCheck: false,
                      };
                    }
                    return { ...bank, pennydropCheck: bank.pennydropCheck };
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.domestic.${index}.micrCode`}
              label="MICR Code"
              placeholder="Enter MICR Code"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
            {values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
            !['huf'].includes(application?.applicants[0]?.investorType || '') ? (
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)',
                  mt: { xs: 0, sm: 5 },
                  mb: { xs: 1, sm: 0 },
                }}>
                Bank type: Current{' '}
              </Typography>
            ) : (
              <UseRadioGroup
                formLabel="Bank type*"
                name={`banks.domestic.${index}.bankAccountType`}
                defaultValue="current"
                items={Object.keys(
                  applicantStatusMasters[values.status as string] === applicantStatusMasters.NRI
                    ? NRIBankAccountTypeMaster
                    : BankAccountTypeMaster
                ).map((accountType) => ({
                  label: accountType,
                  value:
                    applicantStatusMasters[values.status as string] === applicantStatusMasters.NRI
                      ? NRIBankAccountTypeMaster[accountType]
                      : BankAccountTypeMaster[accountType],
                }))}
                disabled={isFieldDisabled}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`banks.domestic.${index}.bankName`}
              label="Bank Name"
              placeholder="Enter Bank Name"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`banks.domestic.${index}.bankBranch`}
              label="Bank Branch"
              placeholder="Enter Bank Branch"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`banks.domestic.${index}.bankAddress`}
              multiline
              rows={3}
              label="Bank Address"
              placeholder="Enter Bank Address"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <MFCheckbox
              name={`banks.domestic.${index}.defaultBankAccount`}
              label="Default Bank Account *"
              sx={{ letterSpacing: '1px' }}
              onChange={() =>
                setFieldValue(
                  'banks.domestic',
                  values.banks?.domestic?.map((bank, ind) => {
                    if (ind === index) {
                      return {
                        ...bank,
                        defaultBankAccount: !bank.defaultBankAccount,
                      };
                    }
                    return {
                      ...bank,
                      defaultBankAccount: false,
                    };
                  })
                )
              }
              disabled={isFieldDisabled}
            />
          </Grid>
          {IS_PENNYDROP_APPLICABLE_FOR_AMC && (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{
                width: '100%',
                flexDirection: { xs: 'column-reverse', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
              }}>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Button
                  variant="outlined"
                  type="button"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                    fontSize: 14,
                    px: 3,
                    marginRight: 1,
                    width: '230px',
                    borderColor: 'primary.main',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ':hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.main',
                    },
                  }}
                  onClick={() => {
                    bank.bankAccountType =
                      values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                      !['huf'].includes(application?.applicants[0]?.investorType || '')
                        ? BankAccountTypeMaster.Current
                        : bank.bankAccountType;
                    handleBankVerification(values, index);
                  }}
                  disabled={bank.pennydropCheck || isFieldDisabled || pennyDropCheck(values)}>
                  {!bank.pennydropVerified || !bank.pennydropCheck
                    ? 'Verify Bank Details'
                    : 'Verified'}
                </Button>
                {bank.loading && (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress size={30} />
                  </Box>
                )}
              </Grid>
              <Grid sm={6} xs={12} sx={{ position: 'relative', bottom: 10, mb: { xs: 2, sm: 0 } }}>
                <Typography
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: { xs: 0, sm: '10px 20px' },
                    borderRadius: '5px',
                    alignItems: 'flex-start',
                    mt: 2,
                    width: '100%',
                    boxSizing: 'border-box',
                  }}>
                  <InfoOutlinedIcon color="info" fontSize="small" />
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: '13px',
                      fontWeight: 500,
                      ml: 1,
                    }}>
                    {bank.defaultBankAccount && bank.pennydropCheck && !bank.pennydropVerified
                      ? 'Penny drop verification failed, Unable to verify your bank details. Please upload your default bank account proofs In Document Details'
                      : 'The Primary Contributor’s bank account will be verified using a penny drop validation'}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sm={12}
            xs={12}
            sx={{ position: 'relative', bottom: { xs: 5, sm: 20 }, mb: { xs: 2, sm: 0 } }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '13px',
                fontWeight: 500,
                ml: 1,
              }}>
              {bank.pennydropCheck && !bank.defaultBankAccount && !bank.pennydropVerified
                ? 'Penny drop verification failed'
                : null}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
      {Number(values.banks?.domestic?.length) < 2 &&
        ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 14,
                mt: 2,
              }}
              onClick={() => {
                setFieldValue('banks', {
                  ...values.banks,
                  domestic: [
                    ...(values.banks?.domestic || []),
                    { ...bankObject('domestic'), applicationId: application?.id },
                  ],
                });
              }}
              disabled={pennyDropCheck(values)}>
              Add Another Bank
            </Button>
          </Grid>
        )}
    </>
  );
}

export function OverseasBankDetails({
  values,
  isFieldDisabled,
  pennyDropCheck,
  setBankToDelete,
  role,
  application,
}: {
  values: Values;
  isFieldDisabled: boolean;
  pennyDropCheck: any;
  setBankToDelete: any;
  role: any;
  application: ApplicationProps | null;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      {values.banks.overseas?.map((bank, index) => (
        <React.Fragment key={index}>
          <SubHeading
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            {getApplicantName(index + 1, true)} Bank Account Details
            {index !== 0 && ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                  cursor: pennyDropCheck(values) ? 'not-allowed' : 'pointer',
                  pointerEvents: pennyDropCheck(values) ? 'none' : 'all',
                }}
                onClick={() => setBankToDelete({ bank, index })}>
                <Typography
                  sx={{
                    fontSize: 13,
                    ml: 'auto',
                    letterSpacing: '-0.2px',
                  }}>
                  Remove Bank{' '}
                </Typography>
                <IconButton sx={{ p: '2px' }}>
                  <DeleteOutlineIcon
                    color={pennyDropCheck(values) ? 'disabled' : 'error'}
                    fontSize="small"
                  />
                </IconButton>
              </Box>
            )}
          </SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.overseas.${index}.swiftCode`}
              label="Swift Code *"
              placeholder="Enter Swift Code"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.overseas.${index}.bankAccountNumber`}
              label="Bank Account Number *"
              placeholder="Enter Bank Account Number"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`banks.overseas.${index}.bankAccountType`}
              label="Account Type *"
              items={Object.keys(BankAccountTypeForVenture).map((accountType) => ({
                key: BankAccountTypeForVenture[accountType],
                value: accountType,
              }))}
              disabled={isFieldDisabled}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.overseas.${index}.bankAccountType`}
              label="Account Type *"
              placeholder="Enter Account Type"
              disabled={isFieldDisabled}
            />
          </Grid> */}
          <Grid item xs={12}>
            <MFTextField
              name={`banks.overseas.${index}.bankName`}
              label="Bank Name *"
              placeholder="Enter Bank Name"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`banks.overseas.${index}.bankBranch`}
              label="Bank Branch"
              placeholder="Enter Bank Branch"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MFTextField
              name={`banks.overseas.${index}.bankAddress`}
              multiline
              rows={3}
              label="Bank Address *"
              placeholder="Enter Bank Address"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.overseas.${index}.ibanNumber`}
              label="IBAN (International Bank Account Number)"
              placeholder="Enter IBAN (International Bank Account Number)"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`banks.overseas.${index}.cif`}
              label="CIF (Customer Information File) if any"
              placeholder="Enter CIF (Customer Information File) if any"
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MFCheckbox
              name={`banks.overseas.${index}.defaultBankAccount`}
              label="Default Bank Account *"
              sx={{ letterSpacing: '1px' }}
              onChange={() =>
                setFieldValue('banks', {
                  ...values.banks,
                  overseas: values.banks.overseas?.map((bank, ind) => {
                    if (ind === index) {
                      return {
                        ...bank,
                        defaultBankAccount: !bank.defaultBankAccount,
                      };
                    }
                    return {
                      ...bank,
                      defaultBankAccount: false,
                    };
                  }),
                })
              }
              disabled={isFieldDisabled}
            />
          </Grid>
        </React.Fragment>
      ))}
      {values.banks?.overseas &&
        values.banks?.overseas.length < 2 &&
        ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 14,
                mt: 2,
              }}
              onClick={() =>
                setFieldValue('banks', {
                  ...values.banks,
                  overseas: [
                    ...(values.banks.overseas || []),
                    { ...bankObject('overseas'), applicationId: application?.id },
                  ],
                })
              }
              disabled={pennyDropCheck(values)}>
              Add Another Bank
            </Button>
          </Grid>
        )}
    </>
  );
}

export default function BankDetails(): JSX.Element {
  const [banksData, setBanksData] = useState(initialValues);
  const [bankToDelete, setBankToDelete] = useState<{ bank: Bank; index: number } | null>(null);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { id = '', applicants } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [pennyDropStatusPopUp, setPennyDropStatusPopUp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [addBankAfterPennyDrop, setAddBankAfterPennyDrop] = useState(bankObject('domestic'));
  const [addBankAfterPennyDropCheck, setAddBankAfterPennyDropCheck] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopUpClose = () => {
    setPennyDropStatusPopUp(false);
  };

  useEffect(() => {
    const {
      banks = [],
      applicationType,
      applicants = [],
      investor_bank_type = 'domestic',
    } = application || {};
    const setBank =
      addBankAfterPennyDropCheck && banks.length < 2
        ? [
            ...banks.map((bank) => ({
              ...bank,
              defaultBankAccount: !!bank.defaultBankAccount,
              pennydropCheck: !!bank.pennydropCheck,
              pennydropVerified: !!bank.pennydropVerified,
              loading: false,
              bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
            })),
            { ...addBankAfterPennyDrop },
          ]
        : banks.map((bank) => ({
            ...bank,
            defaultBankAccount: !!bank.defaultBankAccount,
            pennydropCheck: !!bank.pennydropCheck,
            pennydropVerified: !!bank.pennydropVerified,
            loading: false,
            bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
          }));
    setBanksData({
      ...banksData,
      applicationType: applicationType,
      status: applicants.length ? applicants[0].status : '',
      statusSubType: applicants.length ? applicants[0]?.statusSubType || null : null,
      investor_bank_type:
        applicantStatusMasters[applicants[0]?.statusSubType as string] ===
        applicantStatusMasters.NRI_FRI
          ? investor_bank_type || 'domestic'
          : 'domestic',
      banks: {
        domestic:
          banks.length &&
          checkBankTypeExistOrNot(
            banks.map((_bank) => _bank?.bank_type),
            'domestic'
          )
            ? setBank.filter(
                (bank) =>
                  bankOverseasDomesticMaster[bank.bank_type || ''] ===
                  bankOverseasDomesticMaster.domestic
              )
            : [{ ...bankObject('domestic'), applicationId: id }],
        overseas:
          banks.length &&
          checkBankTypeExistOrNot(
            banks.map((_bank) => _bank.bank_type),
            'overseas'
          )
            ? setBank.filter(
                (bank) =>
                  bankOverseasDomesticMaster[bank.bank_type] === bankOverseasDomesticMaster.overseas
              )
            : applicantStatusMasters[applicants[0]?.statusSubType as string] ===
              applicantStatusMasters.NRI_FRI
            ? [{ ...bankObject('overseas'), applicationId: id }]
            : [],
      },
    });
    setAddBankAfterPennyDrop(bankObject('domestic'));
    setAddBankAfterPennyDropCheck(false);
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { banks, saveType, investor_bank_type } = values;
      if (
        IS_PENNYDROP_APPLICABLE_FOR_AMC &&
        bankOverseasDomesticMaster[investor_bank_type] === bankOverseasDomesticMaster.domestic
      ) {
        const isAllBanksPennyChecked = banks?.domestic?.every((bank) => bank.pennydropCheck);
        if (!isAllBanksPennyChecked) {
          throw 'Please make sure that all the banks are verified';
        }
      }
      const {
        banks: existingBanks = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
      } = application || {};

      const domestic = updatedPayloadBanks(existingBanks, application, 'domestic', banks.domestic);
      const overseas = updatedPayloadBanks(existingBanks, application, 'overseas', banks.overseas);

      const updatedBanks = (
        checkBankTypeExistOrNot(Object.keys(banks), 'domestic') &&
        checkBankTypeExistOrNot(Object.keys(banks), 'overseas')
          ? [...domestic, ...overseas]
              .map((_bank) => {
                if (investor_bank_type !== _bank.bank_type) {
                  return {
                    ..._bank,
                    isActive: false,
                    defaultBankAccount: false,
                  };
                }
                return _bank;
              })
              .filter((ele) => ele.id || ele.isActive)
          : !checkBankTypeExistOrNot(Object.keys(banks), 'domestic') &&
            checkBankTypeExistOrNot(Object.keys(banks), 'overseas')
          ? overseas
          : domestic
      ).map((bank) => {
        // eslint-disable-next-line
        const { loading, bankAddress, ...rest } = bank;
        return { ...rest };
      });

      const checkApplication = applicationComparison(
        {
          ...application,
          banks: application?.banks?.sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
        },
        {
          ...application,
          banks: updatedBanks.sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
          investor_bank_type,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 5 : 4)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 6
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              investor_bank_type,
              banks: updatedBanks,
              status: statusRoleBased(role, application),
              currentStep:
                applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? RiskProfileEnabled
                    ? getStep(6, isSaveLater)
                    : getStep(5, isSaveLater)
                  : getStep(7, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      }
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('document-details', { id, applicant1ReferenceId })
          : history.push(RiskProfileEnabled ? 'risk-profiles' : 'investment-payment-details', {
              id,
              applicant1ReferenceId,
            })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);

  const handleBankVerification = async (values: Values, ind: number) => {
    const { banks: existingBanks = [], currentStep, applicationType } = application || {};
    const { banks, saveType, investor_bank_type } = values;
    setBanksData({
      ...banksData,
      investor_bank_type,
      banks: {
        ...banks,
        domestic: banks?.domestic?.map((bank, index) =>
          index === ind ? { ...bank, loading: true } : bank
        ),
      },
    });
    try {
      const bank = (banks?.domestic || [])[ind];
      if (
        !bank?.ifscCode ||
        !bank?.bankAccountNumber ||
        (!bank?.bankAccountType && applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL) ||
        (!bank?.bankAccountType &&
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          ['huf'].includes(application?.applicants[0].investorType || ''))
      ) {
        throw `Please fill all the required(*) fields in  ${getApplicantName(
          ind + 1,
          true
        )} Bank Account Details`;
      }
      if (!ifscRegex.test(bank?.ifscCode)) {
        throw `Invalid IFSC Code in  ${getApplicantName(ind + 1, true)} Bank Account Details`;
      }
      if (!accountNumberRegex.test(bank?.bankAccountNumber)) {
        throw `Please enter valid Account Number in  ${getApplicantName(
          ind + 1,
          true
        )} Bank Account Details`;
      }
      banks?.domestic?.map((bank, index) => {
        if (index !== ind && bank.bankAccountNumber === bank?.bankAccountNumber) {
          throw `Account number already used`;
        }
      });
      const getDefaultBankType =
        values.banks?.domestic?.map((_bank) => _bank.defaultBankAccount) || [];
      if (!getDefaultBankType.includes(true)) {
        throw `Please check the default bank account`;
      }
      const checkforDefaultBankIsPennydropChecked =
        values.banks?.domestic?.map((bank) => {
          if (bank.defaultBankAccount && bank.pennydropCheck) {
            return true;
          }
          return false;
        }) || [];
      if (
        values.banks?.domestic?.map((bank) => bank.id).filter((ele) => ele).length &&
        !bank?.defaultBankAccount &&
        !checkforDefaultBankIsPennydropChecked.includes(true)
      ) {
        throw `Please verify default bank account`;
      }
      const pennyDropResponse = (await dispatch(
        pennyDropVerification({
          ...bank,
          bankAccountType:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
            !['huf'].includes(application?.applicants[0].investorType || '')
              ? BankAccountTypeMaster.Current
              : bank?.bankAccountType,
        })
      )) as unknown as Bank;
      const isSaveLater = saveType !== 'save and proceed';
      const domestic = banks?.domestic
        ?.map((bank, index) => {
          if (ind === index) {
            return {
              ...bank,
              ...pennyDropResponse,
            };
          }
          return bank.id && bank.pennydropCheck && bank;
        })
        .filter((ele) => ele) as Bank[];
      const overseas = banks.overseas || [];
      await dispatch(
        updateApplication({
          body: {
            ...application,
            investor_bank_type,
            banks:
              checkBankTypeExistOrNot(Object.keys(banks), 'domestic') &&
              checkBankTypeExistOrNot(Object.keys(banks), 'overseas')
                ? [...domestic, ...overseas]
                    .map((_bank) => {
                      if (investor_bank_type !== _bank.bank_type) {
                        return {
                          ..._bank,
                          isActive: false,
                          defaultBankAccount: false,
                        };
                      }
                      return _bank;
                    })
                    .filter((ele) => ele.id || ele.isActive)
                : domestic,
            status: statusRoleBased(role, application),
            currentStep: currentStep,
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      setBanksData({
        ...banksData,
        investor_bank_type,
        banks: {
          ...banks,
          domestic: banks?.domestic?.map((bank, index) => {
            if (index === ind) {
              return { ...bank, ...pennyDropResponse, loading: false };
            }
            if (!bank.id) {
              setAddBankAfterPennyDropCheck(true);
              setAddBankAfterPennyDrop(bank);
              return bank;
            } else if (
              bank.id &&
              !(JSON.stringify(existingBanks[index]) === JSON.stringify(bank))
            ) {
              setAddBankAfterPennyDropCheck(true);
              setAddBankAfterPennyDrop(bank);
              return bank;
            }
            return bank;
          }),
        },
      });
      if (pennyDropResponse.pennydropVerified) {
        enqueueSnackbar('Verified Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      if (pennyDropResponse.defaultBankAccount && !pennyDropResponse.pennydropVerified) {
        setPennyDropStatusPopUp(true);
      }
    } catch (e) {
      setBanksData({
        ...banksData,
        investor_bank_type,
        banks: {
          ...banks,
          domestic: banks?.domestic?.map((bank, index) => {
            if (index === ind) {
              return { ...bank, loading: false };
            }
            if (!bank.id) {
              setAddBankAfterPennyDropCheck(true);
              setAddBankAfterPennyDrop(bank);
              return bank;
            } else if (
              bank.id &&
              !(JSON.stringify(existingBanks[index]) === JSON.stringify(bank))
            ) {
              setAddBankAfterPennyDropCheck(true);
              setAddBankAfterPennyDrop(bank);
              return bank;
            }
            return bank;
          }),
        },
      });
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const pennyDropCheck = (values: Values): boolean => {
    return (
      values.banks?.domestic?.map((bank) => {
        if (bank.loading) {
          return true;
        }
        return false;
      }) || []
    ).includes(true);
  };
  return (
    <Formik
      initialValues={banksData}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            bankOverseasDomesticMaster[values.investor_bank_type || ''] ===
              bankOverseasDomesticMaster.domestic
              ? bankDetailsSchema(applicants)
              : overseasBankDetailsSchema,
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'The below mentioned bank details will be used for principal payouts, Distribution payouts, and Redemption payouts'
            }
          />
          {applicantStatusMasters[values?.statusSubType as string] ===
            applicantStatusMasters.NRI_FRI && (
            <Grid xs={12} mt={1}>
              <UseRadioGroup
                formLabel=""
                name={`investor_bank_type`}
                defaultValue="domestic"
                items={Object.keys(bankOverseasDomesticMaster).map((bankType) => ({
                  label: bankOverseasDomesticMaster[bankType],
                  value: bankType,
                }))}
                disabled={isFieldDisabled}
              />
            </Grid>
          )}
          {bankOverseasDomesticMaster[values.investor_bank_type] ===
          bankOverseasDomesticMaster.domestic ? (
            <IndianBankDetails
              values={values}
              isFieldDisabled={isFieldDisabled}
              pennyDropCheck={pennyDropCheck}
              setBankToDelete={setBankToDelete}
              role={role}
              handleBankVerification={handleBankVerification}
              application={application}
            />
          ) : (
            <OverseasBankDetails
              values={values}
              isFieldDisabled={isFieldDisabled}
              pennyDropCheck={pennyDropCheck}
              setBankToDelete={setBankToDelete}
              role={role}
              application={application}
            />
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
            disabled={pennyDropCheck(values)}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete bank details ?'}
            open={bankToDelete !== null}
            setOpen={() => setBankToDelete(null)}
            onSave={async () => {
              try {
                const { id } = application || {};
                const getBanksArr: updatedBank[] =
                  (bankOverseasDomesticMaster[values.investor_bank_type] ===
                  bankOverseasDomesticMaster.domestic
                    ? values.banks.domestic
                    : values.banks.overseas) || [];
                const remainedBanks = getBanksArr?.map((bank, i) => {
                  // eslint-disable-next-line
                  const { loading, ...rest } = bank;
                  if (i === bankToDelete?.index) {
                    return {
                      ...rest,
                      isActive: false,
                      defaultBankAccount: bank.defaultBankAccount ? false : bank.defaultBankAccount,
                    };
                  }
                  return { ...rest, defaultBankAccount: true };
                });
                setBankToDelete(null);
                if (id && bankToDelete?.bank.id) {
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        investor_bank_type: values.investor_bank_type,
                        banks: remainedBanks,
                        status: statusRoleBased(role, application),
                      },
                      applicationId: id,
                    })
                  );
                  return;
                }
                const activeRemainedBanks = remainedBanks.filter((bank) => bank.isActive);
                setFieldValue('banks', {
                  ...values.banks,
                  domestic:
                    bankOverseasDomesticMaster[values.investor_bank_type] ===
                    bankOverseasDomesticMaster.domestic
                      ? activeRemainedBanks
                      : values.banks.domestic,
                  overseas:
                    bankOverseasDomesticMaster[values.investor_bank_type] ===
                    bankOverseasDomesticMaster.overseas
                      ? activeRemainedBanks
                      : values.banks.overseas,
                });
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setBankToDelete(null)}
          />
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                All the Bank Details Should be Verified
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  '.MuiButton-root': {
                    minWidth: '100px',
                  },
                }}>
                <Button variant="contained" onClick={handleClose} sx={{ color: 'common.white' }}>
                  Ok
                </Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            onClose={handlePopUpClose}
            open={pennyDropStatusPopUp}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                Unable to verify your bank details. Please upload your default bank account proofs
                In Document Details
              </Typography>
            </Box>
            <Box
              sx={{
                gap: 1.5,
                '.MuiButton-root': {
                  minWidth: '100px',
                },
                textAlign: 'center',
              }}>
              <Button variant="contained" onClick={handlePopUpClose} sx={{ color: 'common.white' }}>
                Ok
              </Button>
            </Box>
          </Dialog>
        </Grid>
      )}
    </Formik>
  );
}
