import { Grid, Typography } from '@mui/material';
import MFSelectField from '../../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from '../../investors/components';
import { MFTextField } from '../../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant } from '../../../redux-store/types/api-types';
import {
  // eslint-disable-next-line
  ApplicationSourceMaster,
  grossAnnualMasters,
  investorTypeMasters,
  PEPsMasters,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../../utils/constant';
import { DatePicker } from '../../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  applicationComparison,
  currencyConversion,
  folioValidationCheck,
  getStep,
  removeSingleQuote,
  saveForLater,
  statusRoleBased,
} from '../../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { updateApplication } from '../../../redux-store/actions/application';
import { NonIndividualContributorValidationForeignSchema } from '../../../utils/schema';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line
import UseRadioGroup from '../../../lib/formik/Radio';
import FieldValidationNote from '../../investors/FieldValidationNote';

const contributorObject: Partial<Applicant> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  investorType: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  netWorth: '',
  taxIdNumber: '',
  corporateIdentificationNo: '',
  politicallyExposedPersonStatus: '',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  applicationSource: string;
  folio_no: string | null;
  applicants: Partial<Applicant>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  applicationSource: 'digitalonboarding',
  folio_no: '',
  saveType: 'save and proceed',
};

export default function ContributorDetailsForeign(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    try {
      const {
        applicants = [],
        applicationSource = 'digitalonboarding',
        folio_no = '',
      } = application || {};
      setContributorValues({
        ...contributorValues,
        folio_no: folio_no,
        applicationSource: applicationSource || 'digitalonboarding',
        applicants: applicants.length ? applicants : [contributorObject],
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  }, [application]);

  const handleApplicant = async (values: Values) => {
    const { saveType, folio_no, applicationSource } = values;
    const { id, currentStep, banks = [] } = application || {};
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        folio_no: folioValidationCheck(applicationSource) ? folio_no : null,
        applicationSource,
        banks:
          values.applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: values.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              values.applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            applicants: values.applicants.map((applicant) => ({
              ...applicant,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
            })),
            modeOfHolding: 'single',
            folio_no: folioValidationCheck(applicationSource) ? folio_no : '',
            applicationSource,
            status: statusRoleBased(role, application),
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const { id, applicant1ReferenceId = '', applicationNumber } = application || {};
      const { saveType } = values;
      await handleApplicant(values);
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, NonIndividualContributorValidationForeignSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {/* <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }} mb={2}>
              <UseRadioGroup
                formLabel="Application Source"
                name="applicationSource"
                defaultValue="digitalonboarding"
                items={Object.keys(ApplicationSourceMaster).map((source) => ({
                  label: ApplicationSourceMaster[source],
                  value: source,
                }))}
              />
            </Grid> */}
            {folioValidationCheck(values.applicationSource) && (
              <Grid item xs={12} sm={6} mb={2}>
                <MFTextField
                  name={`folio_no`}
                  label={`Folio Number ${
                    folioValidationCheck(values.applicationSource) ? '*' : ''
                  }`}
                  placeholder="Enter Folio Number"
                />
              </Grid>
            )}
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={applicant.dataFetchedFromKRA || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={applicant.dataFetchedFromKRA || false}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.corporateIdentificationNo`}
                    label="Corporate Identification number (if applicable)"
                    placeholder="Enter Corporate Identification number (if applicable)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.taxIdNumber`}
                    label="Tax ID  *"
                    placeholder="Enter Tax ID "
                    disabled={true}
                  />
                </Grid>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income in USD"
                    items={grossAnnualMasters.foreign.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth in USD"
                    placeholder="Enter Net Worth in USD"
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
                    disabled={true}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}
