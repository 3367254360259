import { Box, Button, CardMedia, Hidden, IconButton, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

export const MobileBackButton = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <Hidden smUp={true}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ height: 'fit-content', p: 0 }} onClick={onClick}>
        <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
      </IconButton>
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 600,
          color: '#1C2D47',
        }}>
        Back
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 2,
        py: 2,
        pl: { xs: 3, sm: 0 },
        color: 'primary.main',
        bgcolor: 'rgba(238, 244, 251, 0.5)',
      }}>
      <PersonOutlineOutlinedIcon fontSize="large" />
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 600,
          pl: 1,
        }}>
        User Management
      </Typography>
    </Box>
  </Hidden>
);

export const DesktopBackButton = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <Hidden only="xs">
    <IconButton sx={{ height: 'fit-content', p: 0 }} onClick={onClick}>
      <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
    </IconButton>
  </Hidden>
);

export const BackButtonAndHeader = ({
  onClickEdit,
  onClickBack,
  accessForEditAdd,
  User,
}: {
  onClickEdit?: () => void;
  onClickBack: () => void;
  accessForEditAdd?: boolean;
  User: string;
}): JSX.Element => (
  <>
    <MobileBackButton onClick={onClickBack} />
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DesktopBackButton onClick={onClickBack} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            color: '#1C2D47',
            pl: { xs: 2, sm: 0 },
          }}>
          {User}
        </Typography>
      </Box>
      {accessForEditAdd && (
        <IconButton>
          <CardMedia
            component="img"
            src="/images/edit-icon-outlined.svg"
            alt="Edit Icon"
            sx={{ width: 'unset' }}
            onClick={() => onClickEdit && onClickEdit()}
          />
        </IconButton>
      )}
    </Box>
  </>
);

export const UserListHeader = ({
  accessForEditAdd,
  addUser,
  listName,
  onClickAdd,
}: {
  accessForEditAdd: boolean;
  addUser: string;
  listName?: string;
  onClickAdd?: () => void;
}): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      pt: { xs: 2, sm: 7 },
      pb: { xs: 2, sm: 4 },
    }}>
    <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>{listName}</Typography>
    {accessForEditAdd && (
      <Hidden only="xs">
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            color: 'white',
            fontWeight: 500,
            fontSize: 14,
            mr: 2.5,
            py: 0.5,
            px: 3,
          }}
          onClick={onClickAdd}>
          {addUser}
        </Button>
      </Hidden>
    )}
    {accessForEditAdd && (
      <Hidden smUp={true}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            color: 'primary.main',
            fontWeight: 500,
            fontSize: 13,
            py: 0.5,
          }}
          onClick={onClickAdd}>
          {addUser}
        </Button>
      </Hidden>
    )}
  </Box>
);
