import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from '../components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../../lib/formik';
import { Bank } from '../../../redux-store/types/api-types';
import { RootStateType } from '../../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateApplication } from '../../../redux-store/actions/application';
import {
  applicationComparison,
  getApplicantName,
  getBankAddress,
  getStep,
  removeSingleQuote,
  saveForLater,
  statusRoleBased,
} from '../../../utils/utilityFunctions';
import { Box } from '@mui/system';
import { ConfirmationDialog } from '../../commonComponents';
import { bankDetailsForeginSchema } from '../../../utils/schema';
import {
  APPLICATION_TYPE,
  BankAccountTypeForVenture,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../../utils/constant';
import { Notes } from '../components';
import { showError } from '../../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MFSelectField from '../../../lib/formik/SelectField';
import MFCheckbox from '../../../lib/formik/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import FieldValidationNote from '../FieldValidationNote';

type updatedBank = Bank & {
  loading: boolean;
  bankAddress: string;
};

export const bankObject: updatedBank = {
  ifscCode: '',
  bankAccountNumber: '',
  bankAccountType: '',
  bankName: '',
  bankBranch: '',
  defaultBankAccount: true,
  isActive: true,
  pennydropCheck: false,
  pennydropVerified: false,
  address1: '',
  address2: '',
  address3: '',
  micrCode: '',
  bankAddress: '',
  loading: false,
  swiftCode: '',
  ibanNumber: '',
  cif: '',
  bank_type: 'overseas',
};

type Values = {
  status?: string;
  applicationType?: string;
  banks: updatedBank[];
  saveType: string;
};

const initialValues: Values = {
  status: '',
  applicationType: '',
  banks: [bankObject],
  saveType: 'save and proceed',
};

export default function BankDetailsForegin(): JSX.Element {
  const [banksData, setBanksData] = useState(initialValues);
  const [bankToDelete, setBankToDelete] = useState<{ bank: Bank; index: number } | null>(null);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { id = '' } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [addBankAfterPennyDrop, setAddBankAfterPennyDrop] = useState(bankObject);
  const [addBankAfterPennyDropCheck, setAddBankAfterPennyDropCheck] = useState(false);

  useEffect(() => {
    const { banks = [], applicationType, applicants = [] } = application || {};
    setBanksData({
      ...banksData,
      applicationType: applicationType,
      status: applicants.length ? applicants[0].status : '',
      banks: banks.length
        ? addBankAfterPennyDropCheck && banks.length < 2
          ? [
              ...banks.map((bank) => ({
                ...bank,
                defaultBankAccount: bank.defaultBankAccount || true,
                pennydropCheck: !!bank.pennydropCheck,
                pennydropVerified: !!bank.pennydropVerified,
                loading: false,
                bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
              })),
              { ...addBankAfterPennyDrop },
            ]
          : banks.map((bank) => ({
              ...bank,
              defaultBankAccount: bank.defaultBankAccount || true,
              pennydropCheck: !!bank.pennydropCheck,
              pennydropVerified: !!bank.pennydropVerified,
              loading: false,
              bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
            }))
        : [{ ...bankObject, applicationId: id }],
    });
    setAddBankAfterPennyDrop(bankObject);
    setAddBankAfterPennyDropCheck(false);
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { banks, saveType } = values;
      const {
        banks: existingBanks = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
      } = application || {};
      const updatedBanks = banks.map((bank, ind) => ({
        ...(existingBanks[ind] || {}),
        ...bank,
        bankName: removeSingleQuote(bank.bankName),
        bankBranch: removeSingleQuote(bank.bankBranch),
        address1: removeSingleQuote(bank.bankAddress),
      }));

      const checkApplication = applicationComparison(
        {
          ...application,
          banks: application?.banks?.sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
        },
        {
          ...application,
          banks: updatedBanks
            .map((bank) => {
              // eslint-disable-next-line
              const { loading, bankAddress, ...rest } = bank;
              return { ...rest };
            })
            .sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 5 : 4)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 6
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              banks: updatedBanks,
              status: statusRoleBased(role, application),
              currentStep:
                applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? RiskProfileEnabled
                    ? getStep(6, isSaveLater)
                    : getStep(5, isSaveLater)
                  : getStep(7, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      }
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('document-details', { id, applicant1ReferenceId })
          : history.push('investment-payment-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);

  return (
    <Formik
      initialValues={banksData}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, bankDetailsForeginSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'The below mentioned bank details will be used for principal payouts, Distribution payouts, and Redemption payouts'
            }
          />
          {values.banks.map((bank, index) => (
            <React.Fragment key={index}>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                {getApplicantName(index + 1, true)} Bank Account Details
                {index !== 0 && ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'rgba(196, 42, 51, 0.8)',
                      cursor: 'pointer',
                      pointerEvents: 'all',
                    }}
                    onClick={() => setBankToDelete({ bank, index })}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        ml: 'auto',
                        letterSpacing: '-0.2px',
                      }}>
                      Remove Bank{' '}
                    </Typography>
                    <IconButton sx={{ p: '2px' }}>
                      <DeleteOutlineIcon color={'error'} fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </SubHeading>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`banks.${index}.swiftCode`}
                  label="SWIFT Code/IFSC *"
                  placeholder="Enter SWIFT Code/IFSC"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`banks.${index}.bankAccountNumber`}
                  label="Bank Account Number *"
                  placeholder="Enter Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFSelectField
                  name={`banks.${index}.bankAccountType`}
                  label="Account Type *"
                  items={Object.keys(BankAccountTypeForVenture).map((accountType) => ({
                    key: BankAccountTypeForVenture[accountType],
                    value: accountType,
                  }))}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MFTextField
                  name={`banks.${index}.bankName`}
                  label="Bank Name *"
                  placeholder="Enter Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MFTextField
                  name={`banks.${index}.bankBranch`}
                  label="Bank Branch"
                  placeholder="Enter Bank Branch"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MFTextField
                  name={`banks.${index}.bankAddress`}
                  multiline
                  rows={3}
                  label="Bank Address *"
                  placeholder="Enter Bank Address"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`banks.${index}.ibanNumber`}
                  label="IBAN (International Bank Account Number) *"
                  placeholder="Enter IBAN (International Bank Account Number)"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={`banks.${index}.cif`}
                  label="CIF (Customer Information File) if any"
                  placeholder="Enter CIF (Customer Information File) if any"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MFCheckbox
                  name={`banks.${index}.defaultBankAccount`}
                  label="Default Bank Account *"
                  sx={{ letterSpacing: '1px' }}
                  onChange={() =>
                    setFieldValue(
                      'banks',
                      values.banks.map((bank, ind) => {
                        if (ind === index) {
                          return {
                            ...bank,
                            defaultBankAccount: !bank.defaultBankAccount,
                          };
                        }
                        return {
                          ...bank,
                          defaultBankAccount: false,
                        };
                      })
                    )
                  }
                  disabled={isFieldDisabled}
                />
              </Grid>
            </React.Fragment>
          ))}
          {values.banks.length < 2 &&
            ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 2,
                  }}
                  onClick={() =>
                    setFieldValue('banks', [...values.banks, { ...bankObject, applicationId: id }])
                  }>
                  Add Another Bank
                </Button>
              </Grid>
            )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete bank details ?'}
            open={bankToDelete !== null}
            setOpen={() => setBankToDelete(null)}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedBanks = values.banks.map((bank, i) => {
                  // eslint-disable-next-line
                  const { loading, ...rest } = bank;
                  if (i === bankToDelete?.index) {
                    return {
                      ...rest,
                      isActive: false,
                      defaultBankAccount: bank.defaultBankAccount ? false : bank.defaultBankAccount,
                    };
                  }
                  return { ...rest, defaultBankAccount: true };
                });
                setBankToDelete(null);
                if (id && bankToDelete?.bank.id) {
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        banks: remainedBanks,
                        status: statusRoleBased(role, application),
                      },
                      applicationId: id,
                    })
                  );
                  return;
                }
                setFieldValue(
                  'banks',
                  remainedBanks.filter((bank) => bank.isActive)
                );
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setBankToDelete(null)}
          />
        </Grid>
      )}
    </Formik>
  );
}
